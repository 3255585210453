import React from 'react'
import * as APP_DATA from '../../../ConstantData';
import { MitraImage } from '../../../entity/MitraImage';


class UserRegisterNavigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  is_section_active(current_active_section, section_id) {
    let cls_numbering_section = "cursor-pointer hover:bg-magnet-rose-normal hover:text-white duration-500 rounded-full border-2 border-magnet-rose-normal m-auto w-7 mb-1"
    let cls_active_section = cls_numbering_section + " bg-magnet-rose-normal text-white"
    let cls_inactive_section = cls_numbering_section + " bg-white text-black"

    if (current_active_section === section_id) {
      return cls_active_section
    } else {
      return cls_inactive_section
    }
  }

  render() {
    const {
      active_section_id,
      moveToSection
    } = this.props;

    return (
      <div className="flex flex-col items-center">
        <div className='flex flex-col justify-center items-center'>
          <div className="w-10/12 ">
            <div className="flex flex-col gap-8 mt-10 text-center text-2xl font-bold">
              Formulir Pendaftaran Pengguna Baru
            </div>
            <div className="absolute -z-20 right-0 top-20">
              <MitraImage.MataGarudaLogograph className='mt-8 fill-magnet-rose-normal opacity-10' />
            </div>
          </div>
          <div className="z-0 md:flex hidden">
            <div className="flex flex-row gap-16 justify-center font-semibold -ml-28">
              <div className="flex flex-col -mr-28 text-center z-30">
                <div
                  className={this.is_section_active(active_section_id, 1)}
                  onClick={() => moveToSection(APP_DATA.USER_REGISTRATION_SECTION.credential)}>
                  {APP_DATA.USER_REGISTRATION_SECTION.credential}
                </div>
                <div className="uppercase">Credentials</div>
              </div>
              <div className="flex flex-col">
                <div>
                  <hr className="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700" />
                </div>
              </div>
              <div className="flex flex-col -mx-28 text-center z-30">
                <div
                  className={this.is_section_active(active_section_id, 2)}
                  onClick={() => moveToSection(APP_DATA.USER_REGISTRATION_SECTION.profile)}>
                  {APP_DATA.USER_REGISTRATION_SECTION.profile}
                </div>
                <div className="uppercase">Profil</div>
              </div>
              <div className="flex flex-col">
                <div>
                  <hr className="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700" />
                </div>
              </div>
              <div className="z-30 flex flex-col -mx-28 text-center ">
                <div
                  className={this.is_section_active(active_section_id, 3)}
                  onClick={() => moveToSection(APP_DATA.USER_REGISTRATION_SECTION.socialmedia)}>
                  {APP_DATA.USER_REGISTRATION_SECTION.socialmedia}
                </div>
                <div className="uppercase whitespace-nowrap">Social Media</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default UserRegisterNavigation