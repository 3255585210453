import React from 'react'
import CariLowongan from './sections/CariLowongan'
import CareerList from './sections/CareerList'


class CareerView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }

  }

  render() {
    const { postLowongan } = this.props;
    return (
      <div className="flex flex-col items-center font-poppins xl:mt-10 max-xl:mt-20">
        <div className='w-10/12'>
          <div className='flex flex-col gap-8 '>
            <div className='flex flex-col gap-4'>
              <p className='text-4xl font-semibold'>Temukan karir impianmu</p>
              <p className='text-lg font-light'>Jelajahi beragam peluang terbaik untuk masa depanmu!</p>
            </div>
            <CariLowongan />

            <CareerList content={postLowongan} />

          </div>

        </div>

      </div>
    )
  }
}

export default CareerView