import React from 'react'
// import * as APP_DATA from '../../../ConstantData';
import { Icons } from '../../../entity/Icons';
import { Page } from '../../../entity/Pages';
import ProfileCard from '../../cards/ProfileCard';
// import SalamPenggunaBanner from '../banners/SalamPenggunaBanner';
import { ReactComponent as Mlfipped } from '../../../../image/magnet-logo-white-flipped.svg';


class SalamPengguna extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }

  }

  render() {
    const {
      navigator
    } = this.props

    const users = [
      {
        "id": 52,
        "fullname": "Guntur Nur Hidayat",
        "city": "Tangerang Selatan",
        "country": "Indonesia",
        "university": "University of Illinois at Urbana-Champaign",
        "majorstudy": "Accounting & Business",
        "picture": "guntur_nur_hidayat1cfbef77152a4f0cb1c8e867415754af1eb093dddea3787da98f9b8a27cb78c9.jpg",
        "pk": 153,
        "expertise": "Finance, Accounting, Tax",
        "description": "Setelah mendapatkan gelar University of Illinois Urbana-Champaign USA, saya semakin passionate dengan ilmu finance, accounting, dan tax. \r\nSaya pun membangun proyek-proyek yang dapat menyalurkan kemampuan di bidang keuangan guna berkontribusi lebih kepada Indonesia, diantaranya mulai dari membantu pemerintahan desa dalam mengembangkan sektor usahanya, hingga membantu membuat financial modeling dalam merancang pembangunan beberapa rumah susun di IKN.",
        "reason": "Harapan saya, dengan berjejaring kita dapat menciptakan kolaborasi hebat antar anggota Mata Garuda yang luar biasa!",
        "whatsapp": "+6281286445171",
        "instagram": "https://www.instagram.com/gvntr/",
        "linkedin": "https://www.linkedin.com/in/gunturh/",
        "twitter": null,
        "created_at": "2023-05-12T01:11:37.000Z",
        "updated_at": "2023-05-12T01:11:37.000Z",
        "is_verified": true,
        "username": "gunturnurhidayat",
        "is_active": true
      },
      {
        "id": 12,
        "fullname": "Christian Gerald Daniel",
        "city": "Tangerang",
        "country": "Indonesia",
        "university": "TU Delft",
        "majorstudy": "Civil Engineering",
        "picture": "christian_gerald_daniele0c4f63f8a5fcbcb1419c239d77b858c2a6a4dedd726301c19e6e77b0360dcae.jpg",
        "pk": 30,
        "expertise": "Teknik sipil, R&D, research writing, article writing",
        "description": "A passionate and creative researcher in road engineering working at Pelita Harapan University. Experienced in tailoring and participating in research, education and construction-related projects. Capable of working independently and becoming part of a project team. \r\nHolding master degree in Civil Engineering from TU Delft with a specialisation in Structural Engineering. \r\nCurrently holding the SVP of Partnership Division at Mata Garuda",
        "reason": "Menjalin relasi dengan sesama alumni LPDP serta mencari kesempatan untuk kolaborasi dalam bidang infrastruktur, keberlanjutan, dan pengembangan riset.",
        "whatsapp": "+6281222433924",
        "instagram": "https://www.instagram.com/christian_geralddaniel/",
        "linkedin": "https://www.linkedin.com/in/christiangdaniel/",
        "twitter": null,
        "created_at": "2023-04-05T17:38:52.000Z",
        "updated_at": "2023-04-05T17:38:52.000Z",
        "is_verified": true,
        "username": "christian.geralddaniel",
        "is_active": true
      },
      {
        "id": 11,
        "fullname": "Muhammad Razif Rizqullah",
        "city": "Bandung",
        "country": "Indonesia",
        "university": "Institut Teknologi Bandung",
        "majorstudy": "Magister Informatika",
        "picture": "muhammad_razif_r2dd6903cd4d8de9a1bd55917a11cf0b5d3bbace7668ce5e1d74ffbad61e30f89.jpg",
        "pk": 185,
        "expertise": "Teknologi, AI, NLP",
        "description": "Halo semuanya. \r\n\r\nSaat ini saya sedang melakukan studi di bidang informatika dengan research interest di Natural Language Processing (NLP) khususnya pengolahan teks untuk Question Answering (QA). Saya tertarik untuk mendalami tentang bagaimana cara komputer secara matematis bisa mengingat informasi dan melakukan inferensi dari pengetahuan yang ada.",
        "reason": "Ingin berkolaborasi dengan rekan LPDP dan yang lainnya",
        "whatsapp": "+6282238639221",
        "instagram": "instagram.com/rizquuula",
        "linkedin": "https://www.linkedin.com/in/razifrizqullah/",
        "twitter": null,
        "created_at": "2023-03-30T04:15:11.000Z",
        "updated_at": "2023-03-30T04:15:11.000Z",
        "is_verified": true,
        "username": "razifrizqullah",
        "is_active": true
      },
      {
        "id": 339,
        "fullname": "Wahyu Bagus Yuliantok",
        "city": "Jakarta",
        "country": "Indonesia",
        "university": "The University of Melbourne",
        "majorstudy": "Master of Management (Finance)",
        "picture": "wahyu_bagus_yuliantokee3263c3b0f38b62edccbdbc8ad56575f84fa44d6da40bb756eceaa4ea1224c9.jpg",
        "pk": 112,
        "expertise": "Finance",
        "description": "Recognised as a corporate banker with over thirteen years of extensive experience. Currently assigned as Division Head of National Interest Account (NIA) at Indonesia Eximbank that has a primary focus on overseeing NIA programs aimed to boost nation's export output. Previously involved in co-financing various infrastructure projects undertaken by SOEs. \r\n\r\nObtained an executive education in strategic management and leadership from Ross School of Business - University of Michigan (USA), acquired a master degree in Management (Finance) from Melbourne Business School - University of Melbourne (Australia) and bestowed several merit-based awards in both educational and professional settings.",
        "reason": "Networking is a mutually beneficial interaction that involves exchanging ideas and information between individuals who are connected by a common career, industry, or interest. It can open doors to new opportunities that might be out of reach otherwise. Plus, a strong professional relationship can inspire someone to hand-deliver our resume to the hiring manager’s desk. Ultimately, building a professional network not only helps us find new opportunities, but it can help us secure a new role in the future, too.",
        "whatsapp": "081311022033",
        "instagram": "bagus.yuliantok",
        "linkedin": "https://www.linkedin.com/in/bagusyuliantok/",
        "twitter": null,
        "created_at": "2023-07-23T07:23:34.000Z",
        "updated_at": "2023-07-23T07:23:34.000Z",
        "is_verified": true,
        "username": "bagusyuliantok",
        "is_active": true
      }
    ]
    return (
      <div className='relative py-40'>
        <div className='-z-10 -left-20 absolute top-0 opacity-5 bg-magnet-black-normal rounded-[5rem] w-full h-full'></div>
        <Mlfipped className='hidden md:flex -z-10 -left-20 absolute top-0 fill-white opacity-60 h-full' />
        <div className='flex flex-col gap-4 w-full'>
          <div className='flex md:flex-row flex-col justify-between'>
            <div className='flex items-center gap-2'>
              <p className='text-4xl md:text-5xl font-extrabold text-magnet-black-normal'>Salam Pengguna</p>
            </div>
          </div>
          <div className='flex md:flex-row flex-col justify-between md:items-end items-start gap-2'>
            <p className='text-lg md:text-xl font-light text-magnet-black-light'>Salam hangat dari keluarga Awardee LPDP</p>
            <button className='flex flex-row items-center px-6 py-1 bg-magnet-yellow-normal hover:bg-magnet-yellow-light duration-500 rounded-xl'
              onClick={() => navigator(Page.NETWORK)}>
              <p className='text-magnet-black-normal'>Pelajari Selengkapnya</p>
              <Icons.ANGLE_RIGHT className='fill-magnet-black-normal h-8' />
            </button>
          </div>
          <div className='flex justify-center items-center mt-4'>
            <div className='grid md:grid-cols-4 grid-cols-1 gap-6'>
              {users.map(function (user, i) {
                return <ProfileCard key={i} user={user} navigator={navigator} updateSelectedCategory={console.log} category={""} />
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SalamPengguna