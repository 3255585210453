import React, { useState } from 'react'
// import * as APP_DATA from '../../../ConstantData';
import { Icons } from '../../../entity/Icons';
import { hideComponent } from '../../../utils/ShowHideComponent';

const category = [{ tampil: 0, list: 1, number: 1, value: "Hari ini" },
{ tampil: 0, list: 2, number: 1, value: "Kemarin" },
{ tampil: 0, list: 3, number: 1, value: "Bulan ini" },
{ tampil: 0, list: 4, number: 1, value: "Bulan Lalu" },
{ tampil: 0, list: 5, number: 2, value: "-" },
{ tampil: 0, list: 6, number: 2, value: "--" },
{ tampil: 0, list: 7, number: 2, value: "---" },
{ tampil: 0, list: 8, number: 2, value: "----" },
{ tampil: 0, list: 9, number: 3, value: "-" },
{ tampil: 0, list: 10, number: 3, value: "--" },
{ tampil: 0, list: 11, number: 3, value: "---" },
{ tampil: 0, list: 12, number: 3, value: "----" },
{ tampil: 0, list: 13, number: 4, value: "-" },
{ tampil: 0, list: 14, number: 4, value: "--" },
{ tampil: 0, list: 15, number: 4, value: "---" },
{ tampil: 0, list: 16, number: 4, value: "----" },
{ tampil: 0, list: 17, number: 5, value: "-" },
{ tampil: 0, list: 18, number: 5, value: "--" },
{ tampil: 0, list: 19, number: 5, value: "---" },
{ tampil: 0, list: 20, number: 5, value: "----" },
{ tampil: 0, list: 21, number: 6, value: "-" },
{ tampil: 0, list: 22, number: 6, value: "--" },
{ tampil: 0, list: 23, number: 6, value: "---" },
{ tampil: 0, list: 24, number: 6, value: "----" },]


const cat = [{
  nama: 'Dipasang', number: 1
},
{
  nama: 'Lokasi', number: 2
},
{ nama: 'Jenis', number: 3 },
{ nama: 'Perusahaan', number: 4 },
{ nama: 'Spesialisasi', number: 5 },
{ nama: 'Tingkat', number: 6 }]

function Accordion() {
  // Declare a new state variable, which we'll call "count"

  let hitungan = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];


  const [counters, setCounters] = useState(
    hitungan)



  const [count, setCount] = useState();


  return (
    <><div className="relative w-12/12 ">
      <div className="flex flex-row w-full gap-4 absolute max-lg:hidden max-lg:invisible lg:invisible xl:visible"> {cat.map((b) => {
        return (
          <div
            onClick={() => setCount(b.number)}
            className={
              (b.nama.length < 7)
                ? "py-8 px-8 w-28 z-30 cursor-pointer mb-3 rounded-xl "
                : (b.nama.length > 6 && b.nama.length < 10) ? "py-8 px-8 w-32 z-30  cursor-pointer mb-3 rounded-xl "
                  : "py-8 px-8 w-40 z-30  cursor-pointer mb-3 rounded-xl "
            }
          >
            <div onClick={() => setCount(b.number)}

              className={b.nama.length < 7 ? "flex flex-row border w-28 text-center justify-center border-magnet-primary close font-bold rounded-lg flex justify-between px-4 py-3 "
                : (b.nama.length > 6 && b.nama.length < 10) ? "flex  flex-row border w-32 text-center justify-center border-magnet-primary close font-bold rounded-lg flex justify-between pr-4 pl-2 py-3 "
                  : "flex flex-row border w-40 text-center justify-center border-magnet-primary close font-bold rounded-lg flex justify-between px-4 py-3 "
              } >
              <div className="flex ml-3 text-center flex-row justify-center">
                <p>{b.nama}</p>
                <div className=" flex items-left">

                  <Icons.ANGLE_DOWN className="fill-magnet-primary" />

                </div>
              </div>
            </div>
            {count === b.number &&
              category.map((a, i) => {
                if (a.number === b.number)
                  return (<div className="flex flex-col bg-white border border-gray w-40">
                    <div  ><p onClick={a.tampil = 1} className="px-4 py-3 w-40 text-sm pr-16 hover:bg-magnet-primary hover:text-white ">
                      {a.value}
                    </p></div>

                  </div>);
              })}
          </div>)
          ;
      })}

        <div className="flex-grow "></div>
        <div className="flex flex-row content-end text-magnet-primary -mt-2 font-semibold absolute right-0 top-14">
          <button>Reset Filter</button>
        </div>
      </div>
    </div >

      <hr className="h-px mt-32 bg-gray-200 border-0  max-xl:invisible dark:bg-gray-700"></hr>

      <div className="flex flex-row my-4 ml-8">
        {category.map((a, i) => {
          if (a.tampil !== 0)
            return (
              <div className="mr-2 flex flex-row shrink bg-gray rounded-md rounded-md bg-gray-200 text-gray-400 px-2 flex-row">
                <div>{a.value}</div>
                <div className="flex flex-grow"></div>
                <div className="mt-1 ml-2" >
                  <Icons.TIMES onClick={a.tampil = 0}
                    className="h-4 w-4 fill-magnet-gray hover:fill-magnet-primary  rounded-full duration-500" />
                </div>
              </div>);
        })}

      </div></>
  );
}




class CariLowongan extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }

  }

  render() {
    const {
      navigateToCareer
    } = this.props;

    return (
      <>
        <div className='flex flex-col'>
          <div className='bg-gray-100 max-xl:-mb-40 rounded-xl flex flex-row p-8 gap-6 items-center justify-between'>
            <div className='w-11/12 bg-white rounded-xl flex flex-row items-center justify-between'>
              <div className='px-4'>
                <Icons.SEARCH className='h-10 fill-magnet-primary' />
              </div>
              <input className='p-4 w-full' type='text' placeholder='Cari Lowongan...' />
              <div className='px-4 cursor-pointer'>
                <Icons.TIMES className='h-6 w-6 fill-magnet-gray hover:fill-magnet-primary bg-gray-100 rounded-full duration-500' />
              </div>
            </div>
            <button className='lg:w-1/12 md:w-3/12 sm:w-4/12 bg-magnet-primary rounded-xl p-4 text-magnet-primarylight border border-magnet-primary hover:bg-white hover:text-magnet-primary duration-500'
              onClick={navigateToCareer}>
              Cari
            </button>
          </div>

          <Accordion />

          <div className='flex flex-row mt-6 gap-8'>


          </div>

        </div>
      </>
    )
  }
}

export default CariLowongan