import Company1 from "../../image/company-list/company 1.png";
import Company2 from "../../image/company-list/company 2.png";
import Company3 from "../../image/company-list/company 3.png";
import Company4 from "../../image/company-list/company 4.png";
import Company5 from "../../image/company-list/company 5.png";
import Company6 from "../../image/company-list/company 6.png";

export const CompanyList = {
  Company1: Company1,
  Company2: Company2,
  Company3: Company3,
  Company4: Company4,
  Company5: Company5,
  Company6: Company6,
};
