import UserModel from './domain/UserModel'
import UserProfileModel from './domain/UserProfileModel'
import { makeAutoObservable } from 'mobx'

class RootStore {
    constructor() {
        makeAutoObservable(this)
    }

    static type = {
        USER_MODEL: 'userModel',
        PROFILE_MODEL: 'profileModel',
    }

    userModel = new UserModel()
    profileModel = new UserProfileModel()


    getStores = () => ({
        [RootStore.type.USER_MODEL]: this.userModel,
        [RootStore.type.PROFILE_MODEL]: this.profileModel
    })
}

export default RootStore