import * as APP_DATA from '../../../core/ConstantData';
import CareerView from './CareerView';
import React from 'react'
import { DUMMYLOWONGAN } from '../../DummyData';


class CareerController extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }

  }

  render() {
    document.title = APP_DATA.APP_NAME
    return (
      <CareerView postLowongan={DUMMYLOWONGAN} />
    )
  }
}

export default CareerController