import React from "react";
import { TopFunction } from '../../../utils/TopFunction';
import { Page } from "../../../entity/Pages";
import * as APP_DATA from '../../../../core/ConstantData';


class MgtalksContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { navigator, mgtalks } = this.props;
    const {
      slug,
      title,
      cover_url,
    } = mgtalks;

    const mgtalk_path = Page.MGTALKDETAIL.replace(':slug', slug);
    return (
      <div className="flex flex-col rounded-2xl overflow-hidden shadow-2xl cursor-pointer" onClick={() => {TopFunction(); navigator(mgtalk_path)}}>
          <img className="w-auto max-h-[420px] object-cover" src={cover_url} alt="" />
          <div className="px-6 pt-6 pb-8 flex-1">
              <div className="flex flex-col h-full justify-between">
                  <div className="flex flex-col items-start">
                      <div className="flex flex-row items-center gap-2 mb-4">
                          <img alt='' src={APP_DATA.NEW_LOGO_MAGNET} className='cursor-pointer w-16'></img>
                          <span>Mata Garuda Network</span>
                      </div>
                      <h4 className="font-bold text-xl mb-2">{title}</h4>
                  </div>
              </div>
          </div>
      </div>
    );
  }
}

export default MgtalksContent;
