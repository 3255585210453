import { useNavigate } from 'react-router-dom';
import * as APP_DATA from '../../ConstantData';
import React from 'react'
import LoginView from './LoginView';
import { hideComponent, showComponent } from '../../utils/ShowHideComponent';
import { observer } from 'mobx-react';
import axios from 'axios';
import { API } from '../../entity/API';
import { store_email, store_jwt } from '../../utils/JwtToken';
import { Page } from '../../entity/Pages';


class LoginController extends React.Component {
  constructor(props) {
    super(props)
    this.hideComponent = hideComponent.bind(this)
    this.showComponent = showComponent.bind(this)
    this.store_jwt = store_jwt.bind(this)
    this.store_email = store_email.bind(this)

    this.profileUpdate = this.profileUpdate.bind(this)
    this.navigator = this.navigator.bind(this)
    this.callAPILogin = this.callAPILogin.bind(this)
    this.switchPasswordType = this.switchPasswordType.bind(this)
    this.state = {
      error_messages: [],
      is_login_on_process: false,
      password_field_type: 'password',
    }
  }

  componentDidMount() {
    let mgnt_header = document.getElementById('mgnt-header')
    let mgnt_footer = document.getElementById('mgnt-footer')
    this.hideComponent(mgnt_header)
    this.hideComponent(mgnt_footer)
  }

  componentWillUnmount() {
    let mgnt_header = document.getElementById('mgnt-header')
    let mgnt_footer = document.getElementById('mgnt-footer')
    this.showComponent(mgnt_header)
    this.showComponent(mgnt_footer)
  }

  navigator(path) {
    this.props.navigate(path)
  }

  profileUpdate(key_val) {
    this.props.profileModel.update(key_val)
  }

  switchPasswordType() {
    if (this.state.password_field_type === 'password') {
      this.setState({ password_field_type: 'text' })
    } else {
      this.setState({ password_field_type: 'password' })
    }
  }

  async callAPILogin() {
    const formData = this.props.profileModel.read_formdata_login()
    const self = this
    self.setState({ is_login_on_process: true })
    try {
      const response = await axios.post(
        API.POST_LOGIN,
        formData,
        {
          headers: {
            // 'Authorization': `Bearer ${jwt}`,
            'Content-Type': 'multipart/form-data'
          }
        });
      // console.log(response.data);
      if (response.data.status === 'OK') {
        const jwt = response.data.jwt
        if (jwt) {
          self.store_jwt(jwt)
          self.store_email(formData.get('email'))
          window.location.href = Page.NETWORK
        } else {
          self.setState({
            error_messages: ['Silahkan konfirmasi akun anda melalui link yang kami kirimkan via email.'],
            is_login_on_process: false
          })
        }
      } else {
        self.setState({
          error_messages: response.data.message,
          is_login_on_process: false
        })
      }
    }
    catch (error) {
      if (error.response !== undefined) {
        self.setState({ is_login_on_process: false })
        // console.log(error.response);
      }
    }
  }

  render() {
    document.title = `Masuk | ${APP_DATA.APP_NAME}`
    return (
      <LoginView
        profile_data={this.props.profileModel.read()}
        profileUpdate={this.profileUpdate}
        navigator={this.navigator}
        callAPILogin={this.callAPILogin}
        switchPasswordType={this.switchPasswordType}
        error_messages={this.state.error_messages}
        is_login_on_process={this.state.is_login_on_process}
        password_field_type={this.state.password_field_type}
      />
    )
  }
}


export default (props) => {
  const navigate = useNavigate();
  const Controller = observer(LoginController)
  return (
    <Controller {...props} navigate={navigate} />
  )
}