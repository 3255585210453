import React from "react";
import { Icons } from "../../entity/Icons";
import { Page } from "../../entity/Pages";
import { MitraImage } from "../../entity/MitraImage";

class FooterLoginMitraView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { navigator } = this.props;

    return (
      <div className="flex justify-center py-12 h-32 font-poppins bg-magnet-secondary to-magnet-secondarygradient"></div>
    );
  }
}

export default FooterLoginMitraView;
