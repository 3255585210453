import React from "react";
import { Icons } from "../../../entity/Icons";

class PageFour extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className=" gap-6">
        <div className="text-xl font-bold text-magnet-secondarydark">
          Detail Pembayaran
        </div>
        <div className="flex mt-4 flex-row text-lg font-semibold">
          <div>Pilih Bank Penerima</div>
          <div className="text-magnet-secondary">*</div>
        </div>
        <input
          className="shadow mt-2 mb-6 appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
          id="bank"
          type="text"
          placeholder="Pilih jenis"
        ></input>
        <div className="flex flex-row text-lg font-semibold">
          <div>Nama Pemilik Rekening Penerima</div>
          <div className="text-magnet-secondary">*</div>
        </div>
        <input
          className="shadow mt-2 mb-6  appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
          id="pemilik"
          type="text"
          placeholder="Tulis nama penerima"
        ></input>
        <div className="flex flex-row text-lg font-semibold">
          <div>Nomor Rekening Penerima</div>
          <div className="text-magnet-secondary">*</div>
        </div>
        <input
          className="shadow mt-2 mb-6  appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
          id="rekening"
          type="number"
          placeholder="Tulis rekening penerima"
        ></input>
        <div className="flex flex-row mb-2 text-lg font-semibold">
          <div>Jadwal Pembayaran</div>
          <div className="text-magnet-secondary">*</div>
        </div>
        <div className="flex flex-row">
          <label className="cursor-pointer">
            <input type="radio" className="peer sr-only rounded-full" name="pricing" />
            <div className=" mr-1 shadow-xl ring-1 ring-gray-500 peer-checked:ring-0 transition-all  rounded-full text-white peer-checked:text-magnet-secondary">


              <div className="flex flex-row">
                <svg width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m10.6 13.8l-2.175-2.175q-.275-.275-.675-.275t-.7.3q-.275.275-.275.7q0 .425.275.7L9.9 15.9q.275.275.7.275q.425 0 .7-.275l5.675-5.675q.275-.275.275-.675t-.3-.7q-.275-.275-.7-.275q-.425 0-.7.275ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z" /></svg>




              </div>
            </div>
          </label>
          <p>2 Minggu</p>
          <label className="cursor-pointer">
            <input type="radio" className="peer sr-only" name="pricing" />
            <div className=" ml-4 mr-1 shadow-xl ring-1 ring-gray-500 peer-checked:ring-0 transition-all  rounded-full text-white peer-checked:text-magnet-secondary">


              <div className="flex flex-row">
                <svg width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m10.6 13.8l-2.175-2.175q-.275-.275-.675-.275t-.7.3q-.275.275-.275.7q0 .425.275.7L9.9 15.9q.275.275.7.275q.425 0 .7-.275l5.675-5.675q.275-.275.275-.675t-.3-.7q-.275-.275-.7-.275q-.425 0-.7.275ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z" /></svg>




              </div>
            </div>
          </label>
          <p>1 Bulan</p>
        </div>
        <div className="flex flex-row mt-6 mb-2 text-lg font-semibold w-2/3">
          <div className="flex flex-col mr-6 mb-6">
            <div className="flex mt-4 flex-row text-lg font-semibold">
              <div>Foto tandatangan sebagai bentuk persetujuan kemitraan</div>
              <div className="text-magnet-secondary">*</div>
            </div>
            <div className="flex w-3/4 flex-row mt-4 text-sm text-gray font-normal">
              <div>Format gambar .jpg .jpeg .png dan ukuran minimum 300 x 300px (Untuk gambar optimal gunakan ukuran minimum 700 x 700 px).
              </div><div className="flex items-center ml-8 justify-center">
                <label
                  for="dropzone-file"
                  className="flex flex-col items-center justify-center w-24 h-24 border-2 border-magnet-secondary border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <Icons.IMAGE_PLUS className="fill-gray-400" />
                  </div>
                  <input id="dropzone-file" type="file" className="hidden" />
                </label>
              </div></div>

          </div>
          <div className="flex flex-row gap-6">
          </div>
        </div></div>
    );
  }
}

export default PageFour;
