import * as APP_DATA from '../../ConstantData';
import NetworkView from './NetworkView';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { read_jwt } from '../../utils/JwtToken';
import { API } from '../../entity/API';
import axios from 'axios';
// import { Page } from '../../entity/Pages';


class NetworkController extends React.Component {
  constructor(props) {
    super(props)
    this.read_jwt = read_jwt.bind(this)

    this.updateSelectedCategory = this.updateSelectedCategory.bind(this)
    this.updateCurrentPage = this.updateCurrentPage.bind(this)
    this.updateSearchName = this.updateSearchName.bind(this)
    this.doSearchName = this.doSearchName.bind(this)

    this.state = {
      categories: [],
      page_number: 1,
      prev_page_number: null,
      category: '',
      prev_category: '',
      search_name: '',
      prev_search_name: '',
      users: [],
      total_user: null,
      user_each_page: null,
    }

  }

  componentDidMount() {
    if (this.read_jwt()) {
      this.callAPICategory()
      this.callAPIUsers()
    } else {
      // window.location.href = Page.LOGIN
    }
  }

  componentDidUpdate() {
    if (this.state.prev_category !== this.state.category) {
      this.callAPIUsers()
    }
    if (this.state.prev_page_number !== this.state.page_number) {
      this.callAPIUsers()
    }

  }

  async callAPICategory() {
    const self = this
    try {
      const response = await axios.get(
        API.GET_CATEGORIES,
        {
          headers: {
            'Authorization': `Bearer ${self.read_jwt()}`,
            // 'Content-Type': 'multipart/form-data'
          }
        });
      // console.log(response.data)
      if (response.data.status === 'OK') {
        self.setState({ categories: response.data.categories })
      } else {

      }
    }
    catch (error) {
      if (error.response !== undefined) {
        // console.log(error.response);
      }
    }
  }

  async callAPIUsers() {
    const self = this
    try {
      const response = await axios.get(
        API.GET_USERS,
        {
          headers: {
            'Authorization': `Bearer ${self.read_jwt()}`,
            // 'Content-Type': 'multipart/form-data'
          },
          params: {
            page: self.state.page_number,
            category: self.state.category,
          }
        });
      // console.log(response.data)
      if (response.data.status === 'OK') {
        self.setState({
          users: response.data.users,
          prev_category: self.state.category,
          prev_page_number: self.state.page_number,
          total_user: response.data.meta['total-user'],
          user_each_page: response.data.meta['user-each-page'],
        })
      } else {

      }
    }
    catch (error) {
      if (error.response !== undefined) {
        // console.log(error.response);
      }
    }
  }

  async callAPISearchUsers(search_name, page_number) {
    const self = this
    try {
      const response = await axios.get(
        API.GET_SEARCH_USERS,
        {
          headers: {
            'Authorization': `Bearer ${self.read_jwt()}`,
            // 'Content-Type': 'multipart/form-data'
          },
          params: {
            page: page_number,
            name: search_name,
          }
        });
      // console.log(response.data)
      if (response.data.status === 'OK') {
        self.setState({
          users: response.data.users,
          prev_search_name: self.state.search_name,
          prev_page_number: self.state.page_number,
          total_user: response.data.meta['total-user'],
          user_each_page: response.data.meta['user-each-page'],
        })
      } else {

      }
    }
    catch (error) {
      if (error.response !== undefined) {
        // console.log(error.response);
      }
    }
  }

  updateSelectedCategory(value) {
    if (value !== this.state.category) {
      this.setState({
        category: value,
        users: [],
      })
    }
  }

  updateCurrentPage(value) {
    if (value !== this.state.page_number) {
      this.setState({
        page_number: value,
        users: [],
      })
    }
  }

  updateSearchName(value) {
    if (value !== this.state.search_name) {
      this.setState({ search_name: value })
    }
  }

  doSearchName() {
    if (this.state.search_name !== this.state.prev_search_name) {
      let trimmed_search_name = this.state.search_name.toString().trim()
      if (trimmed_search_name) {
        this.callAPISearchUsers(trimmed_search_name, 1)
      }
      else {
        this.callAPIUsers()
        this.setState({ prev_search_name: '' })
      }
    }
  }

  getPageNumberInfo() {
    let current_page = this.state.page_number
    let is_far_left_page = false
    let is_far_right_page = false

    let num_showed_page = APP_DATA.MAX_PAGE_NUMBER_SHOWED
    let number_of_pages = Math.ceil(this.state.total_user / this.state.user_each_page)
    let page_number_for_render = []

    // build page number for render
    if (current_page <= 3) {
      // for small page number show all
      for (let i = 0; i < num_showed_page; i++) {
        if ((i + 1) <= number_of_pages)
          page_number_for_render.push(i + 1)
      }
    } else if (current_page > 3) {
      // big page number, show half before and half after of the current page
      for (let i = 0; i < num_showed_page; i++) {
        // page num to render, start to lower possible by `- Math.floor(APP_DATA.MAX_PAGE_NUMBER_SHOWED / 2)`
        const p = i + current_page - Math.floor(APP_DATA.MAX_PAGE_NUMBER_SHOWED / 2)
        if (p <= number_of_pages) // prevent page num to render exceed the maximum page number
          page_number_for_render.push(p)
      }
    }

    // whether show far right / maximum number / total pages
    if (page_number_for_render.includes(number_of_pages) === false) {
      if (page_number_for_render.includes(number_of_pages - 1)) {
        page_number_for_render.push(number_of_pages)
      } else {
        is_far_right_page = true
      }
    }

    // whether show far left / minimum number / 1
    if (page_number_for_render.includes(1) === false) {
      if (page_number_for_render.includes(2)) {
        page_number_for_render.unshift(1)
      } else {
        is_far_left_page = true
      }
    }

    const page_number_info = {
      current_page: current_page,
      total_page: number_of_pages,
      active_page_number: page_number_for_render,
      is_show_far_left_page: is_far_left_page,
      is_show_far_right_page: is_far_right_page,
    }
    return page_number_info
  }

  render() {
    document.title = APP_DATA.APP_NAME
    if (this.read_jwt()) {
      return (
        <NetworkView
          navigator={this.props.navigate}
          search_name={this.state.search_name}
          updateSelectedCategory={this.updateSelectedCategory}
          updateCurrentPage={this.updateCurrentPage}
          updateSearchName={this.updateSearchName}
          doSearchName={this.doSearchName}
          categories={this.state.categories}
          category={this.state.category}
          users={this.state.users}
          page_number_info={this.getPageNumberInfo()}
        />
      )
    } else {
      return <></>
    }
  }
}

export default (props) => {
  const navigate = useNavigate();
  return (
    <NetworkController {...props} navigate={navigate} />
  )
}