import React from "react";
import { Icons } from "../../entity/Icons";
import { Page } from "../../entity/Pages";
import { MitraImage } from "../../entity/MitraImage";
import Copyright from "./Copyright";

class FooterMitraView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { navigator } = this.props;

    return (
      <div className="flex justify-center py-12 h-64 font-poppins bg-gradient-to-b from-magnet-secondary to-magnet-secondarygradient">
        <div className="flex flex-col w-10/12 gap-6">
          <div className="flex flex-col w-10/12 gap-6">
            {" "}
            <div className="flex flex-row justify-between gap-4">
              <div>
                <img
                  alt=""
                  src={MitraImage.vector2}
                  className="cursor-pointer"
                  onClick={() => navigator(Page.HOME)}
                ></img>
              </div>
              <div className="w-1/2 text-white flex flex-col">
                <div className="font-bold text-xl mb-2">
                  Mata Garuda Network
                </div>
                <div className=" mb-4 ">
                  Jalan Cikini Raya No. 91, RT.1/RW.2, ABCD, Menteng, Kota
                  Jakarta Pusat, Daerah Khusus Ibu kota Jakarta 10330
                </div>
                <div className="font-medium mb-2">Ikuti kami di</div>
                <div className="flex flex-row gap-2">
                  <div className="rounded-full bg-white p-2">
                    <Icons.LINKEDIN className="fill-magnet-secondary duration-500 cursor-pointer" />
                  </div>{" "}
                  <div className="rounded-full bg-white p-2">
                    <Icons.INSTAGRAM className="fill-magnet-secondary duration-500 cursor-pointer" />
                  </div>{" "}
                  <div className="rounded-full bg-white p-2">
                    <Icons.TWITTER className="fill-magnet-secondary duration-500 cursor-pointer" />
                  </div>{" "}
                  <div className="rounded-full bg-white p-2">
                    <Icons.YOUTUBE className="fill-magnet-secondary duration-500 cursor-pointer" />
                  </div>{" "}
                </div>
              </div>
              <div className="flex flex-col text-white">
                <div className="font-semibold mb-2">Tentang Kami</div>
                <div>About us</div>
                <div>FAQ</div>
                <div>Privacy</div>
              </div>
              <div className="flex flex-col text-white ">
                <div className="font-semibold mb-2">Link Terkait</div>
                <div>matagaruda.id</div>
                <div>lpdp.go.id</div>
              </div>
            </div>{" "}
          </div>
          <Copyright />
        </div>
      </div>
    );
  }
}

export default FooterMitraView;
