import React from "react";

class AboutusContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    return (
      

      <div className="w-10/12 bg-white self-center">
        <div className="flex flex-col md:flex-row justify-center items-start w-full gap-6 md:gap-8">
          <div className="w-full md:w-6/12">
            <h2 className="text-magnet-rose-normal text-3xl md:text-5xl font-bold text-left leading-normal">Our Journey</h2>
          </div>
          <div className="w-full md:w-6/12 mt-6">
            <div className="flex flex-col items-start">
              <div className="relative flex flex-row items-center">
                <div className="absolute top-0 -left-[0.425rem]">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <circle cx="8" cy="8" r="7.5" fill="url(#paint0_radial_304_14707)"/>
                    <circle cx="8" cy="8" r="7.5" fill="url(#paint1_linear_304_14707)"/>
                    <circle cx="8" cy="8" r="7.5" stroke="url(#paint2_radial_304_14707)"/>
                    <circle cx="8" cy="8" r="7.5" stroke="url(#paint3_linear_304_14707)"/>
                    <defs>
                      <radialGradient id="paint0_radial_304_14707" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.8012 6.94809) rotate(114.613) scale(36.5377 26.9885)">
                        <stop stopColor="#FF783E"/>
                        <stop offset="1" stopColor="#EE1161"/>
                      </radialGradient>
                      <linearGradient id="paint1_linear_304_14707" x1="-0.534161" y1="10.1592" x2="13.0344" y2="8.3569" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#EE1161"/>
                        <stop offset="1" stopColor="#EE1161" stopOpacity="0"/>
                      </linearGradient>
                      <radialGradient id="paint2_radial_304_14707" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.8012 6.94809) rotate(114.613) scale(36.5377 26.9885)">
                        <stop stopColor="#FF783E"/>
                        <stop offset="1" stopColor="#EE1161"/>
                      </radialGradient>
                      <linearGradient id="paint3_linear_304_14707" x1="-0.534161" y1="10.1592" x2="13.0344" y2="8.3569" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#EE1161"/>
                        <stop offset="1" stopColor="#EE1161" stopOpacity="0"/>
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="border-l-2 border-magnet-rose-normal pl-9 flex flex-col text-gray-700">
                  <p className="text-lg font-bold leading-7 mb-2">2015 - 2016</p>
                  <ul className="list-disc text-gray-700 ml-6 mb-6">
                    <li>Mata Garuda dibentuk pada 21 Januari 2015 sebagai komunitas alumni LPDP untuk kolaborasi.</li>
                    <li>Membangun kolaborasi dengan institusi keungan, riset, dan pemerintah.</li>
                  </ul>
                </div>
              </div>
              <div className="relative flex flex-row items-center">
                <div className="absolute top-0 -left-[0.425rem]">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <circle cx="8" cy="8" r="7.5" fill="url(#paint0_radial_304_14707)"/>
                    <circle cx="8" cy="8" r="7.5" fill="url(#paint1_linear_304_14707)"/>
                    <circle cx="8" cy="8" r="7.5" stroke="url(#paint2_radial_304_14707)"/>
                    <circle cx="8" cy="8" r="7.5" stroke="url(#paint3_linear_304_14707)"/>
                    <defs>
                      <radialGradient id="paint0_radial_304_14707" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.8012 6.94809) rotate(114.613) scale(36.5377 26.9885)">
                        <stop stopColor="#FF783E"/>
                        <stop offset="1" stopColor="#EE1161"/>
                      </radialGradient>
                      <linearGradient id="paint1_linear_304_14707" x1="-0.534161" y1="10.1592" x2="13.0344" y2="8.3569" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#EE1161"/>
                        <stop offset="1" stopColor="#EE1161" stopOpacity="0"/>
                      </linearGradient>
                      <radialGradient id="paint2_radial_304_14707" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.8012 6.94809) rotate(114.613) scale(36.5377 26.9885)">
                        <stop stopColor="#FF783E"/>
                        <stop offset="1" stopColor="#EE1161"/>
                      </radialGradient>
                      <linearGradient id="paint3_linear_304_14707" x1="-0.534161" y1="10.1592" x2="13.0344" y2="8.3569" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#EE1161"/>
                        <stop offset="1" stopColor="#EE1161" stopOpacity="0"/>
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="border-l-2 border-magnet-rose-normal pl-9 flex flex-col text-gray-700">
                  <p className="text-lg font-bold leading-7 mb-2">2016 - 2018</p>
                  <ul className="list-disc text-gray-700 ml-6 mb-6">
                    <li>Membangun kerjasama strategis dengan pemerintah dalam perumusan kebijakan dan kemitraan strategis dengan sektor swasta. Melibatkan 5 kementerian pusat dan lebih dari 5 pemerintah daerah. Kerjasama dalam bentuk capacity building, internship, dan exclusive recruitment dengan industri.</li>
                  </ul>
                </div>
              </div>
              <div className="relative flex flex-row items-center">
                <div className="absolute top-0 -left-[0.425rem]">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <circle cx="8" cy="8" r="7.5" fill="url(#paint0_radial_304_14707)"/>
                    <circle cx="8" cy="8" r="7.5" fill="url(#paint1_linear_304_14707)"/>
                    <circle cx="8" cy="8" r="7.5" stroke="url(#paint2_radial_304_14707)"/>
                    <circle cx="8" cy="8" r="7.5" stroke="url(#paint3_linear_304_14707)"/>
                    <defs>
                      <radialGradient id="paint0_radial_304_14707" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.8012 6.94809) rotate(114.613) scale(36.5377 26.9885)">
                        <stop stopColor="#FF783E"/>
                        <stop offset="1" stopColor="#EE1161"/>
                      </radialGradient>
                      <linearGradient id="paint1_linear_304_14707" x1="-0.534161" y1="10.1592" x2="13.0344" y2="8.3569" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#EE1161"/>
                        <stop offset="1" stopColor="#EE1161" stopOpacity="0"/>
                      </linearGradient>
                      <radialGradient id="paint2_radial_304_14707" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.8012 6.94809) rotate(114.613) scale(36.5377 26.9885)">
                        <stop stopColor="#FF783E"/>
                        <stop offset="1" stopColor="#EE1161"/>
                      </radialGradient>
                      <linearGradient id="paint3_linear_304_14707" x1="-0.534161" y1="10.1592" x2="13.0344" y2="8.3569" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#EE1161"/>
                        <stop offset="1" stopColor="#EE1161" stopOpacity="0"/>
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="border-l-2 border-magnet-rose-normal pl-9 flex flex-col text-gray-700">
                  <p className="text-lg font-bold leading-7 mb-2">2018 - 2020</p>
                  <ul className="list-disc text-gray-700 ml-6 mb-6">
                    <li>Menjadi mitra penting dalam menjembatani potensi dan talenta awardee LPDP dengan industri.</li>
                    <li>Membentuk Mata Garuda Daerah untuk memberdayakan alumni yang berada di daerah dalam rangka mendukung desentralisasi pembangunan.</li>
                  </ul>
                </div>
              </div>
              <div className="relative flex flex-row items-center">
                <div className="absolute top-0 -left-[0.425rem]">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <circle cx="8" cy="8" r="7.5" fill="url(#paint0_radial_304_14707)"/>
                    <circle cx="8" cy="8" r="7.5" fill="url(#paint1_linear_304_14707)"/>
                    <circle cx="8" cy="8" r="7.5" stroke="url(#paint2_radial_304_14707)"/>
                    <circle cx="8" cy="8" r="7.5" stroke="url(#paint3_linear_304_14707)"/>
                    <defs>
                      <radialGradient id="paint0_radial_304_14707" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.8012 6.94809) rotate(114.613) scale(36.5377 26.9885)">
                        <stop stopColor="#FF783E"/>
                        <stop offset="1" stopColor="#EE1161"/>
                      </radialGradient>
                      <linearGradient id="paint1_linear_304_14707" x1="-0.534161" y1="10.1592" x2="13.0344" y2="8.3569" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#EE1161"/>
                        <stop offset="1" stopColor="#EE1161" stopOpacity="0"/>
                      </linearGradient>
                      <radialGradient id="paint2_radial_304_14707" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.8012 6.94809) rotate(114.613) scale(36.5377 26.9885)">
                        <stop stopColor="#FF783E"/>
                        <stop offset="1" stopColor="#EE1161"/>
                      </radialGradient>
                      <linearGradient id="paint3_linear_304_14707" x1="-0.534161" y1="10.1592" x2="13.0344" y2="8.3569" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#EE1161"/>
                        <stop offset="1" stopColor="#EE1161" stopOpacity="0"/>
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="border-l-2 border-magnet-rose-normal pl-9 flex flex-col text-gray-700">
                  <p className="text-lg font-bold leading-7 mb-2">2020 - 2023</p>
                  <ul className="list-disc text-gray-700 ml-6 mb-6">
                    <li>Penguatan Mata Garuda Institute sebagai organisasi Think Tank. Membentuk tim advokasi Blue Ocean Consulting, seri networking bersama dengan para ahli.</li>
                    <li>Kerjasama dengan kementerian Kesehatan dalam pengendalian COVID-19.</li>
                  </ul>
                </div>
              </div>
              <div className="relative flex flex-row items-center">
                <div className="absolute top-0 -left-[0.425rem]">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <circle cx="8" cy="8" r="7.5" fill="url(#paint0_radial_304_14707)"/>
                    <circle cx="8" cy="8" r="7.5" fill="url(#paint1_linear_304_14707)"/>
                    <circle cx="8" cy="8" r="7.5" stroke="url(#paint2_radial_304_14707)"/>
                    <circle cx="8" cy="8" r="7.5" stroke="url(#paint3_linear_304_14707)"/>
                    <defs>
                      <radialGradient id="paint0_radial_304_14707" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.8012 6.94809) rotate(114.613) scale(36.5377 26.9885)">
                        <stop stopColor="#FF783E"/>
                        <stop offset="1" stopColor="#EE1161"/>
                      </radialGradient>
                      <linearGradient id="paint1_linear_304_14707" x1="-0.534161" y1="10.1592" x2="13.0344" y2="8.3569" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#EE1161"/>
                        <stop offset="1" stopColor="#EE1161" stopOpacity="0"/>
                      </linearGradient>
                      <radialGradient id="paint2_radial_304_14707" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.8012 6.94809) rotate(114.613) scale(36.5377 26.9885)">
                        <stop stopColor="#FF783E"/>
                        <stop offset="1" stopColor="#EE1161"/>
                      </radialGradient>
                      <linearGradient id="paint3_linear_304_14707" x1="-0.534161" y1="10.1592" x2="13.0344" y2="8.3569" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#EE1161"/>
                        <stop offset="1" stopColor="#EE1161" stopOpacity="0"/>
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="border-l-2 border-magnet-rose-normal pl-9 flex flex-col text-gray-700">
                  <p className="text-lg font-bold leading-7 mb-2">2022 - 2024 Perluasan cakupan dan tata kelola</p>
                  <ul className="list-disc text-gray-700 ml-6 mb-6">
                    <li>PPembentukan Mata Garuda Internasional.</li>
                    <li>Penguatan tata kelola yang lebih profesional.</li>
                    <li>Penguatan dampak dan kontribusi terhadap pembangunan ekonomi melalui program Massive Action untuk UMKM.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutusContent;
