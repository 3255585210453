import React from 'react'
import { Icons } from '../../../entity/Icons';
import ProgramBox from '../banners/ProgramBox';


class ProgramMagnet extends React.Component {
  render() {
    return (
      <div className='flex flex-col gap-12'>
        <div className='flex flex-col items-center text-center md:text-left gap-2 md:mt-40'>
          <p className='text-3xl md:text-5xl font-extrabold text-magnet-black-normal'>Program Mata Garuda Network</p>
          <p className='text-lg md:text-xl font-light text-magnet-black-light'>Kamu adalah prioritas, untuk itu kami hadirkan program terbaik Kami</p>
        </div>

        <div className='flex flex-col md:gap-8 gap-4'>
          <div className='flex md:flex-row flex-col md:gap-8 gap-4 items-center justify-center'>
            <ProgramBox
              icon={Icons.TRENDING_UP}
              icon_color={'stroke-white'}
              title={'Upgrading Skill'}
              description={'Meningkatkan kompetensi diri adalah suatu hal yang penting untuk dilakukan agar dapat bersaing di dunia kerja yang semakin kompetitif. Kompetensi diri dapat didefinisikan sebagai kumpulan pengetahuan, keterampilan, dan sikap yang dimiliki seseorang dalam menjalankan tugas dan tanggung jawabnya di tempat kerja.'} />
            <ProgramBox
              icon={Icons.SUITCASE_ALT}
              icon_color={'fill-white'}
              title={'Kesempatan Kerja'}
              description={'Membuka jaringan yang lebih luas dengan menghubungkan pihak pencari talent dan awardee. Memudahkan dan memperluas peluang setiap talenta dalam penempatan pekerjaan sesuai bidang spesialisnya.'} />
            <ProgramBox
              icon={Icons.LIGHT_BULB}
              icon_color={'stroke-white'}
              title={'Empowerment'} description
              ={'Memberi pemahaman pada setiap talenta agar mengerti tentang growth mindset. Awardee '} />
          </div>

          <div className='flex md:flex-row flex-col md:gap-8 gap-4 items-center justify-center'>
            <ProgramBox
              icon={Icons.USER_GROUP}
              icon_color={'stroke-white'}
              title={'Jaringan Kolaborasi'}
              description={'Memudahkan setiap talenta dalam berjejaring.'} />
            <ProgramBox
              icon={Icons.ENGLISH_TO_CHINESE}
              icon_color={'fill-white'}
              title={'Pelatihan Bahasa'}
              description={'Meningkatkan kemampuan bahasa dalam mendukung komunikasi antar negara.'} />
          </div>
        </div>
      </div>
    )
  }
}

export default ProgramMagnet