import React from "react";
//import * as APP_DATA from "../../../ConstantData";
import { Icons } from "../../../entity/Icons";

class IsiBerita extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { navigateToCareer } = this.props;

    return (
      <div className="flex flex-col">
        <div className="bg-gray-100 rounded-xl flex flex-row p-8 gap-6 items-center justify-between">
          <div className="w-11/12 bg-white rounded-xl flex flex-row items-center justify-between">
            <div className="px-4">
              <Icons.SEARCH className="h-10 fill-magnet-primary" />
            </div>
            <input
              className="p-4 w-full"
              type="text"
              placeholder="Cari Lowongan..."
            />
            <div className="px-4 cursor-pointer">
              <Icons.TIMES className="h-6 w-6 fill-magnet-gray hover:fill-magnet-primary bg-gray-100 rounded-full duration-500" />
            </div>
          </div>
          <button
            className="w-1/12 bg-magnet-primary rounded-xl p-4 text-magnet-primarylight border border-magnet-primary hover:bg-white hover:text-magnet-primary duration-500"
            onClick={navigateToCareer}
          >
            Cari
          </button>
        </div>
      </div>
    );
  }
}

export default IsiBerita;
