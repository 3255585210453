import React from 'react'
import { Icons } from '../../../entity/Icons';
// import { Icons } from '../../../entity/Icons'


class SocialMediaInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }

  }

  render() {
    const {
      profileUpdate,
      profile_data,
    } = this.props;

    let asteric_symbol = <span className="text-magnet-rose-normal">&nbsp;*</span>

    return (
      <div>
        <p className="text-xl font-bold text-magnet-rose-normal mb-6">
          Data media sosial yang anda gunakan
        </p>
        <div className='flex flex-col gap-6'>
          <div>
            <div className="md:text-lg text-sm md:font-semibold md:mb-0 mb-2">
              <p>Deskripsi diri{asteric_symbol}</p>
            </div>
            <textarea
              className="h-40 md:text-base text-sm shadow appearance-none border rounded-xl w-full md:py-4 py-2 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
              id="desc"
              placeholder="Masukkan deskripsi diri"
              value={profile_data.Description}
              onChange={(e) => { profileUpdate({ 'Description': e.target.value }); }}
            ></textarea>
          </div>
          <div>
            <div className="md:text-lg text-sm md:font-semibold md:mb-0 mb-2">
              <p>Alasan berjejaring{asteric_symbol}</p>
            </div>
            <textarea
              className="h-40 md:text-base text-sm shadow appearance-none border rounded-xl w-full md:py-4 py-2 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
              id="reason"
              placeholder="Masukkan alasan berjejaring"
              value={profile_data.Reason}
              onChange={(e) => { profileUpdate({ 'Reason': e.target.value }); }}
            ></textarea>
          </div>
          <div>
            <div className="md:text-lg text-sm md:font-semibold md:mb-0 mb-2">
              <p>Akun Media Sosial</p>
            </div>
            <div className='grid md:grid-cols-2 gap-6'>
              <div className='flex items-center'>
                <Icons.WHATSAPP className='mr-2 fill-green-700' />
                <input
                  className="md:text-base text-sm shadow appearance-none border rounded-xl w-full md:py-4 py-2 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                  id="whatsapp"
                  type="text"
                  placeholder="Nomor Whatsapp (contoh: +628XXXXXXXXXX)"
                  value={profile_data.Whatsapp}
                  onChange={(e) => { profileUpdate({ 'Whatsapp': e.target.value }); }}
                ></input>
              </div>
              <div className='flex items-center'>
                <Icons.INSTAGRAM className='mr-2 fill-pink-700' />
                <input
                  className="md:text-base text-sm shadow appearance-none border rounded-xl w-full md:py-4 py-2 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                  id="instagram"
                  type="text"
                  placeholder="Link atau username profil Instagram"
                  value={profile_data.Instagram}
                  onChange={(e) => { profileUpdate({ 'Instagram': e.target.value }); }}
                ></input>
              </div>
              <div className='flex items-center'>
                <Icons.LINKEDIN className='mr-2 fill-blue-700' />
                <input
                  className="md:text-base text-sm shadow appearance-none border rounded-xl w-full md:py-4 py-2 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                  id="linkedin"
                  type="text"
                  placeholder="Link atau username profil Linkedin"
                  value={profile_data.Linkedin}
                  onChange={(e) => { profileUpdate({ 'Linkedin': e.target.value }); }}
                ></input>
              </div>
              <div className='flex items-center'>
                <Icons.TWITTER className='mr-2 fill-blue-300' />
                <input
                  className="md:text-base text-sm shadow appearance-none border rounded-xl w-full md:py-4 py-2 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                  id="twitter"
                  type="text"
                  placeholder="Link atau username profil Twitter"
                  value={profile_data.Twitter}
                  onChange={(e) => { profileUpdate({ 'Twitter': e.target.value }); }}
                ></input>
              </div>
            </div>
          </div>
          <hr className="border-magnet-rose-normal mt-8" />
        </div>
      </div>
    );
  }
}


export default SocialMediaInfo