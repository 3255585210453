import React from 'react'
import { ReactComponent as Mlfipped } from '../../../../image/magnet-logo-white-flipped.svg';


class ProgramBox extends React.Component {
  render() {
    const { title, description, icon: Icon, icon_color } = this.props
    return (
      <div className="w-[350px] h-[350px] relative">
        <div className="w-[350px] h-[350px] left-0 top-0 absolute bg-gradient-to-bl from-magnet-orange-normal to-magnet-rose-normal rounded-[20px]" />
        <div className="absolute opacity-10">
          <Mlfipped className='absolute fill-white opacity-60 h-full -bottom-16 -left-60' />
        </div>
        <div className="p-8 absolute flex-col justify-center items-start gap-4 inline-flex">
          <div className="p-1 rounded-md border border-white border-opacity-50 justify-start items-start gap-[4.91px] inline-flex">
            <Icon className={'h-12 w-12 ' + icon_color} />
          </div>
          <div className="flex-col justify-start items-start gap-2 flex">
            <div className="text-neutral-50 text-xl font-bold leading-9">{title}</div>
            <div className="opacity-75 text-neutral-50 text-sm">{description}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default ProgramBox