import { useNavigate } from 'react-router-dom';
import * as APP_DATA from '../../ConstantData';
import React from 'react'
import ForgetPasswordView from './ForgetPasswordView';
import axios from 'axios';
import { API } from '../../entity/API';
import { Page } from '../../entity/Pages';
import { ValidateEmail } from '../../utils/Checker';


class ForgetPasswordController extends React.Component {
  constructor(props) {
    super(props)
    this.ValidateEmail = ValidateEmail.bind(this)
    this.navigator = this.navigator.bind(this)
    this.callAPISendForgetPasswordEmail = this.callAPISendForgetPasswordEmail.bind(this)
    this.updateEmail = this.updateEmail.bind(this)
    this.updateNewPassword = this.updateNewPassword.bind(this)
    this.updateRetypeNewPassword = this.updateRetypeNewPassword.bind(this)
    this.callAPISetPassword = this.callAPISetPassword.bind(this)
    this.redirectToLogin = this.redirectToLogin.bind(this)

    this.is_check_key = false
    this.token = new URLSearchParams(window.location.search).get('key');

    this.state = {
      email: '',
      is_sending_email: false,
      error_message: null,
      success_message: null,
      new_password: '',
      retype_new_password: '',
      is_setting_new_password: false,
    }
  }

  componentDidMount() {
    if (!this.is_check_key && this.token) this.callAPICheckKey()
  }

  navigator(path) {
    this.props.navigate(path)
  }

  async callAPISendForgetPasswordEmail() {
    if (!this.ValidateEmail(this.state.email)) return;

    const self = this
    self.setState({ is_sending_email: true })

    const formData = new FormData();
    formData.append('email', this.state.email)
    try {
      const response = await axios.post(
        API.POST_FORGETPW_SEND_EMAIL,
        formData,
        {
          headers: {
            // 'Authorization': `Bearer ${jwt}`,
            'Content-Type': 'multipart/form-data'
          }
        });
      // console.log(response.data);
      if (response.data.status === 'OK') {
        self.setState({
          success_message: "Instruksi atur ulang password sudah dikirim ke email anda.",
        })
      } else {
        self.setState({ is_sending_email: false, error_message: response.data.message[0] })
      }
    }
    catch (error) {
      if (error.response !== undefined) {
        // console.log(error.response);
      }
      self.setState({ is_sending_email: false })
    }
  }

  async callAPICheckKey() {
    this.is_check_key = true
    const self = this
    const formData = new FormData();
    formData.append('key', this.token)

    try {
      const response = await axios.post(
        API.POST_FORGETPW_CHECK_KEY,
        formData,
        {
          headers: {
            // 'Authorization': `Bearer ${jwt}`,
            'Content-Type': 'multipart/form-data'
          }
        });
      // console.log(response.data);
      if (response.data.status === 'OK') {
        self.setState({ email: response.data.email })
      } else {
        self.setState({ error_message: response.data.message[0] })
      }
    }
    catch (error) {
      if (error.response !== undefined) {
        // console.log(error.response);
      }
    }
  }

  async callAPISetPassword() {
    if (!this.checkPasswords()) return;

    const self = this
    self.setState({ is_setting_new_password: true })

    const formData = new FormData();
    formData.append('key', this.token)
    formData.append('new_password', this.state.new_password)
    formData.append('retype_new_password', this.state.retype_new_password)

    // console.log(this.state.new_password, this.state.retype_new_password)

    try {
      const response = await axios.post(
        API.POST_FORGETPW_SET_PW,
        formData,
        {
          headers: {
            // 'Authorization': `Bearer ${jwt}`,
            'Content-Type': 'multipart/form-data'
          }
        });
      // console.log(response.data);
      if (response.data.status === 'OK') {
        self.setState({ success_message: 'Password berhasil diatur ulang. Redirecting to login...' })
        setTimeout(self.redirectToLogin, 3000);
      } else {
        self.setState({
          error_message: response.data.message[0],
          is_setting_new_password: false,
        })
      }
    }
    catch (error) {
      if (error.response !== undefined) {
        // console.log(error.response);
      }
      self.setState({ is_setting_new_password: false })
    }
  }

  redirectToLogin() {
    window.location.href = Page.LOGIN
  }

  updateEmail(text) {
    this.setState({ email: text })
  }

  updateNewPassword(text) {
    this.setState({ new_password: text })
  }

  updateRetypeNewPassword(text) {
    this.setState({ retype_new_password: text })
  }

  checkPasswords() {
    if (this.state.new_password !== this.state.retype_new_password) {
      this.setState({ error_message: 'Password tidak sama.' })
      return false
    } else if (this.state.new_password.length < 8) {
      this.setState({ error_message: 'Password minimal 8 karakter.' })
      return false
    } else {
      this.setState({ error_message: '' })
      return true
    }
  }

  render() {
    document.title = `Verify Email | ${APP_DATA.APP_NAME}`
    return (
      <ForgetPasswordView
        token={this.token}
        is_sending_email={this.state.is_sending_email}
        is_setting_new_password={this.state.is_setting_new_password}
        error_message={this.state.error_message}
        success_message={this.state.success_message}
        email={this.state.email}
        new_password={this.state.new_password}
        retype_new_password={this.state.retype_new_password}
        updateEmail={this.updateEmail}
        updateNewPassword={this.updateNewPassword}
        updateRetypeNewPassword={this.updateRetypeNewPassword}
        callAPISendForgetPasswordEmail={this.callAPISendForgetPasswordEmail}
        callAPISetPassword={this.callAPISetPassword}
      />
    )
  }
}


export default (props) => {
  const navigate = useNavigate();
  return (
    <ForgetPasswordController {...props} navigate={navigate} />
  )
}