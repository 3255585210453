import { DUMMY_KONTEN } from '../../DummyData';
import { Page } from '../../entity/Pages';
import { useNavigate } from 'react-router-dom';
import * as APP_DATA from '../../../core/ConstantData';
import LandingView from './LandingView'
import React from 'react'
import { read_jwt } from '../../utils/JwtToken';


class LandingController extends React.Component {
  constructor(props) {
    super(props)
    this.read_jwt = read_jwt.bind(this)
    this.navigateToCareer = this.navigateToCareer.bind(this)
    this.navigator = this.navigator.bind(this)
    this.state = {

    }
  }

  navigateToCareer() {
    this.props.navigate(Page.CAREER)
  }

  navigator(path) {
    this.props.navigate(path)
  }

  render() {
    document.title = APP_DATA.APP_NAME
    return (
      <LandingView
        is_logged_in={this.read_jwt()}
        navigateToCareer={this.navigateToCareer}
        postShowcase={DUMMY_KONTEN}
        navigator={this.navigator}
      />
    )
  }
}

export default (props) => {
  const navigate = useNavigate();
  return (
    <LandingController {...props} navigate={navigate} />
  )
}