import React from 'react'


class ForgetPasswordView extends React.Component {
  render() {
    const {
      email,
      new_password,
      retype_new_password,
      updateEmail,
      error_message,
      success_message,
      token,
      is_sending_email,
      callAPISendForgetPasswordEmail,
      updateNewPassword,
      updateRetypeNewPassword,
      callAPISetPassword,
      is_setting_new_password,
    } = this.props;

    return (
      <div className='mt-20 px-10 flex flex-col items-center justify-center gap-2'>
        <p className='text-xl font-semibold text-magnet-orange-normal'>
          Lupa Password
        </p>
        {!token
          ? <div className='flex flex-col gap-2 items-center'>
            <p className='text-center'>
              Silahkan masukkan alamat email anda lalu klik submit untuk mengirim email konfirmasi.
            </p>
            <input className='text-center md:text-base text-sm shadow appearance-none border rounded-lg py-2 px-4 w-2/3 text-gray-700 focus:outline-magnet-orange-normal focus:shadow-outline'
              type='text' value={email} onChange={(e) => updateEmail(e.target.value)}>
            </input>
            {success_message && <p className='text-xs text-magnet-green-normal'>{success_message}</p>}
            {error_message && <p className='text-xs text-magnet-rose-normal'>{error_message}</p>}
            {is_sending_email
              ? <button className='rounded-xl bg-gray-300 duration-500 text-white px-8 py-2 cursor-not-allowed'>
                Submit
              </button>
              : <button className='rounded-xl bg-magnet-orange-normal hover:bg-magnet-orange-dark duration-500 text-white px-8 py-2'
                onClick={() => callAPISendForgetPasswordEmail()}>
                Submit
              </button>}
          </div>

          : <div className='flex flex-col lg:w-1/3 md:w-2/3 w-11/12 gap-2 items-start'>
            <p className=''>Email:</p>
            <input className='text-center md:text-base text-sm shadow appearance-none border rounded-lg py-2 px-4 w-full text-gray-400 focus:outline-magnet-orange-normal focus:shadow-outline bg-gray-100'
              type='text'
              value={email}
              disabled>
            </input>

            <p className=''>New Password:</p>
            <input
              id='new-password'
              className='text-center md:text-base text-sm shadow appearance-none border rounded-lg py-2 px-4 w-full text-gray-700 focus:outline-magnet-orange-normal focus:shadow-outline'
              type='password'
              value={new_password}
              onChange={(e) => updateNewPassword(e.target.value)}>
            </input>

            <p className=''>Retype New Password:</p>
            <input
              id='retype-new-password'
              className='text-center md:text-base text-sm shadow appearance-none border rounded-lg py-2 px-4 w-full text-gray-700 focus:outline-magnet-orange-normal focus:shadow-outline'
              type='password'
              value={retype_new_password}
              onChange={(e) => updateRetypeNewPassword(e.target.value)}>
            </input>

            <div className='flex flex-col items-center justify-center w-full gap-2'>
              {error_message && <p className='text-xs text-magnet-rose-normal'>{error_message}</p>}
              {success_message && <p className='text-xs text-magnet-green-normal'>{success_message}</p>}
              {is_setting_new_password
                ? <button className='rounded-xl bg-gray-300 duration-500 text-white px-8 py-2 cursor-not-allowed'>
                  Submit
                </button>
                : <button className='rounded-xl bg-magnet-orange-normal hover:bg-magnet-orange-dark duration-500 text-white px-8 py-2'
                  onClick={() => callAPISetPassword()}>
                  Submit
                </button>}
            </div>
          </div>}
      </div>
    )
  }
}

export default ForgetPasswordView