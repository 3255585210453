import React from "react";
import FaqContent from "./sections/FaqContent";

class FaqView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="flex flex-col items-center font-poppins">
        <div className="w-10/12 flex flex-col gap-16 mt-10 justify-center">
          <div className="w-full bg-white mx-auto">
            <div className="flex flex-col items-center mx-auto gap-8">
              <h2 className="text-3xl mx-auto max-w-fit md:text-5xl font-bold leading-snug bg-gradient-to-r from-magnet-rose-normal to-magnet-orange-normal bg-clip-text text-transparent">FAQ</h2>
              
              <div className="w-full text-center flex flex-col">
                <p className="text-base font-medium text-gray-700 items-end mt-auto">Kerjasama antara PT Penjaminan Infrastruktur Indonesia (Persero) dengan Mata Garuda LPDP</p>
              </div>
            </div>
          </div>
          <FaqContent />
        </div>
      </div>
    );
  }
}

export default FaqView;
