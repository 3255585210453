import mgtalks1 from "../../image/mgtalks/mgtalks-1.jpg";
import mgtalks2 from "../../image/mgtalks/mgtalks-2.jpg";
import mgtalks3 from "../../image/mgtalks/mgtalks-3.jpg";


export const MgtalksImage = {
  mgtalks1 : mgtalks1,
  mgtalks2 : mgtalks2,
  mgtalks3 : mgtalks3
};
