import * as APP_DATA from "../../../core/ConstantData";
import CallforbookView from "./CallforbookView";
import React from "react";

class CallforbookController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    document.title = APP_DATA.APP_NAME;
    return <CallforbookView />;
  }
}

export default CallforbookController;
