import React from 'react'
import { Icons } from '../../../entity/Icons'


class PageNavigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }

  }

  render() {
    const {
      active_section_id,
      moveNextSection,
      movePreviousSection,
      callAPIRegister,
      error_messages,
      is_registering,
    } = this.props

    const page_id_left_most = 1
    const page_id_right_most = 3

    return (
      <div className="flex flex-row md:text-lg text-sm font-semibold justify-between w-full">
        {active_section_id !== page_id_left_most
          ? <button className="cursor-pointer flex flex-row items-center hover:bg-gray-100 duration-500 rounded-2xl pl-2 pr-4"
            onClick={() => movePreviousSection()}>
            <Icons.ARROW_LEFT className="fill-magnet-rose-normal mr-2" />
            <p>Sebelumnya</p>
          </button>
          : <div></div>}
        {active_section_id !== page_id_right_most
          ? <button className="cursor-pointer flex flex-row bg-magnet-rose-normal hover:bg-magnet-rose-dark duration-500 text-white px-4 pt-2 md:pb-2 pb-1 rounded-2xl"
            onClick={() => moveNextSection()}>
            Lanjut
            <Icons.ARROW_RIGHT className="fill-white ml-2" />
          </button>
          : error_messages.length === 0 && !is_registering
            ? <button className="cursor-pointer flex flex-row bg-magnet-rose-normal hover:bg-magnet-rose-dark duration-500 text-white px-4 pt-2 md:pb-2 pb-1 rounded-2xl"
              onClick={() => callAPIRegister()}>
              Submit
              <Icons.CHECK className="fill-white ml-2" />
            </button>
            : is_registering
              ? <button className="cursor-not-allowed flex flex-row bg-magnet-gray text-white px-4 pt-2 md:pb-2 pb-1 rounded-2xl" disabled>
                Registering
              </button>
              : <button className="cursor-not-allowed flex flex-row bg-magnet-gray text-white px-4 pt-2 md:pb-2 pb-1 rounded-2xl" disabled>
                Submit
                <Icons.CHECK className="fill-white ml-2" />
              </button>
        }
      </div >
    );
  }
}


export default PageNavigation