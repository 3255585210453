import { useNavigate } from "react-router-dom";
import MitraRegisterView from "./MitraRegisterView";
import React from "react";

class MitraRegisterController extends React.Component {
  constructor(props) {
    super(props);
    this.navigator = this.navigator.bind(this);
    this.state = {};
  }

  navigator(path) {
    this.props.navigate(path)
  }

  render() {
    return <MitraRegisterView navigator={this.navigator} />;
  }
}

export default (props) => {
  const navigate = useNavigate();
  return <MitraRegisterController {...props} navigate={navigate} />;
};
