import React, { useState } from "react";

import { MitraImage } from "../../entity/MitraImage";
import { Icons } from "../../entity/Icons";
import { Page } from '../../entity/Pages';

import PageOne from "./page/PageOne";
import PageTwo from "./page/PageTwo";

import PageThree from "./page/PageThree";

import PageFour from "./page/PageFour";

function Tab() {
  const [tab, setTab] = useState(1);

  return (
    <>
      {" "}
      <div className="w-10/12 ">
        <div className="flex flex-col gap-8 mt-10 text-center text-2xl relative font-bold">
          Formulir Pendaftaran Mitra
        </div>
        <div
          className="absolute -z-20
         right-0 top-20"
        >
          <img className=" mt-8" alt="" src={MitraImage.vector4}></img>
        </div>
      </div>
      <div className="w-10/12 z-30">
        <div className="flex flex-row gap-8 mt-10 justify-center  font-semibold ">
          <div className="flex flex-col -mr-24 text-center z-30">
            <div className="rounded-full bg-magnet-secondary m-auto w-6 text-white mb-2">
              1
            </div>
            <div className>DATA PERUSAHAAN</div>
          </div>
          <div className="flex flex-col">
            <div>
              <hr
                className={
                  tab === 1
                    ? "w-48 h-1 mx-auto  my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"
                    : "w-48 h-1 mx-auto  my-4 bg-magnet-secondary border-0 rounded md:my-10 dark:bg-gray-700"
                }
              />
            </div>
          </div>
          <div className="flex flex-col -mx-16 text-center z-30">
            <div
              className={
                tab === 1
                  ? "rounded-full border-2 bg-white text-black border-magnet-secondary m-auto  w-7 mb-2"
                  : "rounded-full border-2 bg-magnet-secondary text-white border-magnet-secondary m-auto  w-7 mb-2"
              }
            >
              2
            </div>
            <div className>PROFIL</div>
          </div>
          <div className="flex flex-col">
            <div>
              <hr
                className={
                  tab < 3
                    ? "w-48 h-1 mx-auto  my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"
                    : "w-48 h-1 mx-auto  my-4 bg-magnet-secondary border-0 rounded md:my-10 dark:bg-gray-700"
                }
              />
            </div>
          </div>
          <div className="z-30 flex flex-col -mx-16 text-center ">
            <div
              className={
                tab < 3
                  ? "rounded-full border-2 bg-white text-black border-magnet-secondary m-auto  w-7 mb-2"
                  : "rounded-full border-2 bg-magnet-secondary text-white border-magnet-secondary m-auto  w-7 mb-2"
              }
            >
              3
            </div>
            <div className>INDUSTRI</div>
          </div>
          <div className="flex flex-col">
            <div>
              <hr
                className={
                  tab < 4
                    ? "w-48 h-1 mx-auto  my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"
                    : "w-48 h-1 mx-auto  my-4 bg-magnet-secondary border-0 rounded md:my-10 dark:bg-gray-700"
                }
              />
            </div>
          </div>
          <div className="flex flex-col -ml-16 text-center ">
            <div
              className={
                tab < 4
                  ? "rounded-full border-2 bg-white text-black border-magnet-secondary m-auto  w-7 mb-2"
                  : "rounded-full border-2 bg-magnet-secondary text-white border-magnet-secondary m-auto  w-7 mb-2"
              }
            >
              4
            </div>
            <div className>DETAIL</div>
          </div>
        </div>
      </div>
      <div className="flex mb-24 flex-col gap-6 p-16 rounded-3xl mt-10 bg-white shadow-xl  w-10/12 z-30">
        {tab === 1 && <PageOne />}
        {tab === 2 && <PageTwo />}
        {tab === 3 && <PageThree />}
        {tab === 4 && <PageFour />}
        <hr className="border-magnet-secondary mt-8" />
        <div className="flex flex-row text-lg font-semibold justify-between mt-4 ">
          <div
            onClick={tab === 1 ? () => navigator(Page.LOGINMITRA) : () => setTab(tab - 1)}
            className={tab === 1 ? "flex flex-row invisible" : "flex flex-row visible cursor-pointer"}
          >
            <div>
              <Icons.ARROW_LEFT className="fill-magnet-secondary mr-2" />
            </div>{" "}
            <div>Sebelumnya</div>
          </div>
          <div
            onClick={tab < 4 ? () => setTab(tab + 1) : () => setTab(4)}
            className="flex flex-row cursor-pointer bg-magnet-secondary text-white px-4 py-2 rounded-2xl cursor-pointer"
          >
            <div>Lanjut</div>{" "}
            <div>
              <Icons.ARROW_RIGHT className="fill-white ml-2" />
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
}

class MitraRegisterView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      navigator
    } = this.props;
    return (
      <div className="flex flex-col items-center font-poppins  ">
        <Tab navigator={navigator} />{" "}
      </div>
    );
  }
}

export default MitraRegisterView;
