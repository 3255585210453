import React from "react";
import Kalender from "./sections/Kalender";

import DetailAgenda from "./sections/DetailAgenda";

class AgendaView extends React.Component {
  render() {
    return (
      <div className="flex flex-col items-center font-poppins">
        <div className="md:w-10/12 w-11/12">
          <div className="flex flex-col gap-8 mt-10">
            <Kalender {...this.props} />
          </div>
          <DetailAgenda {...this.props} />
        </div>
      </div>
    );
  }
}

export default AgendaView;
