import React from "react";
import { MitraImage } from "../../../entity/MitraImage";

class SectionKeuntunganMitra extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-center ">
          <div className="flex items-center gap-2">
            <p className="text-2xl font-semibold ">
              Keuntungan Bermitra Dengan
            </p>
            <br />
          </div>
        </div>{" "}
        <div className="flex flex-row justify-center">
          <div className="flex items-center gap-2">
            <p className="text-2xl font-semibold text-magnet-secondarydark">
              Mata Garuda
            </p>
          </div>
        </div>
        <div className="flex flex-row gap-4 mt-4 ">
          <div className="flex flex-col w-full rounded-xl border border-gray-300 hover:border-magnet-primary duration-500 p-8">
            <div className="flex flex-row gap-2  justify-center">
              <div className="flex flex-col">
                <img className="w-16 h-16" alt="" src={MitraImage.img2}></img>
              </div>
            </div>
            <div className="flex flex-row gap-2 mt-4 justify-center">
              <p className="text-lg font-semibold hover:text-magnet-primary duration-500 cursor-pointer">
                Talenta Berkualitas
              </p>
            </div>
            <div className="flex flex-col gap-1 mt-4">
              <p className="text-gray-500  justify-center text-center">
                Anggota Mata Garuda terdiri dari ikatan alumni beasiswa LPDP
                yang teruji memiliki visi yang jelas terkait
              </p>
            </div>
            <div className="flex flex-col items-center justify-center mt-4">
              <div>
                <button className="text-sm text-magnet-secondary font-medium rounded-xl px-4 py-1 cursor-default">
                  Selengkapnya
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full rounded-xl border border-gray-300 hover:border-magnet-primary duration-500 p-8">
            <div className="flex flex-row gap-2  justify-center">
              <div className="flex flex-col">
                <img className="w-16 h-16" alt="" src={MitraImage.img3}></img>
              </div>
            </div>
            <div className="flex flex-row gap-2 mt-4 justify-center">
              <p className="text-lg font-semibold hover:text-magnet-primary duration-500 cursor-pointer">
                Berbagai Keahlian
              </p>
            </div>
            <div className="flex flex-col gap-1 mt-4">
              <p className="text-gray-500  justify-center text-center">
                Medis, Engineering dan berbagai kebutuhan talenta segala bidang
                tersedia.
              </p>
            </div>
            <div className="flex flex-col items-center justify-center mt-4">
              <div>
                <button className="text-sm text-magnet-secondary font-medium rounded-xl px-4 py-1 cursor-default">
                  Selengkapnya
                </button>
              </div>
            </div>
          </div>{" "}
          <div className="flex flex-col w-full rounded-xl border border-gray-300 hover:border-magnet-primary duration-500 p-8">
            <div className="flex flex-row gap-2  justify-center">
              <div className="flex flex-col">
                <img className="w-16 h-16" alt="" src={MitraImage.img4}></img>
              </div>
            </div>
            <div className="flex flex-row gap-2 mt-4 justify-center">
              <p className="text-lg font-semibold hover:text-magnet-primary duration-500 cursor-pointer">
                Dukungan Recruitment
              </p>
            </div>
            <div className="flex flex-col gap-1 mt-4">
              <p className="text-gray-500  justify-center text-center">
                Mata Garuda berkomitmen untuk menjadi teman agar terus tumbuh
                berkembang.
              </p>
            </div>
            <div className="flex flex-col items-center justify-center mt-4">
              <div>
                <button className="text-sm text-magnet-secondary font-medium rounded-xl px-4 py-1 cursor-default">
                  Selengkapnya
                </button>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    );
  }
}

export default SectionKeuntunganMitra;
