import React from "react";
import { Icons } from "../../../entity/Icons";
import { MitraImage } from "../../../entity/MitraImage";

class SectionArtikel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="flex flex-col gap-2 mt-8">
        <div className="flex flex-row justify-between">
          <div className="flex items-center gap-2">
            <div className="">
              <div className="flex justify-center items-center w-8 h-8 bg-magnet-secondary fill-magnet-primarylight rounded-full">
                <Icons.NEWSPAPER className="h-4" />
              </div>
            </div>
            <p className="text-2xl font-semibold text-magnet-secondarydark">
              Artikel Terkait
            </p>
          </div>
          <div className="flex flex-row items-center">
            <p className="font-semibold text-magnet-secondary">
              Pelajari Selengkapnya
            </p>
            <Icons.ANGLE_RIGHT className="fill-magnet-secondary h-4" />
          </div>
        </div>
        <div>
          <p>
            Konten terbaru seputar Informasi Kerja baik dalam maupun luar
            negeri.
          </p>
        </div>
        <div className="flex flex-row gap-6 mt-4">
          <div className="flex flex-col w-1/3 gap-3">
            <img className="" alt="" src={MitraImage.thumbnail1}></img>
            <p className="text-magnet-primary font-semibold text-xs">
              MG INSTITUTE
            </p>
            <p className="font-medium text-lg hover:text-magnet-primary duration-500 cursor-pointer">
              PPN Batu Bara: Langkah Awal dari Green Economy
            </p>
          </div>

          <div className="flex flex-col  w-1/3 gap-3">
            <img alt="" src={MitraImage.thumbnail2}></img>
            <p className="text-magnet-primary font-semibold text-xs">
              MG INSIGHT
            </p>
            <p className="font-medium text-lg hover:text-magnet-primary duration-500 cursor-pointer">
              MG Insight: OMNIBUS Law Seriesy
            </p>
          </div>

          <div className="flex flex-col  w-1/3 gap-3">
            <img alt="" src={MitraImage.thumbnail3}></img>
            <p className="text-magnet-primary font-semibold text-xs">
              PENDIDIKAN
            </p>
            <p className="font-medium text-lg hover:text-magnet-primary duration-500 cursor-pointer">
              Pendidikan di Tengah Pandemi: Memastikan Akses untuk Semua
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionArtikel;
