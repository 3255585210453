import React from "react";

import { Routes, Route } from "react-router-dom";

// import RootStore from './model/RootStore'

import HeaderController from "./view/header/HeaderController";

import HeaderMitraController from "./view/header-mitra/HeaderMitraController";
import HeaderLoginMitraController from "./view/header-login-mitra/HeaderLoginMitraController";
import HeaderRegisterMitraController from "./view/header-register-mitra/HeaderRegisterMitraController";
import { Page } from "./entity/Pages";

class HeaderProvide extends React.Component {
  constructor(props) {
    super(props);
    // const userModel = props.store[RootStore.type.USER_MODEL]
    // const profileModel = props.store[RootStore.type.PROFILE_MODEL]

    // this.UserViewModel = new UserViewModel(userModel);
    // this.CredentialViewModel = new CredentialViewModel(userModel);
    // this.ProfileViewModel = new ProfileViewModel(profileModel);
    this.state = {};
  }

  render() {
    return (
      <Routes>
        <Route path="/mitra/login" element={<HeaderLoginMitraController />} />
        <Route path="/mitra/register" element={<HeaderRegisterMitraController />} />
        <Route path="/mitra" element={<HeaderMitraController />} />

        <Route path="" element={<HeaderController />} />
        <Route path={Page.USER_PROFILE} element={<HeaderController />} />
        <Route path={Page.USER_PROFILE_EDIT} element={<HeaderController />} />
        <Route path=":id" element={<HeaderController />} />
        <Route path=":id/:slug" element={<HeaderController />} />
      </Routes>
    );
  }
}

export default HeaderProvide;
