import React from "react";

import { Icons } from "../../../entity/Icons";
import { days_indo, months_indo } from "./Utils";

class DataAgenda extends React.Component {
  render() {
    const { agenda } = this.props;
    const day_date = days_indo[agenda.date.getDay()] + ", " + agenda.day + " " + months_indo[agenda.month - 1] + " " + agenda.year
    const jam_agenda = agenda.jam_mulai_1
      ? agenda.jam_mulai_1.slice(0, 5) + "-" + agenda.jam_berakhir_1.slice(0, 5)
      : agenda.jam_berakhir_1.slice(0, 5)
    const share_text = "Ayo ikut " + agenda.judul + " yang diselenggarakan hari " + day_date + " pukul " + jam_agenda + " WIB. Jangan sampai terlewatkan."

    return (
      <div className="grid md:grid-cols-2 grid-cols-1 gap-8 md:gap-2">
        <div className="flex flex-col">
          <div className="flex flex-row ">
            <div className="flex flex-row font-bold">
              <button className="text-lg bg-gray-100 text-gray-500 font-bold rounded-xl px-4  cursor-default">
                {day_date}
              </button>
            </div>
            <div className="flex flex-grow">
              <div className="relative py-3 px-auto"></div>
            </div>
          </div>
          <div className="mt-2 p-1 flex flex-col gap-2">
            <h1 className="font-semibold">{agenda.judul}</h1>
            <div className="flex gap-2">
              <div className="flex gap-1 bg-gray-500 text-white rounded-lg px-2 py-1 text-xs">
                {agenda.jam_mulai_1 && <p>{agenda.jam_mulai_1.slice(0, 5)}</p>}
                {agenda.jam_mulai_1 && <p>-</p>}
                <p>{agenda.jam_berakhir_1.slice(0, 5)}</p>
                <p>WIB</p>
              </div>
              {agenda.kategori_atau_penyelenggara_arr.map((item, i) => {
                return <button key={i} className="text-xs select-none bg-magnet-primary text-white rounded-lg hover:bg-magnet-primarygradient duration-500 px-2 py-1 font-semibold">
                  {item}
                </button>
              })}
            </div>
            <div className="mt-2">
              {agenda.deskripsi.split('\n').map((str, i) => <p key={i} className="text-sm mb-2">{str}</p>)}
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img alt="" src={agenda.poster_url} className="h-[500px]"></img>

          <div className="flex gap-4">
            {agenda.link && <button className="flex mt-2 bg-magnet-green-normal hover:bg-magnet-green-dark px-4 py-2 text-white fill-white rounded-lg duration-500"
              onClick={() => {
                let url = agenda.link
                if (!url.match(/^https?:\/\//i)) {
                  url = 'https://' + url;
                }
                window.open(url, '_blank')
              }}>
              <p>Click to Join</p>
              {/* <p><Icons.ANGLE_RIGHT /></p> */}
            </button>}

            <button className="flex mt-2 bg-magnet-primary hover:bg-magnet-primarygradient px-4 py-2 text-white fill-white rounded-lg duration-500"
              onClick={() => {
                if (agenda.link) {
                  navigator.share({
                    title: agenda.judul + " | " + document.title,
                    text: share_text,
                    url: agenda.link
                  })
                } else {
                  navigator.share({
                    title: agenda.judul + " | " + document.title,
                    text: share_text,
                  })
                }
              }}>
              <div className="flex items-center gap-1">
                <p>Share</p>
                <Icons.SHARE className='stroke-white' />
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default DataAgenda;
