import React from 'react'
// import * as APP_DATA from '../../../ConstantData';
import { Icons } from '../../entity/Icons';
import './clean-select.css'


class NetworkSearchBarMobile extends React.Component {
  render() {
    const {
      search_name,
      updateSearchName,
      doSearchName,
      categories,
      // category,
      updateSelectedCategory,
    } = this.props;

    return (
      <div className='flex flex-col gap-2'>
        <div className='flex flex-row md:gap-6 gap-2 items-center justify-between text-sm '>
          <div className='w-8/12 bg-white rounded-full flex flex-row items-center justify-between'>
            <div className='px-4'>
              <Icons.SEARCH className='h-10 fill-magnet-primary' />
            </div>
            <input className='p-2 w-full text-sm focus:outline-none rounded-full' type='text' placeholder='Search by name here'
              value={search_name}
              onChange={(e) => updateSearchName(e.target.value)} />
            {search_name && <div className='px-4 cursor-pointer'>
              <Icons.TIMES className='h-6 w-6 fill-magnet-gray hover:fill-magnet-primary bg-gray-100 rounded-full duration-500'
                onClick={() => updateSearchName('')} />
            </div>}
          </div>
          <button className='flex items-center justify-center w-4/12 bg-magnet-yellow-normal rounded-full py-2 pl-4 text-sm text-magnet-black-normal hover:bg-magnet-yellow-light duration-500'
            onClick={() => doSearchName()}>
            <span>Search</span>
            <Icons.ANGLE_RIGHT className='fill-magnet-black-normal' />
          </button>
        </div>
        <div>
          <div className='w-8/12 px-4 py-0 h-full rounded-full bg-white overflow-hidden items-center justify-center text-center'>
            <select defaultValue={""} className='focus:outline-none text-magnet-black-normal text-left text-sm bg-transparent' name="expertise" id="expertise"
              onChange={(e) => updateSelectedCategory(e.target.value)}>
              <option value="" disabled>Filter by expertise</option>
              <option value="">SHOW ALL</option>
              {categories.sort().map(function ([expertise, count], i) {
                expertise = expertise.trim()
                return <option key={i} value={expertise}>{expertise} ({count})</option>
              })}
            </select>
          </div>
        </div>
      </div>
    )
  }
}

export default NetworkSearchBarMobile