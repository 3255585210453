import React from "react";

class PageThree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className=" gap-6">
        <div className="text-xl font-bold text-magnet-secondarydark mb-6">
          Jenis Layanan
        </div>

        <div className="flex flex-row justify-between ">
          <div className="flex flex-col w-full mr-4 ">
            <div className="flex flex-row text-lg font-semibold">
              <div>Jenis Layanan Klinik 1</div>
              <div className="text-magnet-secondary">*</div>
            </div>
            <input
              className="shadow mt-2 mb-6  appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
              id="layanan1"
              type="text"
              placeholder="Pilih jenis"
            ></input>
          </div>
          <div className="flex flex-col w-full ml-4">
            <div className="flex flex-row text-lg font-semibold">
              <div>Harga Jenis Layanan 1</div>
              <div className="text-magnet-secondary">*</div>
            </div>
            <input
              className="shadow mt-2 mb-6  appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
              id="harga1"
              type="number"
              placeholder="Pilih jenis"
            ></input>
          </div>
        </div>

        <div className="flex flex-row justify-between ">
          <div className="flex flex-col w-full mr-4 ">
            <div className="flex flex-row text-lg font-semibold">
              <div>Jenis Layanan Klinik 2</div>
              <div className="text-magnet-secondary">*</div>
            </div>
            <input
              className="shadow mt-2 mb-6  appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
              id="layanan2"
              type="text"
              placeholder="Pilih jenis"
            ></input>
          </div>
          <div className="flex flex-col w-full ml-4">
            <div className="flex flex-row text-lg font-semibold">
              <div>Harga Jenis Layanan 2</div>
              <div className="text-magnet-secondary">*</div>
            </div>
            <input
              className="shadow mt-2 mb-6  appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
              id="harga2"
              type="number"
              placeholder="Pilih jenis"
            ></input>
          </div>
        </div>

        <div className="flex flex-row justify-between ">
          <div className="flex flex-col w-full mr-4 ">
            <div className="flex flex-row text-lg font-semibold">
              <div>Jenis Layanan Klinik 3</div>
              <div className="text-magnet-secondary">*</div>
            </div>
            <input
              className="shadow mt-2 mb-6  appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
              id="layanan3"
              type="text"
              placeholder="Pilih jenis"
            ></input>
          </div>
          <div className="flex flex-col w-full ml-4">
            <div className="flex flex-row text-lg font-semibold">
              <div>Harga Jenis Layanan 3</div>
              <div className="text-magnet-secondary">*</div>
            </div>
            <input
              className="shadow mt-2 mb-6  appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
              id="harga3"
              type="number"
              placeholder="Pilih jenis"
            ></input>
          </div>
        </div>
      </div>
    );
  }
}

export default PageThree;
