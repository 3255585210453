import React from 'react'
// import * as APP_DATA from '../../../ConstantData';
// import { Icons } from '../../../entity/Icons';
import { Page } from '../../../entity/Pages';
import wg_img from '../../../../image/wroking-group.png'


class MulaiBergabung extends React.Component {
  render() {
    const { is_logged_in, navigator } = this.props
    return (
      <div className="md:w-[1288px] md:h-[534px] md:relative flex md:bg-transparent bg-gradient-to-r from-magnet-rose-normal to-magnet-orange-normal rounded-2xl py-20 px-4 md:py-0 md:px-0">
        <div className="md:flex hidden md:w-[1288px] md:h-[534px] absolute rounded-2xl">
          <img alt='' src={wg_img} className='md:h-[534px] rounded-2xl'></img>
        </div>

        <div className="md:w-[520px] md:h-full md:ml-20 md:absolute flex-col justify-center items-end gap-8 inline-flex md:text-left text-center">
          <div className="flex-col justify-start items-start gap-6 flex">
            {is_logged_in
              ? <div className="md:w-[520px] text-white text-3xl md:text-4xl font-extrabold">Selamat Bergabung di Mata Garuda Network</div>
              : <div className="md:w-[520px] text-white text-3xl md:text-4xl font-extrabold">Daftar di Mata Garuda Network Sekarang</div>
            }
            <div className="self-stretch text-white md:text-lg font-medium leading-7">
              Bergabung bersama dengan puluhan ribu alumni yang sudah mendapatkan manfaat melalui program Mata Garuda Network. Daftarkan diri Kamu sekarang!
            </div>
          </div>
          {is_logged_in
            ? <div className="self-stretch md:p-6 p-4 bg-magnet-yellow-normal hover:bg-magnet-yellow-light rounded-3xl shadow justify-center items-center inline-flex cursor-pointer duration-500"
              onClick={() => navigator(Page.NETWORK)}>
              Menuju Network!
            </div>
            : <div className="self-stretch md:p-6 p-4 bg-magnet-yellow-normal hover:bg-magnet-yellow-light rounded-3xl shadow justify-center items-center inline-flex cursor-pointer duration-500"
              onClick={() => navigator(Page.REGISTER)}>
              Bergabung Sekarang!
            </div>
          }
        </div>
      </div>
    )
  }
}

export default MulaiBergabung