import aboutusbanner from "../../image/aboutus/aboutus-banner.png";
import maps from "../../image/aboutus/maps.png";
import bannervideo from "../../image/aboutus/video.png";
import board1 from "../../image/aboutus/board1.jpg";
import board2 from "../../image/aboutus/board2.jpg";
import board3 from "../../image/aboutus/board3.jpg";
import board4 from "../../image/aboutus/board4.jpg";
import board5 from "../../image/aboutus/board5.jpg";
import board6 from "../../image/aboutus/board6.jpg";
import board7 from "../../image/aboutus/board7.jpg";
import board8 from "../../image/aboutus/board8.jpg";
import team1 from "../../image/aboutus/team1.jpg";
import team2 from "../../image/aboutus/team2.jpg";
import team3 from "../../image/aboutus/team3.jpg";
import team4 from "../../image/aboutus/team4.jpg";
import team5 from "../../image/aboutus/team5.jpg";
import team6 from "../../image/aboutus/team6.jpg";

export const AboutusImage = {
  aboutusbanner : aboutusbanner,
  maps : maps,
  bannervideo : bannervideo,
  board1 : board1,
  board2 : board2,
  board3 : board3,
  board4 : board4,
  board5 : board5,
  board6 : board6,
  board7 : board7,
  board8 : board8,
  team1 : team1,
  team2 : team2,
  team3 : team3,
  team4 : team4,
  team5 : team5,
  team6 : team6,
};
