function addHttps(link) {
  if (link.includes("http")) {
    return link
  } else {
    return 'https://' + link
  }
}

function isInstagramLink(text) {
  return text.includes("instagram")
}

function isLinkedinLink(text) {
  return text.includes("linkedin")
}

function isWhatsappLink(text) {
  return text.includes("wa.me")
}

function isTwitterLink(text) {
  return text.includes("twitter")
}

function createInstagramLink(username) {
  return 'https://instagram.com/' + username
}

function createLinkedinLink(username) {
  return 'https://linkedin.com/in/' + username
}

function createWhatsappLink(phone) {
  return 'https://wa.me/' + phone
}

function createTwitterLink(username) {
  return 'https://twitter.com/' + username
}

export function FixSocialMediaUrls(profile_data) {
  // Check social media URL
  if (isWhatsappLink(profile_data.Whatsapp)) {
    profile_data.Whatsapp = addHttps(profile_data.Whatsapp)
  } else if (profile_data.Whatsapp && !isWhatsappLink(profile_data.Whatsapp)) {
    profile_data.Whatsapp = createWhatsappLink(profile_data.Whatsapp)
  }

  if (isTwitterLink(profile_data.Twitter)) {
    profile_data.Twitter = addHttps(profile_data.Twitter)
  } else if (profile_data.Twitter && !isTwitterLink(profile_data.Twitter)) {
    profile_data.Twitter = createTwitterLink(profile_data.Twitter)
  }

  if (isInstagramLink(profile_data.Instagram)) {
    profile_data.Instagram = addHttps(profile_data.Instagram)
  } else if (profile_data.Instagram && !isInstagramLink(profile_data.Instagram)) {
    profile_data.Instagram = createInstagramLink(profile_data.Instagram)
  }

  if (isLinkedinLink(profile_data.Linkedin)) {
    profile_data.Linkedin = addHttps(profile_data.Linkedin)
  } else if (profile_data.Linkedin && !isLinkedinLink(profile_data.Linkedin)) {
    profile_data.Linkedin = createLinkedinLink(profile_data.Linkedin)
  }
  return profile_data
}