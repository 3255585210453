import { makeAutoObservable } from 'mobx'
import { DEFAULT_PROFILE } from '../../ConstantData'


class UserProfileModel {
    constructor() {
        makeAutoObservable(this)
    }

    Email = ''
    Password = ''
    RetypePassword = ''
    Fullname = ''
    City = ''
    Country = ''
    University = ''
    MajorStudy = ''
    Picture = DEFAULT_PROFILE
    CV = null
    PK = ''
    Expertise = ''
    Description = ''
    Reason = ''
    Whatsapp = ''
    Instagram = ''
    Linkedin = ''
    Twitter = ''

    read() {
        return {
            Email: this.Email,
            Password: this.Password,
            RetypePassword: this.RetypePassword,

            Fullname: this.Fullname,
            City: this.City,
            Country: this.Country,
            University: this.University,
            MajorStudy: this.MajorStudy,
            Picture: this.Picture,
            CV: this.CV,
            PK: this.PK,
            Expertise: this.Expertise,
            Description: this.Description,
            Reason: this.Reason,
            Whatsapp: this.Whatsapp,
            Instagram: this.Instagram,
            Twitter: this.Twitter,
            Linkedin: this.Linkedin,
        }
    }

    async read_profile_picture() {
        const response = await fetch(this.Picture);
        const blob = await response.blob();
        const file = new File([blob], 'image.jpg', { type: blob.type });
        return file
    }

    read_formdata_login() {
        const formData = new FormData();
        formData.append('email', this.Email)
        formData.append('password', this.Password)
        return formData
    }

    async read_as_formdata() {
        const urlToObject = async (url, filename) => {
            const response = await fetch(url, { mode: 'no-cors' });
            const blob = await response.blob();
            const file = new File([blob], filename, { type: blob.type });
            return file
        }
        const pictureFile = await urlToObject(this.Picture, 'image.jpg').then(function (file) {
            return file
        })
        const cvFile = await urlToObject(this.CV, 'file.pdf').then(function (file) {
            return file
        })

        const formData = new FormData();

        formData.append('email', this.Email)
        formData.append('password', this.Password)
        formData.append('retypepassword', this.RetypePassword)

        formData.append('fullname', this.Fullname)
        formData.append('city', this.City)
        formData.append('country', this.Country)
        formData.append('university', this.University)
        formData.append('majorstudy', this.MajorStudy)
        formData.append('picture', pictureFile)
        formData.append('cv', cvFile)
        formData.append('pk', this.PK)
        formData.append('expertise', this.Expertise)
        formData.append('description', this.Description)
        formData.append('reason', this.Reason)
        formData.append('whatsapp', this.Whatsapp)
        formData.append('instagram', this.Instagram)
        formData.append('linkedin', this.Linkedin)
        formData.append('twitter', this.Twitter)

        return formData
    }

    read_null() {
        const null_obj = []

        if (!this.Email) null_obj.push('Email')
        if (!this.Password) null_obj.push('Password')
        if (!this.RetypePassword) null_obj.push('Retype password')
        if (!this.Fullname) null_obj.push('Nama Lengkap')
        if (!this.City) null_obj.push('Kota Domisili')
        if (!this.Country) null_obj.push('Negara Domisili')
        if (!this.University) null_obj.push('Universitas')
        if (!this.MajorStudy) null_obj.push('Program Studi')
        if (!this.Picture || this.Picture === DEFAULT_PROFILE) null_obj.push('Foto Profil')
        // if (!this.CV) null_obj.push('CV') // optional
        if (!this.PK) null_obj.push('PK')
        if (!this.Expertise) null_obj.push('Kategori Keahlian')
        if (!this.Description) null_obj.push('Deskripsi Diri')
        if (!this.Reason) null_obj.push('Alasan Berjejaring')

        return null_obj
    }

    update(key_value) {
        // keep using if to handle multi key updates
        if (key_value.hasOwnProperty('Email')) { this.Email = key_value.Email }
        if (key_value.hasOwnProperty('email')) { this.Email = key_value.email }

        if (key_value.hasOwnProperty('Password')) { this.Password = key_value.Password }
        if (key_value.hasOwnProperty('password')) { this.Password = key_value.password }

        if (key_value.hasOwnProperty('RetypePassword')) { this.RetypePassword = key_value.RetypePassword }
        if (key_value.hasOwnProperty('retypepassword')) { this.RetypePassword = key_value.retypepassword }

        if (key_value.hasOwnProperty('Fullname')) { this.Fullname = key_value.Fullname }
        if (key_value.hasOwnProperty('fullname')) { this.Fullname = key_value.fullname }

        if (key_value.hasOwnProperty('City')) { this.City = key_value.City }
        if (key_value.hasOwnProperty('city')) { this.City = key_value.city }

        if (key_value.hasOwnProperty('Country')) { this.Country = key_value.Country }
        if (key_value.hasOwnProperty('country')) { this.Country = key_value.country }

        if (key_value.hasOwnProperty('University')) { this.University = key_value.University }
        if (key_value.hasOwnProperty('university')) { this.University = key_value.university }

        if (key_value.hasOwnProperty('MajorStudy')) { this.MajorStudy = key_value.MajorStudy }
        if (key_value.hasOwnProperty('majorstudy')) { this.MajorStudy = key_value.majorstudy }

        if (key_value.hasOwnProperty('Picture')) { this.Picture = key_value.Picture }
        if (key_value.hasOwnProperty('picture')) { this.Picture = key_value.picture }

        if (key_value.hasOwnProperty('CV')) { this.CV = key_value.CV }
        if (key_value.hasOwnProperty('cv')) { this.CV = key_value.cv }

        if (key_value.hasOwnProperty('PK')) { this.PK = key_value.PK }
        if (key_value.hasOwnProperty('pk')) { this.PK = key_value.pk }

        if (key_value.hasOwnProperty('Expertise')) { this.Expertise = key_value.Expertise }
        if (key_value.hasOwnProperty('expertise')) { this.Expertise = key_value.expertise }

        if (key_value.hasOwnProperty('Description')) { this.Description = key_value.Description }
        if (key_value.hasOwnProperty('description')) { this.Description = key_value.description }

        if (key_value.hasOwnProperty('Reason')) { this.Reason = key_value.Reason }
        if (key_value.hasOwnProperty('reason')) { this.Reason = key_value.reason }

        if (key_value.hasOwnProperty('Whatsapp')) { this.Whatsapp = key_value.Whatsapp }
        if (key_value.hasOwnProperty('whatsapp')) { if (key_value.whatsapp) this.Whatsapp = key_value.whatsapp }

        if (key_value.hasOwnProperty('Instagram')) { this.Instagram = key_value.Instagram }
        if (key_value.hasOwnProperty('instagram')) { if (key_value.instagram) this.Instagram = key_value.instagram }

        if (key_value.hasOwnProperty('Linkedin')) { this.Linkedin = key_value.Linkedin }
        if (key_value.hasOwnProperty('linkedin')) { if (key_value.linkedin) this.Linkedin = key_value.linkedin }

        if (key_value.hasOwnProperty('Twitter')) { this.Twitter = key_value.Twitter }
        if (key_value.hasOwnProperty('twitter')) { if (key_value.twitter) this.Twitter = key_value.twitter }
    }

    dispose() {
        this.Email = ''
        this.Password = ''
        this.RetypePassword = ''
        this.Fullname = ''
        this.City = ''
        this.Country = ''
        this.University = ''
        this.MajorStudy = ''
        this.Picture = null
        this.CV = null
        this.PK = ''
        this.Expertise = ''
        this.Description = ''
        this.Reason = ''
        this.Whatsapp = ''
        this.Instagram = ''
        this.Twitter = ''
        this.Linkedin = ''
    }
}

export default UserProfileModel;