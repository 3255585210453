import React from "react";
import DetailMgtalk from "./sections/DetailMgtalk";
import { TopFunction } from '../../utils/TopFunction';
import { Page } from "../../entity/Pages";

class MgtalkDetailView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      detail_mgtalk,
      navigate
    } = this.props

    return (
      <div className="flex flex-col max-xl:mt-10 items-center font-poppins">
        <div className="w-10/12">
          <div className="flex flex-col gap-8 mt-10">
            <div className="rounded-2xl bg-neutral-200 p-4 md:p-5 w-full text-neutral-main">
              <div className="flex flex-row gap-x-4 text-sm">
                <span className="text-magnet-rose-normal cursor-pointer" onClick={() => {TopFunction(); navigate(Page.MGTALKS)}}>MG Talks</span>
                <span className="text-neutral-600">/</span>
                <span className="text-neutral-600">{detail_mgtalk.title}</span>
              </div>
            </div>
            <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700" />
            <div className="w-full flex flex-rowmd:gap-x-4">
              <DetailMgtalk detail_mgtalk={detail_mgtalk}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MgtalkDetailView;
