import CompanyA from '../../image/company/image 25.png'
import CompanyB from '../../image/company/image 26.png'
import CompanyC from '../../image/company/image 27.png'
import CompanyD from '../../image/company/image 41.png'
import CompanyE from '../../image/company/image 42.png'
import CompanyF from '../../image/company/image 43.png'
import CompanyG from '../../image/company/image 44.png'
import CompanyH from '../../image/company/image 45.png'
import CompanyI from '../../image/company/image 46.png'
import CompanyJ from '../../image/company/image 49.png'
import CompanyK from '../../image/company/image 51.png'
import CompanyL from '../../image/company/image 52.png'
import CompanyLPDP from '../../image/company/Logo_resmi_LPDP.png'

export const CompanyLogo = {
  CompanyA: CompanyA,
  CompanyB: CompanyB,
  CompanyC: CompanyC,
  CompanyD: CompanyD,
  CompanyE: CompanyE,
  CompanyF: CompanyLPDP,
  CompanyG: CompanyG,
  CompanyH: CompanyH,
  CompanyI: CompanyI,
  CompanyJ: CompanyJ,
  CompanyK: CompanyK,
  CompanyL: CompanyL,
}