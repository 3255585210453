import React from 'react'
import { useNavigate } from 'react-router-dom'
import FooterView from './FooterView'
// import * as APP_DATA from '../../../core/ConstantData';


class FooterController extends React.Component {
  constructor(props) {
    super(props)
    this.navigator = this.navigator.bind(this)
    this.state = {

    }

  }

  navigator(path) {
    this.props.navigate(path)
  }

  render() {
    return (
      <FooterView navigator={this.navigator} />
    )
  }
}

export default (props) => {
  const navigate = useNavigate();
  return (
    <FooterController {...props} navigate={navigate} />
  )
}