import React from "react";
import * as APP_DATA from "../../../core/ConstantData";
import { Page } from "../../entity/Pages";

class HeaderMitraView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { navigator } = this.props;

    return (
      <React.Fragment>
        <div className="flex flex-row justify-between px-8 py-4 font-poppins">
          <div className="ml-20 flex flex-row items-center gap-4">
            <img
              alt=""
              src={APP_DATA.LOGO_MAGNET}
              className="cursor-pointer"
              onClick={() => navigator(Page.MITRA)}
            ></img>
          </div>
          <div className=" flex flex-row items-center gap-4">
            <div className="flex gap-2 cursor-pointer hover:bg-gray-100 duration-500 p-2 rounded-lg fill-black">
              <p className="font-medium">Mengapa Kami?</p>
            </div>
            <div className="flex gap-2 cursor-pointer hover:bg-gray-100 duration-500 p-2 rounded-lg fill-black">
              <p className="font-medium">Tentang Kami</p>
            </div>
            <div className="flex gap-2 cursor-pointer hover:bg-gray-100 duration-500 p-2 rounded-lg fill-black">
              <p className="font-medium">Pusat Bantuan</p>
            </div>
          </div>

          <div
            id="right-menu"
            className="mr-20 flex flex-row items-center gap-6"
          >
            <div>
              <button onClick={() => navigator(Page.REGISTERMITRA)} className="px-4 py-2 rounded-xl font-medium border-2 border-magnet-secondary text-magnet-secondary hover:bg-magnet-secondary hover:text-white duration-500">
                Daftar
              </button>
            </div>
            <div></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HeaderMitraView;
