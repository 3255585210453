import { API } from '../../entity/API';
import { delete_email, delete_jwt, delete_username, read_jwt, read_username, store_username } from '../../utils/JwtToken';
import { LPDPUserOnlyPage, Page, RedirectToNetworkOnSignedIn } from '../../entity/Pages';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import HeaderView from './HeaderView'
import React from 'react'


class HeaderController extends React.Component {
  constructor(props) {
    super(props)
    this.store_username = store_username.bind(this)
    this.read_username = read_username.bind(this)
    this.read_jwt = read_jwt.bind(this)
    this.delete_username = delete_username.bind(this)
    this.delete_jwt = delete_jwt.bind(this)
    this.delete_email = delete_email.bind(this)

    this.navigator = this.navigator.bind(this)
    this.handleShownavbar = this.handleShownavbar.bind(this)
    this.handleShowsubmenu = this.handleShowsubmenu.bind(this)
    this.updateIsMobile = this.updateIsMobile.bind(this)
    this.logout = this.logout.bind(this)
    this.handleJwtError = this.handleJwtError.bind(this)

    this.is_call_jwt_api = false

    this.state = {
      is_show: false,
      is_submenu_show: false,
      is_logged_in: false,
      is_mobile: this.isMobile(),
    }

  }

  componentDidMount() {
    var self = this

    // to keep update this.state.is_mobile on window resize even without any state update
    window.addEventListener('resize', function (event) {
      self.updateIsMobile()
    }, true);

    if (this.read_jwt() && !this.is_call_jwt_api) {
      this.is_call_jwt_api = true
      this.callAPIJwt()
    } else if (!this.read_jwt()) {
      this.handleJwtError()
    }
  }

  navigator(path) {
    this.props.navigate(path)
  }

  handleShownavbar() {
    this.setState({ is_show: !this.state.is_show })
  }

  handleShowsubmenu(e) {
    let is_will_show = !this.state.is_submenu_show

    if (is_will_show) {
      e.target.classList.remove('bg-magnet-primary')
      e.target.classList.add('bg-magnet-primarygradient')
    } else {
      e.target.classList.add('bg-magnet-primary')
      e.target.classList.remove('bg-magnet-primarygradient')
    }
    this.setState({ is_submenu_show: is_will_show })
  }

  updateIsMobile() {
    // 768 is mobile's window width pixel size
    this.setState({ is_mobile: this.isMobile() })
  }

  isMobile() {
    return window.innerWidth < 768
  }

  async callAPIJwt() {
    const self = this
    try {
      const response = await axios.get(
        API.GET_JWT,
        {
          headers: {
            'Authorization': `Bearer ${self.read_jwt()}`,
          }
        });
      // console.log(response.data)
      if (response.data.status === 'OK') {
        self.setState({ is_logged_in: true })
        self.store_username(response.data.payload.username)
        if (RedirectToNetworkOnSignedIn.includes(window.location.pathname)) {
          window.location.href = Page.NETWORK
        }
      } else {
        self.handleJwtError()
      }
    }
    catch (error) {
      self.handleJwtError()
    }
  }

  handleJwtError() {
    this.setState({ is_logged_in: false })

    this.delete_jwt()
    this.delete_username()
    this.delete_email()

    this.redirectToLogin()
  }

  redirectToLogin() {
    if (LPDPUserOnlyPage.includes(window.location.pathname)
      || window.location.pathname.includes('profile')) {
      window.location.href = Page.LOGIN
    }
  }

  logout() {
    this.delete_jwt()
    this.delete_username()
    this.delete_email()
    window.location.href = Page.HOME
  }

  render() {
    return (
      <HeaderView
        handleShowNavbar={this.handleShownavbar}
        handleShowSubmenu={this.handleShowsubmenu}
        navigator={this.navigator}
        is_show={this.state.is_show}
        is_submenu_show={this.state.is_submenu_show}
        is_mobile={this.state.is_mobile}
        logout={this.logout}
        is_logged_in={this.state.is_logged_in}
        username={this.read_username()}
      />
    )
  }
}

export default (props) => {
  const navigate = useNavigate();
  return (
    <HeaderController {...props} navigate={navigate} />
  )
}