import React from "react";
import { Icons } from "../../../entity/Icons";
import DataAgenda from "../kalender/DataAgenda";


class DetailAgenda extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { all_agenda, clicked_date, clicked_event_index } = this.props;

    return (
      <React.Fragment>
        {all_agenda && all_agenda[clicked_date] && <div className="flex flex-col gap-2 mt-10">
          <div className="flex flex-row justify-between">
            <div className="flex items-center gap-2">
              <div className="">
                <div className="flex justify-center items-center w-8 h-8 bg-magnet-rose-normal fill-magnet-rose-light rounded-full">
                  <Icons.CALENDAR_ALT className="h-4" />
                </div>
              </div>
              <p className="text-2xl font-semibold text-magnet-dark">
                Agenda Mata Garuda
              </p>
            </div>
          </div>

          {all_agenda && all_agenda[clicked_date] && all_agenda[clicked_date].map((agenda, i) => {
            if (clicked_event_index === i) {
              return (
                <DataAgenda key={i} agenda={agenda} />
              );
            } else {
              return <></>
            }
          })}
        </div>}
      </React.Fragment>
    );
  }
}

export default DetailAgenda;
