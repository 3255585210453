import React from "react";

import { Routes, Route } from "react-router-dom";

// import RootStore from './model/RootStore'

import FooterController from "./view/footer/FooterController";

import FooterMitraController from "./view/footer-mitra/FooterMitraController";
import FooterLoginMitraController from "./view/footer-login-mitra/FooterLoginMitraController";

class FooterProvide extends React.Component {
  constructor(props) {
    super(props);
    // const userModel = props.store[RootStore.type.USER_MODEL]
    // const profileModel = props.store[RootStore.type.PROFILE_MODEL]

    // this.UserViewModel = new UserViewModel(userModel);
    // this.CredentialViewModel = new CredentialViewModel(userModel);
    // this.ProfileViewModel = new ProfileViewModel(profileModel);
    this.state = {};
  }

  render() {
    return (
      <Routes>
        <Route path="/mitra/login" element={<FooterLoginMitraController />} />
        <Route path="/mitra/register" element={<FooterMitraController />} />
        <Route path="/mitra" element={<FooterMitraController />} />
        <Route path=":id/*" element={<FooterController />} />

        <Route path="" element={<FooterController />} />
      </Routes>
    );
  }
}

export default FooterProvide;
