import React from "react";

class PageOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className=" gap-6">
        <div className="text-xl font-bold text-magnet-secondarydark">
          Data Perusahaan
        </div>
        <div className="flex mt-4 flex-row text-lg font-semibold">
          <div>Data Perusahaan</div>
          <div className="text-magnet-secondary">*</div>
        </div>
        <input
          className="shadow mt-2 mb-6 appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
          id="perusahaan"
          type="text"
          placeholder="Nama Perusahaan"
        ></input>
        <div className="flex flex-row text-lg font-semibold">
          <div>Deskripsi</div>
        </div>
        <input
          className="shadow mt-2 mb-6  appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
          id="deskripsi"
          type="text"
          placeholder="Tulis deskripsi"
        ></input>
        <div className="flex flex-row text-lg font-semibold">
          <div>Alamat</div>
          <div className="text-magnet-secondary">*</div>
        </div>
        <input
          className="shadow mt-2 mb-6  appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
          id="alamat"
          type="text"
          placeholder="Tulis alamat"
        ></input>
        <div className="flex flex-row text-lg font-semibold">
          <div>Kontak Perusahaan</div>
          <div className="text-magnet-secondary">*</div>
        </div>
        <input
          className="shadow mt-2 mb-6  appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
          id="kontak"
          type="text"
          placeholder="Tulis No. Telepon"
        ></input>
      </div>
    );
  }
}

export default PageOne;
