import * as APP_DATA from "../../../core/ConstantData";
import { DUMMYCOURSE } from "../../DummyData";
import CourseView from "./CourseView";
import React from "react";

class CourseController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    document.title = APP_DATA.APP_NAME;
    return <CourseView postCourse={DUMMYCOURSE} />;
  }
}

export default CourseController;
