import React from "react";
import { Icons } from "../../../entity/Icons";

class FilterMgtalkList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      search_name,
      updateSearchName,
      doSearchName } 
    = this.props;

    return (
      <div className="flex flex-col ">
        <div className="bg-gray-100 rounded-xl flex flex-row p-8 gap-6 items-center justify-between">
          <div className="w-11/12 bg-white rounded-xl flex flex-row items-center justify-between">
            <div className="px-4">
              <Icons.SEARCH className="h-10 fill-magnet-rose-normal" />
            </div>
            <input
              className="p-4 w-full appearance-none border-none"
              type="text"
              placeholder="Cari Judul MG Talks..."
              value={search_name}
              onChange={(e) => updateSearchName(e.target.value)} />
            <div className="px-4 cursor-pointer">
              {search_name && <div className='px-4 cursor-pointer z-[10]'>
                <Icons.TIMES className='h-6 w-6 fill-magnet-gray hover:fill-magnet-rose-normal bg-gray-100 rounded-full duration-500'
                  onClick={() => updateSearchName('')} />
              </div>}
            </div>
          </div>
          <button
            className="w-1/12 bg-magnet-rose-normal rounded-xl p-4 text-magnet-primarylight border border-magnet-rose-normal hover:bg-white hover:text-magnet-rose-normal duration-500"
            onClick={() => doSearchName()}>
            Cari
          </button>
        </div>
      </div>
    );
  }
}

export default FilterMgtalkList;
