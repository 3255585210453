import React from "react";
import { MitraImage } from "../../../entity/MitraImage";

class SectionStepMitra extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-center ">
          <div className="flex items-center gap-2">
            <p className="text-2xl font-semibold ">Langkah-Langah Bergabung</p>
            <br />
          </div>
        </div>{" "}
        <div className="flex flex-row justify-center">
          <div className="flex items-center gap-2">
            <p className="text-2xl font-semibold ">Menjadi Mitra</p>
          </div>
        </div>
        <div className="flex flex-row gap-4 mt-4 ">
          <div className="flex flex-col w-full rounded-xl hover:border-magnet-primary duration-500 p-8">
            <div className="flex flex-row gap-2 mt-4 justify-center">
              <p className="text-lg text-magnet-secondarydark font-semibold hover:text-magnet-primary duration-500 cursor-pointer">
                Pendaftaran
              </p>
            </div>{" "}
            <div className="flex flex-row gap-2  justify-center mt-4">
              <div className="flex flex-col">
                <img className="w-16 h-16" alt="" src={MitraImage.ico1}></img>
              </div>
            </div>
            <div className="flex flex-row gap-2 mt-4 justify-center">
              <p className="text-sm font-semibold hover:text-magnet-primary duration-500 cursor-pointer">
                Mitra Mendaftar
              </p>
            </div>
            <div className="flex flex-col gap-1 mt-4">
              <p className="text-gray-500  justify-center text-center">
                Mitra mengisi formulir kerjasama, dan tim MataGaruda akan
                mengirimkan info lengkap terkait model kerjasama.
              </p>
            </div>
          </div>
          <div className="flex flex-col w-full rounded-xl hover:border-magnet-primary duration-500 p-8">
            <div className="flex flex-row gap-2 mt-4 justify-center">
              <p className="text-lg text-magnet-secondarydark font-semibold hover:text-magnet-primary duration-500 cursor-pointer">
                Persiapan
              </p>
            </div>{" "}
            <div className="flex flex-row gap-2 mt-4 justify-center">
              <div className="flex flex-col">
                <img className="w-16 h-16" alt="" src={MitraImage.ico2}></img>
              </div>
            </div>
            <div className="flex flex-row gap-2 mt-4 justify-center">
              <p className="text-sm text-center font-semibold hover:text-magnet-primary duration-500 cursor-pointer">
                Penawaran Kerjasama, Demo Sistem dan Layanan
              </p>
            </div>
            <div className="flex flex-col gap-1 mt-4">
              <p className="text-gray-500 text-sm justify-center text-center">
                Kami mempresentasikan penawaran yang sesuai dengan kebutuhan
                Mitra. Ditambah sesi demo sistem dan layanan yang bisa
                dimanfaatkan oleh Mitra. Dilanjutkan dengan persetujuan kerja
                sama.
              </p>
            </div>
          </div>{" "}
          <div className="flex flex-col w-full rounded-xl  hover:border-magnet-primary duration-500 p-8">
            <div className="flex flex-row gap-2 mt-4 justify-center">
              <p className="text-lg text-magnet-secondarydark font-semibold hover:text-magnet-primary duration-500 cursor-pointer">
                Implementasi
              </p>
            </div>{" "}
            <div className="flex flex-row gap-2 mt-4 justify-center">
              <div className="flex flex-col">
                <img className="w-16 h-16" alt="" src={MitraImage.ico3}></img>
              </div>
            </div>
            <div className="flex flex-row gap-2 mt-4 justify-center">
              <p className="text-sm text-center font-semibold hover:text-magnet-primary duration-500 cursor-pointer">
                Aktivasi dan Pelatihan Sistem, Monitoring Layanan
              </p>
            </div>
            <div className="flex flex-col gap-1 mt-4">
              <p className="text-gray-500 text-sm justify-center text-center">
                Selamat! Mitra sudah bergabung! MataGaruda akan membantu
                aktivasi sistem, pelatihan penggunaan sistem hingga penemuan
                kandidat yang paling tepat.
              </p>
            </div>
          </div>{" "}
        </div>
      </div>
    );
  }
}

export default SectionStepMitra;
