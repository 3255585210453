// import * as APP_DATA from '../../../ConstantData';
import { Icons } from "../../../entity/Icons";
import React, { useState } from "react";

function Accordion() {
  // Declare a new state variable, which we'll call "count"
  const [count, setCount] = useState(0);

  return (
    <>
      <details
        className={
          count === 1
            ? "py-8 px-8 w-full bg-magnet-secondarylight cursor-pointer mb-3 rounded-xl"
            : "py-8 px-8  w-full bg-white cursor-pointer mb-3"
        }
      >
        <summary
          onClick={count > 0 ? () => setCount(1) : () => setCount(count + 1)}
          className={
            count === 1
              ? "flex flex-row w-full font-bold rounded-lg bg-magnet-secondarylight text-magnet-secondarydark flex justify-between px-4 py-3 "
              : "flex flex-row w-full font-bold rounded-lg bg-white text-dark flex justify-between px-4 py-3 "
          }
        >
          <div className="flex flex-grow">
            <p>Apakah bergabung menjadi mitra Mata Garuda dipungut biaya? </p>
            <div className="flex flex-grow"></div>
            <div className=" flex items-left">
              {count === 1 ? (
                <Icons.ANGLE_UP className="fill-magnet-secondary" />
              ) : (
                <Icons.ANGLE_DOWN className="fill-magnet-secondary" />
              )}{" "}
            </div>
          </div>
        </summary>
        <p className="px-4 py-3 text-sm pr-16">
          Bergabung menjadi mitra MataGaruda sama sekali tidak dipungut biaya
          alias gratis. Mitra bisa mengajukan kerja sama tanpa membayar kepada
          MataGaruda.
        </p>
      </details>
      <details
        className={
          count === 2
            ? "py-8 px-8 w-full bg-magnet-secondarylight cursor-pointer mb-3 rounded-xl"
            : "py-8 px-8  w-full bg-white cursor-pointer mb-3"
        }
      >
        <summary
          onClick={count > 0 ? () => setCount(2) : () => setCount(count + 2)}
          className={
            count === 2
              ? "flex flex-row w-full font-bold rounded-lg bg-magnet-secondarylight text-magnet-secondarydark flex justify-between px-4 py-3  "
              : "flex flex-row w-full font-bold rounded-lg bg-white text-dark flex justify-between px-4 py-3 "
          }
        >
          <div className="flex flex-grow">
            <p>Bagaimana cara bergabung menjadi Mitra Mata Garuda? </p>
            <div className="flex flex-grow"></div>
            <div className=" flex items-left">
              {count === 2 ? (
                <Icons.ANGLE_UP className="fill-magnet-secondary" />
              ) : (
                <Icons.ANGLE_DOWN className="fill-magnet-secondary" />
              )}{" "}
            </div>
          </div>
        </summary>
        <p className="px-4 py-3 text-sm pr-16">...</p>
      </details>{" "}
      <details
        className={
          count === 3
            ? "py-8 px-8 w-full bg-magnet-secondarylight cursor-pointer mb-3 rounded-xl"
            : "py-8 px-8  w-full bg-white cursor-pointer mb-3"
        }
      >
        <summary
          onClick={count > 0 ? () => setCount(3) : () => setCount(count + 3)}
          className={
            count === 3
              ? "flex flex-row w-full font-bold rounded-lg bg-magnet-secondarylight text-magnet-secondarydark flex justify-between px-4 py-3 "
              : "flex flex-row w-full font-bold rounded-lg bg-white text-dark flex justify-between px-4 py-3  ]"
          }
        >
          <div className="flex flex-grow">
            <p>Apakah kemitraan terbuka untuk di luar Pulau Jawa? </p>
            <div className="flex flex-grow"></div>
            <div className=" flex items-left">
              {count === 3 ? (
                <Icons.ANGLE_UP className="fill-magnet-secondary" />
              ) : (
                <Icons.ANGLE_DOWN className="fill-magnet-secondary" />
              )}
            </div>
          </div>
        </summary>
        <p className="px-4 py-3 text-sm">...</p>
      </details>{" "}
      <details
        className={
          count === 4
            ? "py-8 px-8 w-full bg-magnet-secondarylight cursor-pointer mb-3 rounded-xl"
            : "py-8 px-8 w-full bg-white cursor-pointer mb-3"
        }
      >
        <summary
          onClick={count > 0 ? () => setCount(4) : () => setCount(count + 4)}
          className={
            count === 4
              ? "flex flex-row  w-full font-bold rounded-lg bg-magnet-secondarylight text-magnet-secondarydark flex justify-between px-4 py-3  "
              : "flex flex-row  w-full font-bold rounded-lg bg-white text-dark flex justify-between px-4 py-3  "
          }
        >
          <div className="flex flex-grow">
            <p>Apa keuntungan menjadi Mitra Mata Garuda? </p>
            <div className="flex flex-grow"></div>
            <div className=" flex items-left">
              {count === 4 ? (
                <Icons.ANGLE_UP className="fill-magnet-secondary" />
              ) : (
                <Icons.ANGLE_DOWN className="fill-magnet-secondary" />
              )}
            </div>
          </div>
        </summary>
        <p className="px-4 py-3 text-sm pr-16">...</p>
      </details>
    </>
  );
}

class SectionFaq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="flex items-center justify-center">
        <div className="w-full px-10 py-8 mx-auto bg-white rounded-lg ">
          <h1 className="text-3xl font-bold mb-10 text-center">
            FAQ - Pertanyaan Yang Sering
            <br />
            Ditanyakan
          </h1>
          <Accordion />
        </div>
      </div>
    );
  }
}

export default SectionFaq;
