
import * as APP_DATA from "../../ConstantData";
import CompanyDetailView from "./CompanyDetailView";
import React from "react";
import { API } from '../../entity/API';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

class CompanyDetailController extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      detail_company: [],
    }
  }

  componentDidMount() {
    const slugParams =  window.location.pathname.split('/')[2]
    this.callAPIDetailCompany(slugParams)
  }

  async callAPIDetailCompany(slugParams) {
    const self = this
    try {
      const response = await axios.get(
        API.GET_COMPANIES + '/' + slugParams,
        {
          headers: {
            'Content-Type': 'application/json'
          },
        });
      var resultRes = response.data;
      if (resultRes.success === true) {
        console.log(resultRes.data)
        self.setState({
          detail_company: resultRes.data,
        })
      } else {

      }
    }
    catch (error) {
      if (error.response !== undefined) {
        // console.log(error.response);
      }
    }
  }

  render() {
    document.title = APP_DATA.APP_NAME;
    return (
      <CompanyDetailView
        navigate={this.props.navigate}
        detail_company={this.state.detail_company}
      />
    )
  }
}

export default (props) => {
  const navigate = useNavigate();
  return (
    <CompanyDetailController {...props} navigate={navigate} />
  )
}
