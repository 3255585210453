import * as APP_DATA from "../../ConstantData";
import BussinessView from "./BussinessView";
import React from "react";

class BussinessController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    document.title = APP_DATA.APP_NAME;
    return <BussinessView />;
  }
}

export default BussinessController;
