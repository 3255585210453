import React from 'react'
import * as APP_DATA from '../../ConstantData';
import CredentialInfo from './section/CredentialInfo';
import UserRegisterNavigation from './section/Navigation';
import PageNavigation from './section/PageNavigator';
import ProfileInfo from './section/ProfileInfo';
import SocialMediaInfo from './section/SocialMediaInfo';


class RegisterView extends React.Component {
  render() {
    const {
      active_section_id,
      moveToSection,
      error_messages,
    } = this.props;

    return (
      <div className="flex flex-col items-center font-poppins">
        <UserRegisterNavigation
          active_section_id={active_section_id}
          moveToSection={moveToSection}
        />
        <div className="flex mb-24 flex-col gap-6 md:p-16 p-10 rounded-3xl mt-10 bg-white md:shadow-xl md:w-10/12 z-0">
          {active_section_id === APP_DATA.USER_REGISTRATION_SECTION.credential ?
            <CredentialInfo {...this.props} />
            : active_section_id === APP_DATA.USER_REGISTRATION_SECTION.profile
              ? <ProfileInfo {...this.props} />
              : active_section_id === APP_DATA.USER_REGISTRATION_SECTION.socialmedia
                ? <SocialMediaInfo {...this.props} />
                : <div>Invalid Section ID</div>}
          <div className='flex flex-col'>
            {error_messages.map(function (message, i) {
              return <p key={i} className="text-sm text-red-500">* {message}</p>
            })}
          </div>
          <PageNavigation {...this.props} />
        </div>
      </div>
    );
  }
}


export default RegisterView