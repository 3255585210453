import React from "react";
import { Icons } from "../../../entity/Icons";

function format_number(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
class CareerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }



  render() {
    const { content } = this.props;
    return (
      <div className="flex flex-col ">
        <div className="flex xl:flex-row items-center max-xl:flex-col ">

          {content.map((item, i) => {
            if (i < 3)
              return (
                <div className="border  justify-center xl:w-1/3 max-xl:w-10/12 content-center items-center border-grey rounded-xl gap-4 p-4 m-4">
                  <div className="flex flex-row">
                    <img alt="" src={item.image} className="h-16 "></img>
                    <div className="flex flex-col ml-4">
                      <div className="text-lg font-bold">{item.nama}</div>
                      <div className="flex flex-row mt-2">
                        <div className="text-sm font-medium">{item.ver}</div>
                        <div className="text-sm font-medium">{item.perusahaan}</div>
                      </div>
                    </div></div>
                  <div className="flex flex-row">

                    <div className="mt-4 font-semibold mr-2">  <Icons.MAP_MARKER ClassName="h-4 w-4" /></div>
                    <div className="mt-4 font-semibold">{item.alamat},</div>
                    <div className="mt-4 ml-1"> {item.country}</div>
                    <div className="flex flex-grow"></div>
                  </div>
                  <div className="flex flex-row mt-2">

                    <div className="font-light text-sm mr-2"><Icons.INVOICE ClassName="h-4 w-4" /></div>
                    {item.login === true ?
                      <div className="font-light text-magnet-primary text-sm">Login untuk Melihat</div>
                      : (item.salarymax === undefined) ? <div className="font-light text-sm">Rp. {format_number(item.salarymin)} </div>
                        : <div className="font-light">Rp.{format_number(item.salarymin)} - Rp.{format_number(item.salarymax)}</div>}

                    <div className="flex flex-grow"></div>
                  </div>
                  <div className="flex flex-row mt-2">

                    <div className="font-light text-sm mr-2"><Icons.TREES ClassName="h-4 w-4" /></div>
                    {item.experiencemax === undefined ? <div className="font-light "> &lt; {item.experiencemin} tahun</div> :
                      <div className="font-light"> {item.experiencemin} - {item.experiencemax} tahun</div>}
                    <div className="flex flex-grow"></div>
                  </div>
                  <div className="flex flex-row mt-4">
                    <div>
                      <Icons.CLOCK ClassName="h-2 w-2 fill-gray-400 " />
                    </div>
                    <div className="font-light text-sm mt-1 ml-2">
                      {item.time}
                    </div>

                    <div className="flex flex-grow"></div>
                    <div className="flex flex-row mb-4 ">
                      <button className="bg-gray-200 text-gray-400 text-xs font-semibold font-bold py-1 px-2 rounded-full">
                        {item.jenis}
                      </button>
                    </div>
                  </div>
                </div>
              );
          })}
        </div>
        <div className="flex xl:flex-row items-center max-xl:flex-col max-xl:mr-0 xl:mr-20">
          {content.map((item, i) => {
            if (i > 2 && i < 6)
              return (
                <div className="border  justify-center xl:w-1/3 max-xl:w-10/12 content-center items-center border-grey rounded-xl gap-4 p-4 m-4">
                  <div className="flex flex-row">
                    <img alt="" src={item.image} className="h-16 "></img>
                    <div className="flex flex-col ml-4">
                      <div className="text-lg font-bold">{item.nama}</div>
                      <div className="flex flex-row mt-2">
                        <div className="text-sm font-medium">{item.ver}</div>
                        <div className="text-sm font-medium">{item.perusahaan}</div>
                      </div>
                    </div></div>
                  <div className="flex flex-row">

                    <div className="mt-4 font-semibold mr-2">  <Icons.MAP_MARKER ClassName="h-4 w-4" /></div>
                    <div className="mt-4 font-semibold">{item.alamat},</div>
                    <div className="mt-4 ml-1"> {item.country}</div>
                    <div className="flex flex-grow"></div>
                  </div>
                  <div className="flex flex-row mt-2">

                    <div className="font-light text-sm mr-2"><Icons.INVOICE ClassName="h-4 w-4" /></div>
                    {item.login === true ?
                      <div className="font-light text-magnet-primary text-sm">Login untuk Melihat</div>
                      : (item.salarymax === undefined) ? <div className="font-light text-sm">Rp. {format_number(item.salarymin)} </div>
                        : <div className="font-light">Rp.{format_number(item.salarymin)} - Rp.{format_number(item.salarymax)}</div>}

                    <div className="flex flex-grow"></div>
                  </div>
                  <div className="flex flex-row mt-2">

                    <div className="font-light text-sm mr-2"><Icons.TREES ClassName="h-4 w-4" /></div>
                    {item.experiencemax === undefined ? <div className="font-light "> &lt; {item.experiencemin} tahun</div> :
                      <div className="font-light"> {item.experiencemin} - {item.experiencemax} tahun</div>}
                    <div className="flex flex-grow"></div>
                  </div>
                  <div className="flex flex-row mt-4">
                    <div>
                      <Icons.CLOCK ClassName="h-2 w-2 fill-gray-400 " />
                    </div>
                    <div className="font-light text-sm mt-1 ml-2">
                      {item.time}
                    </div>

                    <div className="flex flex-grow"></div>
                    <div className="flex flex-row mb-4 ">
                      <button className="bg-gray-200 text-gray-400 text-xs font-semibold font-bold py-1 px-2 rounded-full">
                        {item.jenis}
                      </button>
                    </div>
                  </div>
                </div>
              );
          })}

        </div>
      </div>
    );
  }
}

export default CareerList;
