import { API } from "../../entity/API";
import { Page } from "../../entity/Pages";
import { useNavigate } from "react-router-dom";
import * as APP_DATA from "../../../core/ConstantData";
import AgendaView from "./AgendaView";
import axios from "axios";
import React from "react";

class AgendaController extends React.Component {
  constructor(props) {
    super(props);
    this.navigateToAgenda = this.navigateToAgenda.bind(this);
    this.setActiveMonth = this.setActiveMonth.bind(this);
    this.setActiveYear = this.setActiveYear.bind(this);
    this.setClickedDate = this.setClickedDate.bind(this);
    this.state = {
      prev_active_month: null,
      active_month: new Date().getMonth(),
      active_year: new Date().getFullYear(),
      all_agenda: [],
      clicked_date: null,
      clicked_event_index: null,
    };
  }

  componentDidMount() {
    this.callAPIAgenda()
  }

  componentDidUpdate() {
    if (this.state.prev_active_month !== this.state.active_month) {
      this.callAPIAgenda()
    }
  }

  async callAPIAgenda() {
    const self = this
    try {
      const response = await axios.get(
        API.GET_AGENDA,
        {
          headers: {
            // 'Authorization': `Bearer ${self.read_jwt()}`,
            // 'Content-Type': 'multipart/form-data'
          },
          params: {
            month: self.state.active_month + 1,
            year: self.state.active_year,
          }
        });
      // console.log('response.data')
      if (response.data.status === 'OK') {
        const all_agenda = self.preprocessAgenda(response.data['agenda'])
        self.setState({
          all_agenda: all_agenda,
          prev_active_month: self.state.active_month,
        })
      } else {

      }
    }
    catch (error) {
      if (error.response !== undefined) {
        // console.log(error.response);
      }
    }
  }

  preprocessAgenda(arr_agenda) {
    arr_agenda.forEach(agenda => {
      // parsing date
      const splitted_date = agenda['tanggal_1'].split('-')
      agenda['year'] = parseInt(splitted_date[0])
      agenda['month'] = parseInt(splitted_date[1])
      agenda['day'] = parseInt(splitted_date[2])

      // parsing time end
      const splitted_time_end = agenda['jam_berakhir_1'].split(':')
      agenda['hour'] = parseInt(splitted_time_end[0])
      agenda['minute'] = parseInt(splitted_time_end[1])
      agenda['second'] = parseInt(splitted_time_end[2])

      // js date format
      agenda['date'] = new Date(
        agenda['year'], agenda['month'] - 1, agenda['day'],
        agenda['hour'], agenda['minute'], agenda['second']
      )

      // add host to image url
      agenda['poster_url'] = API.HOST + agenda['upload_poster']
      agenda['kategori_atau_penyelenggara_arr'] = agenda['kategori_atau_penyelenggara'].split(', ')
    });

    // creating agenda in json, day as key, there is possibility for more than 1 agenda in a day
    let json_agenda = {}
    for (let i = 0; i < arr_agenda.length; i++) {
      const key = arr_agenda[i].day
      if (key in json_agenda) {
        json_agenda[key].push(arr_agenda[i])
      } else {
        json_agenda[key] = [arr_agenda[i]]
      }
    }
    return json_agenda
  }

  navigateToAgenda() {
    this.props.navigate(Page.AGENDA)
  }

  setActiveMonth(value) {
    this.setState({
      active_month: value,
      all_agenda: []
    })
  }

  setActiveYear(value) {
    this.setState({
      active_year: value,
      all_agenda: []
    })
  }

  setClickedDate(c_date, c_event_index) {
    this.setState({
      clicked_date: c_date,
      clicked_event_index: c_event_index,
    })
  }

  render() {
    document.title = APP_DATA.APP_NAME;
    return (
      <AgendaView
        active_month={this.state.active_month}
        active_year={this.state.active_year}
        all_agenda={this.state.all_agenda}
        clicked_date={this.state.clicked_date}
        clicked_event_index={this.state.clicked_event_index}
        setActiveMonth={this.setActiveMonth}
        setActiveYear={this.setActiveYear}
        setClickedDate={this.setClickedDate}
        navigateToAgenda={this.navigateToAgenda}
      />
    );
  }
}

export default (props) => {
  const navigate = useNavigate();
  return <AgendaController {...props} navigate={navigate} />
};
