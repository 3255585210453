import React from 'react'
import { Icons } from '../../entity/Icons'
import { read_username } from '../../utils/JwtToken'
// import { Page } from '../../entity/Pages'


class UserProfileEdit extends React.Component {
  render() {
    const {
      navigator,
      profile_data,
      profileUpdate,
      is_editing,
      switchEditing,
      is_own_profile,
      callAPIUserPost,
      onSelectImage,
      onSelectCV,
      is_saving_on_process,
      progress,
      error_messages,
    } = this.props

    let asteric_symbol = <span className="text-magnet-rose-normal">&nbsp;*</span>

    var input_image = <div>
      <div className="md:text-lg text-base font-semibold">
        <p>Foto Profil{asteric_symbol}</p>
        <p className='text-xs font-light mb-2'>Maximum filesize 1 MB</p>
      </div>
      <img alt='preview' id='preview-photo' src={profile_data.Picture} className='bg-gray-100 h-40 w-40 m-4 object-cover'
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/768px-Windows_10_Default_Profile_Picture.svg.png";
        }}></img>
      {error_messages && <p className='text-magnet-rose-normal text-xs'>{error_messages}</p>}
      {is_editing
        ? <input
          className="shadow appearance-none border rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
          id="profile_picture"
          type="file"
          accept="image/png, image/jpeg"
          onChange={(e) => onSelectImage(e)}
        ></input>
        : <input
          className="shadow appearance-none border rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline disabled:text-gray-300"
          id="profile_picture"
          type="file"
          accept="image/png, image/jpeg"
          disabled
        ></input>}
    </div>

    return (
      <div className='flex flex-col flex-grow font-poppins items-center justify-center'>
        <div className='flex flex-col w-10/12 py-12 gap-6 md:text-base text-sm'>
          <div className='flex justify-start'>
            <div className='flex justify-center items-center cursor-pointer hover:fill-magnet-rose-normal hover:text-magnet-rose-normal duration-500'
              onClick={() => navigator("/profile/" + read_username())}>
              <Icons.ANGLE_LEFT className='h-10 w-10 cursor-pointer -ml-4' />
              <p className=''>Back to profil view</p>
            </div>
          </div>
          {is_own_profile && <div>
            <div className="md:text-lg text-base font-semibold">
              <p>Alamat email{asteric_symbol}</p>
            </div>
            <input
              className="shadow appearance-none border rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed"
              id="email"
              type="email"
              placeholder="Masukkan alamat email"
              value={profile_data.Email}
              onChange={(e) => null}
              disabled
            ></input>
          </div>}

          <div className='flex md:flex-row flex-col justify-between gap-6'>
            <div className='flex flex-col gap-6 w-full'>
              <div className='md:hidden flex flex-col'>
                {input_image}
              </div>
              <div>
                <div className="md:text-lg text-base font-semibold">
                  <p>Nama Lengkap{asteric_symbol}</p>
                </div>
                {is_editing
                  ? <input
                    className="shadow appearance-none border rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                    id="fullname"
                    type="text"
                    value={profile_data.Fullname}
                    onChange={(e) => profileUpdate({ 'Fullname': e.target.value })}
                    placeholder="Masukkan nama lengkap"
                  ></input>
                  : <p className='appearance-none rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline border border-gray-200'>
                    {profile_data.Fullname}
                  </p>}
              </div>
              <div>
                <div className="md:text-lg text-base font-semibold">
                  <p>Kota Domisili{asteric_symbol}</p>
                </div>
                {is_editing
                  ? <input
                    className="shadow appearance-none border rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                    id="city"
                    type="text"
                    value={profile_data.City}
                    onChange={(e) => profileUpdate({ 'City': e.target.value })}
                    placeholder="Masukkan kota domisili"
                  ></input>
                  : <p className='appearance-none rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline border border-gray-200'>
                    {profile_data.City}
                  </p>}
              </div>
              <div>
                <div className="md:text-lg text-base font-semibold">
                  <p>Negara Domisili{asteric_symbol}</p>
                </div>
                {is_editing
                  ? <input
                    className="shadow appearance-none border rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                    id="country"
                    type="text"
                    value={profile_data.Country}
                    onChange={(e) => profileUpdate({ 'Country': e.target.value })}
                    placeholder="Masukkan negara domisili"
                  ></input>
                  : <p className='appearance-none rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline border border-gray-200'>
                    {profile_data.Country}
                  </p>}
              </div>
              <div>
                <div className="md:text-lg text-base font-semibold">
                  <p>Universitas{asteric_symbol}</p>
                </div>
                {is_editing
                  ? <input
                    className="shadow appearance-none border rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                    id="university"
                    type="text"
                    value={profile_data.University}
                    onChange={(e) => profileUpdate({ 'University': e.target.value })}
                    placeholder="Masukkan universitas (yang dibiayai LPDP terakhir)"
                  ></input>
                  : <p className='appearance-none rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline border border-gray-200'>
                    {profile_data.University}
                  </p>}
              </div>
              <div>
                <div className="md:text-lg text-base font-semibold">
                  <p>Program Studi{asteric_symbol}</p>
                </div>
                {is_editing
                  ? <input
                    className="shadow appearance-none border rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                    id="university"
                    type="text"
                    value={profile_data.MajorStudy}
                    onChange={(e) => profileUpdate({ 'MajorStudy': e.target.value })}
                    placeholder="Masukkan program studi (yang dibiayai LPDP terakhir)"
                  ></input>
                  : <p className='appearance-none rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline border border-gray-200'>
                    {profile_data.MajorStudy}
                  </p>}
              </div>
            </div>

            <div className='flex flex-col gap-6 w-full'>
              <div className='md:flex md:flex-col hidden'>
                {input_image}
              </div>
              <div>
                <div className="md:text-lg text-base font-semibold">
                  <p>Curiculum Vitae</p>
                </div>
                {is_editing
                  ? <input
                    className="shadow appearance-none border rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                    id="cv"
                    type="file"
                    accept="application/pdf"
                    onChange={(e) => onSelectCV(e)}
                  ></input>
                  : <input
                    className="shadow appearance-none border rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline disabled:text-gray-300"
                    id="cv"
                    type="file"
                    accept="application/pdf"
                    disabled
                  ></input>}
                {(profile_data.CV && profile_data.CV.includes('.pdf')) &&
                  <form method="get" action={profile_data.CV} target="_blank">
                    <button className=' text-magnet-rose-normal font-semibold text-xs bg-gray-100 px-4 py-1 rounded-lg mt-2'
                      type="submit">
                      Download CV
                    </button>
                  </form>}
              </div>
              <div>
                <div className="md:text-lg text-base font-semibold">
                  <p>Nomor PK{asteric_symbol}</p>
                </div>
                {is_editing
                  ? <input
                    className="shadow appearance-none border rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                    id="pk"
                    type="number"
                    value={profile_data.PK}
                    onChange={(e) => profileUpdate({ 'PK': e.target.value })}
                    placeholder="Masukkan nomor PK (angka saja)"
                  ></input>
                  : <p className='appearance-none rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline border border-gray-200'>
                    {profile_data.PK}
                  </p>}
              </div>
              <div>
                <div className="md:text-lg text-base font-semibold">
                  <p>Kategori Keahlian atau Minat{asteric_symbol}</p>
                </div>
                {is_editing
                  ? <input
                    className="shadow appearance-none border rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                    id="speciality"
                    type="text"
                    value={profile_data.Expertise}
                    onChange={(e) => profileUpdate({ 'Expertise': e.target.value })}
                    placeholder="Masukkan kategori keahlian"
                  ></input>
                  : <p className='appearance-none rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline border border-gray-200'>
                    {profile_data.Expertise}
                  </p>}
              </div>
            </div>
          </div>

          <div className='flex flex-col gap-6'>
            <div>
              <div className="md:text-lg text-base font-semibold">
                <p>Deskripsi diri (Max 500 karakter){asteric_symbol}</p>
              </div>
              {is_editing
                ? <textarea
                  className="h-80 shadow appearance-none border rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline resize-none"
                  id="deksripsi"
                  type="text"
                  value={profile_data.Description}
                  onChange={(e) => profileUpdate({ 'Description': e.target.value })}
                  maxLength="500"
                  placeholder="Masukkan deskripsi diri"
                ></textarea>
                : <textarea
                  className="h-80 appearance rounded-xl w-full py-4 px-4 focus px-4:outline-magnet-rose-normal focus:shadow-outline resize-none border border-gray-200 bg-white"
                  id="deksripsi"
                  value={profile_data.Description}
                  disabled
                ></textarea>}
            </div>
            <div>
              <div className="md:text-lg text-base font-semibold">
                <p>Alasan berjejaring (Max 500 karakter){asteric_symbol}</p>
              </div>
              {is_editing
                ? <textarea
                  className="h-40 shadow appearance-none border rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline resize-none"
                  id="alasan"
                  type="text"
                  value={profile_data.Reason}
                  onChange={(e) => profileUpdate({ 'Reason': e.target.value })}
                  maxLength="500"
                  placeholder="Masukkan alasan berjejaring"
                ></textarea>
                : <textarea
                  className="h-40 appearance rounded-xl w-full py-4 px-4 focus px-4:outline-magnet-rose-normal focus:shadow-outline resize-none border border-gray-200 bg-white"
                  id="deksripsi"
                  value={profile_data.Reason}
                  disabled
                ></textarea>}
            </div>
            <div>
              <div className="md:text-lg text-base font-semibold">
                <p>Akun Media Sosial</p>
              </div>
              <div className='grid md:grid-cols-2 gap-6'>
                <div className='flex items-center'>
                  <Icons.WHATSAPP className='mr-2 fill-green-700' />
                  {is_editing
                    ? <input
                      className="shadow appearance-none border rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                      id="whatsapp"
                      type="text"
                      value={profile_data.Whatsapp}
                      onChange={(e) => profileUpdate({ 'Whatsapp': e.target.value })}
                      placeholder="Nomor Whatsapp (contoh: +628XXXXXXXXXX)"
                    ></input>
                    : <p className='appearance-none rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline border border-gray-200'>
                      {profile_data.Whatsapp ? profile_data.Whatsapp : '-'}
                    </p>}
                </div>
                <div className='flex items-center'>
                  <Icons.INSTAGRAM className='mr-2 fill-pink-700' />
                  {is_editing
                    ? <input
                      className="shadow appearance-none border rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                      id="instagram"
                      type="text"
                      value={profile_data.Instagram}
                      onChange={(e) => profileUpdate({ 'Instagram': e.target.value })}
                      placeholder="Link atau username profil Instagram"
                    ></input>
                    : <p className='appearance-none rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline border border-gray-200'>
                      {profile_data.Instagram ? profile_data.Instagram : '-'}
                    </p>}
                </div>
                <div className='flex items-center'>
                  <Icons.LINKEDIN className='mr-2 fill-blue-700' />
                  {is_editing
                    ? <input
                      className="shadow appearance-none border rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                      id="linkedin"
                      type="text"
                      value={profile_data.Linkedin}
                      onChange={(e) => profileUpdate({ 'Linkedin': e.target.value })}
                      placeholder="Link atau username profil Linkedin"
                    ></input>
                    : <p className='appearance-none rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline border border-gray-200'>
                      {profile_data.Linkedin ? profile_data.Linkedin : '-'}
                    </p>}
                </div>
                <div className='flex items-center'>
                  <Icons.TWITTER className='mr-2 fill-blue-300' />
                  {is_editing
                    ? <input
                      className="shadow appearance-none border rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                      id="twitter"
                      type="text"
                      value={profile_data.Twitter}
                      onChange={(e) => profileUpdate({ 'Twitter': e.target.value })}
                      placeholder="Link atau username profil Twitter"
                    ></input>
                    : <p className='appearance-none rounded-xl w-full py-4 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline border border-gray-200'>
                      {profile_data.Twitter ? profile_data.Twitter : '-'}
                    </p>}
                </div>
              </div>
            </div>
            {is_own_profile && <div className='flex w-full justify-center items-center mt-8'>
              {is_editing
                ? <div className='flex gap-6'>
                  {<button className='px-8 py-2 rounded-xl text-white bg-magnet-green-normal hover:bg-magnet-green-dark duration-500 font-semibold'
                    onClick={() => { switchEditing(false); callAPIUserPost(); }}>
                    Simpan
                  </button>}
                  <button className='px-8 py-2 rounded-xl bg-white text-magnet-rose-normal border-magnet-rose-normal border hover:bg-magnet-rose-normal hover:text-white duration-500 font-semibold'
                    onClick={() => switchEditing(false)}>
                    Batal
                  </button>
                </div>
                : is_saving_on_process
                  ? <button className='px-8 py-2 rounded-xl text-white bg-gray-500 font-semibold'>
                    Menyimpan {progress}%
                  </button>
                  : <button className='px-8 py-2 rounded-xl text-white bg-magnet-green-normal hover:bg-magnet-green-dark duration-500 font-semibold'
                    onClick={() => switchEditing(true)}>
                    Mulai Sunting
                  </button>
              }
            </div>}
            <hr className="border-magnet-rose-normal mt-8" />
          </div>
        </div>
      </div>
    )
  }
}

export default UserProfileEdit