import React from "react";

class FaqContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    return (
      <div className="flex flex-col">
        <div className="container mx-auto">
          <div className="flex flex-col gap-y-4 md:gap-y-8 overflow-hidden">
            <div className="group outline-none accordion-section bg-zinc-100 rounded-2xl" tabIndex="1">
              <div className="group bg-white flex flex-row justify-between p-5 items-center text-zinc-700 border border-zinc-200 rounded-2xl transition ease duration-500 cursor-pointer relative">
                <div className="transition ease duration-500 ">
                  <p className="text-lg md:text-xl font-semibold">1. Apa itu Mata Garuda Network (MAGNET) ?</p>
                </div>
                <div className="h-8 w-8 items-center my-auto inline-flex justify-center transform transition ease duration-500 group-focus:text-white group-focus:rotate-90">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 5L16 12L9 19" stroke="#3F3F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
              <div className="group-focus:max-h-screen max-h-0 bg-zinc-100 rounded-2xl px-4 overflow-auto ease duration-500">
                <p className="px-2 py-5 text-zinc-700 text-left">
                  Mata Garuda Network (MAGNET) adalah organisasi resmi ikatan alumni penerima beasiswa LPDP yang bertujuan untuk membentuk jejaring sosial bagi para alumni dan awardee. Kami menyediakan platform untuk berkomunikasi, berkolaborasi, dan saling mendukung dalam pengembangan karier, peningkatan ilmu pengetahuan, serta pertukaran ide dan pengalaman.
                </p>
              </div>
            </div>

            <div className="group outline-none accordion-section bg-zinc-100 rounded-2xl" tabIndex="2">
              <div className="group bg-white flex flex-row justify-between p-5 items-center text-zinc-700 border border-zinc-200 rounded-2xl transition ease duration-500 cursor-pointer relative">
                <div className="transition ease duration-500 ">
                  <p className="text-lg md:text-xl font-semibold">2. Siapa yang dapat menjadi anggota Mata Garuda Network (MAGNET) ?</p>
                </div>
                <div className="h-8 w-8 items-center my-auto inline-flex justify-center transform transition ease duration-500 group-focus:text-white group-focus:rotate-90">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 5L16 12L9 19" stroke="#3F3F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
              <div className="group-focus:max-h-screen max-h-0 bg-zinc-100 rounded-2xl px-4 overflow-auto ease duration-500">
                <p className="px-2 py-5 text-zinc-700 text-left">
                  Anggota Mata Garuda Network (MAGNET) terbuka bagi seluruh alumni dan awardee yang pernah menerima beasiswa LPDP. Baik itu penerima beasiswa S2 maupun S3, serta berbagai program beasiswa lainnya yang ditawarkan oleh LPDP.
                </p>
              </div>
            </div>

            <div className="group outline-none accordion-section bg-zinc-100 rounded-2xl" tabIndex="3">
              <div className="group bg-white flex flex-row justify-between p-5 items-center text-zinc-700 border border-zinc-200 rounded-2xl transition ease duration-500 cursor-pointer relative">
                <div className="transition ease duration-500 ">
                  <p className="text-lg md:text-xl font-semibold">3. Bagaimana cara bergabung dengan Mata Garuda Network (MAGNET) ?</p>
                </div>
                <div className="h-8 w-8 items-center my-auto inline-flex justify-center transform transition ease duration-500 group-focus:text-white group-focus:rotate-90">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 5L16 12L9 19" stroke="#3F3F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
              <div className="group-focus:max-h-screen max-h-0 bg-zinc-100 rounded-2xl px-4 overflow-auto ease duration-500">
                <p className="px-2 py-5 text-zinc-700 text-left">
                  Untuk bergabung dengan Mata Garuda Network (MAGNET), Anda dapat mengunjungi website kami di https://magnet.or.id/ dan mengisi formulir pendaftaran yang telah disediakan. Setelah pendaftaran Anda diverifikasi, Anda akan menjadi anggota resmi MAGNET dan mendapatkan akses ke berbagai fasilitas dan program yang kami tawarkan.
                </p>
              </div>
            </div>

            <div className="group outline-none accordion-section bg-zinc-100 rounded-2xl" tabIndex="4">
              <div className="group bg-white flex flex-row justify-between p-5 items-center text-zinc-700 border border-zinc-200 rounded-2xl transition ease duration-500 cursor-pointer relative">
                <div className="transition ease duration-500 ">
                  <p className="text-lg md:text-xl font-semibold">4. Apa manfaat menjadi anggota Mata Garuda Network (MAGNET) ?</p>
                </div>
                <div className="h-8 w-8 items-center my-auto inline-flex justify-center transform transition ease duration-500 group-focus:text-white group-focus:rotate-90">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 5L16 12L9 19" stroke="#3F3F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
              <div className="group-focus:max-h-screen max-h-0 bg-zinc-100 rounded-2xl px-4 overflow-auto ease duration-500">
                <div className="flex flex-col px-2 py-5">
                  <p className=" text-zinc-700 text-left">
                    Sebagai anggota Mata Garuda Network (MAGNET), Anda akan mendapatkan berbagai manfaat, antara lain :
                  </p>
                  <ul className="list-disc ml-8 text-zinc-700">
                    <li>Kesempatan untuk berkomunikasi dan berkolaborasi dengan sesama alumni dan awardee LPDP di berbagai bidang.</li>
                    <li>Akses ke forum diskusi, webinar, dan acara networking yang dapat meningkatkan pengetahuan dan keterampilan Anda.</li>
                    <li>Dukungan dalam pengembangan karier dan pencarian peluang kerja melalui informasi lowongan kerja, mentoring, dan bimbingan karier.</li>
                    <li>Kemudahan akses ke sumber daya dan referensi terkait penelitian, publikasi, dan pengembangan ilmu pengetahuan.</li>
                    <li>Kesempatan untuk terlibat dalam kegiatan sosial, pengabdian masyarakat, dan proyek-proyek yang bermanfaat bagi masyarakat.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="group outline-none accordion-section bg-zinc-100 rounded-2xl" tabIndex="5">
              <div className="group bg-white flex flex-row justify-between p-5 items-center text-zinc-700 border border-zinc-200 rounded-2xl transition ease duration-500 cursor-pointer relative">
                <div className="transition ease duration-500 ">
                  <p className="text-lg md:text-xl font-semibold">5. Apakah ada biaya keanggotaan di Mata Garuda Network (MAGNET) ?</p>
                </div>
                <div className="h-8 w-8 items-center my-auto inline-flex justify-center transform transition ease duration-500 group-focus:text-white group-focus:rotate-90">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 5L16 12L9 19" stroke="#3F3F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
              <div className="group-focus:max-h-screen max-h-0 bg-zinc-100 rounded-2xl px-4 overflow-auto ease duration-500">
                <p className="px-2 py-5 text-zinc-700 text-left">
                  Tidak, keanggotaan di Mata Garuda Network (MAGNET) tidak memerlukan biaya. Kami menyediakan layanan ini secara gratis bagi para alumni dan awardee LPDP.
                </p>
              </div>
            </div>

            <div className="group outline-none accordion-section bg-zinc-100 rounded-2xl" tabIndex="6">
              <div className="group bg-white flex flex-row justify-between p-5 items-center text-zinc-700 border border-zinc-200 rounded-2xl transition ease duration-500 cursor-pointer relative">
                <div className="transition ease duration-500 ">
                  <p className="text-lg md:text-xl font-semibold">6. Apa yang membedakan Mata Garuda Network (MAGNET) dengan platform lain untuk alumni LPDP ?</p>
                </div>
                <div className="h-8 w-8 items-center my-auto inline-flex justify-center transform transition ease duration-500 group-focus:text-white group-focus:rotate-90">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 5L16 12L9 19" stroke="#3F3F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
              <div className="group-focus:max-h-screen max-h-0 bg-zinc-100 rounded-2xl px-4 overflow-auto ease duration-500">
                <p className="px-2 py-5 text-zinc-700 text-left">
                  Mata Garuda Network (MAGNET) memiliki fokus utama pada pembentukan jejaring sosial yang kuat dan berkelanjutan antara para alumni dan awardee LPDP. Kami menyediakan lingkungan yang mendukung pertukaran ide, kolaborasi, dan pengembangan bersama. Selain itu, kami juga menyediakan berbagai program dan kegiatan yang dikustomisasi untuk memenuhi kebutuhan dan aspirasi anggota kami.
                </p>
              </div>
            </div>

            <div className="group outline-none accordion-section bg-zinc-100 rounded-2xl" tabIndex="7">
              <div className="group bg-white flex flex-row justify-between p-5 items-center text-zinc-700 border border-zinc-200 rounded-2xl transition ease duration-500 cursor-pointer relative">
                <div className="transition ease duration-500 ">
                  <p className="text-lg md:text-xl font-semibold">7. Bagaimana saya dapat berkontribusi dalam Mata Garuda Network (MAGNET) ?</p>
                </div>
                <div className="h-8 w-8 items-center my-auto inline-flex justify-center transform transition ease duration-500 group-focus:text-white group-focus:rotate-90">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 5L16 12L9 19" stroke="#3F3F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
              <div className="group-focus:max-h-screen max-h-0 bg-zinc-100 rounded-2xl px-4 overflow-auto ease duration-500">
                <div className="flex flex-col px-2 py-5">
                  <p className=" text-zinc-700 text-left">
                    Anda dapat berkontribusi dalam Mata Garuda Network (MAGNET) dengan berbagai cara, seperti :
                  </p>
                  <ul className="list-disc ml-8 text-zinc-700">
                    <li>Berbagi pengetahuan dan pengalaman melalui partisipasi dalam forum diskusi dan webinar.</li>
                    <li>Menjadi mentor bagi sesama anggota yang membutuhkan bimbingan dan dukungan.</li>
                    <li>Mengikuti proyek-proyek sosial dan pengabdian masyarakat yang diadakan oleh MAGNET.</li>
                    <li>Menginformasikan lowongan kerja dan kesempatan karier kepada anggota MAGNET.</li>
                    <li>Berpartisipasi aktif dalam pengembangan program dan kegiatan MAGNET.</li>
                  </ul>
                  <p className=" text-zinc-700 text-left">
                    CS : Dewik 085262798705
                  </p>
                </div>
              </div>
            </div>

            <div className="group outline-none accordion-section bg-zinc-100 rounded-2xl" tabIndex="7">
              <div className="group bg-white flex flex-row justify-between p-5 items-center text-zinc-700 border border-zinc-200 rounded-2xl transition ease duration-500 cursor-pointer relative">
                <div className="transition ease duration-500 ">
                  <p className="text-lg md:text-xl font-semibold">8. Tutorial langkah untuk membuat akun di Mata Garuda Network (MAGNET) </p>
                </div>
                <div className="h-8 w-8 items-center my-auto inline-flex justify-center transform transition ease duration-500 group-focus:text-white group-focus:rotate-90">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 5L16 12L9 19" stroke="#3F3F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
              <div className="group-focus:max-h-screen max-h-0 bg-zinc-100 rounded-2xl px-4 overflow-auto ease duration-500">
                <div className="flex flex-col px-2 py-5">
                  <ul className="list-decimal ml-8 text-zinc-700 mb-4">
                    <li>
                      <p>Kunjungi Website Mata Garuda Network (MAGNET)</p>
                      <p>Buka browser web dan kunjungi website Mata Garuda Network (MAGNET) di https://magnet.or.id/</p>
                    </li>
                    <li>
                      <p>Pilih "Daftar" atau "Bergabung"</p>
                      <p>Di halaman utama website, cari tombol atau tautan yang bertuliskan "Daftar" atau "Bergabung". Klik tombol tersebut untuk melanjutkan proses pendaftaran.</p>
                    </li>
                    <li>
                      <p>Isi Formulir Pendaftaran</p>
                      <p>Pada halaman pendaftaran, Anda akan diminta untuk mengisi formulir dengan 3 bagian informasi yang diperlukan. </p>
                      <p>Halaman informasi pertama : Data Kredensial Akun</p>
                      <ul className="list-disc ml-8 text-zinc-700">
                        <li>Alamat email yang valid</li>
                        <li>Password akun (minimal 8 karakter)</li>
                      </ul>
                      <p>Halaman informasi kedua : Informasi Profil</p>
                      <ul className="list-disc ml-8 text-zinc-700">
                        <li>Nama Lengkap</li>
                        <li>Kota Domisili</li>
                        <li>Negara Domisili</li>
                        <li>Universitas</li>
                        <li>Program Studi</li>
                        <li>Foto Profil</li>
                        <li>Nomor PK</li>
                        <li>Kategori Keahlian</li>
                      </ul>
                      <p>Halaman informasi kedua : Data Media Sosial yang digunakan</p>
                      <ul className="list-disc ml-8 text-zinc-700">
                        <li>Deskripsi diri (maksimal 500 karakter)</li>
                        <li>Alasan Berjejaring (maksimal 500 karakter)</li>
                        <li>Akun Media Sosial (nomor whatsApp, LinkedIn, Instagram, Twitter)</li>
                      </ul>
                    </li>
                    <li>
                      <p>Submit data</p>
                    </li>
                    <li>
                      <p>Verifikasi Email</p>
                      <p>Setelah mengisi formulir dan menyetujui persyaratan, Anda akan menerima email verifikasi di alamat email yang telah Anda daftarkan. Buka email tersebut dan ikuti petunjuk verifikasi yang diberikan. Biasanya, Anda akan diminta untuk mengklik tautan verifikasi yang disediakan dalam email.</p>
                    </li>
                    <li>
                      <p>Akses Akun Anda</p>
                      <p>Setelah berhasil memverifikasi email, Anda dapat mengakses akun MAGNET Anda. Masuk ke website MAGNET menggunakan username (email) dan password yang telah Anda buat saat pendaftaran.</p>
                    </li>
                  </ul>
                  <p className="text-zinc-700 text-left my-3">Selamat! Anda sekarang memiliki akun di Mata Garuda Network (MAGNET) dan dapat menjelajahi fitur dan layanan yang disediakan untuk para alumni dan awardee LPDP.</p>
                  <p className="text-zinc-700 text-left ">Pastikan untuk mengikuti petunjuk secara teliti dan memeriksa setiap langkah dengan cermat untuk memastikan pendaftaran Anda berhasil. Jika Anda mengalami kesulitan atau memiliki pertanyaan lebih lanjut, jangan ragu untuk menghubungi tim support MAGNET melalui kontak yang disediakan di website mereka.</p>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    );
  }
}

export default FaqContent;
