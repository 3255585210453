import React from "react";
import { Icons } from "../../../entity/Icons";
import { months, days_indo } from "../kalender/Utils";


function inactive_date_box(day_date) {
  return (
    <div key={day_date} className="relative flex flex-col bg-gray group">
      <div className="flex justify-end items-end">
        <div className="w-6 mr-4 ml-10 pl-1 mb-1 bg-gray-100 text-gray-400 inline mt-1  text-xs font-medium rounded-sm px-4">
          {day_date}
        </div>
      </div>
    </div>
  );
}

function active_date_box(day_date, active_month, agenda_list, setClickedDate) {
  const today = new Date()
  const is_today = day_date === today.getDate() && active_month === today.getMonth()

  return (
    <div key={day_date} className={is_today ? "relative flex flex-col bg-magnet-rose-normal group" : "relative flex flex-col bg-white group"}>
      <div className="flex justify-between">
        {is_today ? <p className="text-white text-sm whitespace-nowrap ml-1 mt-1">Hari ini</p> : <p></p>}

        <div className="w-6 mr-4 ml-10 pl-1 mb-1 text-end bg-gray-100 text-black inline mt-1 text-xs font-medium rounded-sm px-4">
          {day_date}
        </div>
      </div>
      <div className="flex flex-col px-1 py-1 h-20 overflow-auto">
        {
          agenda_list
            ? agenda_list.map((agenda, i) => {
              const datetime_agenda = agenda.date.getTime()
              const datetime_now = new Date().getTime()
              const is_passed_day = datetime_now > datetime_agenda

              if (is_passed_day) {
                return <button key={i} className="flex flex-shrink-0 items-center rounded-lg h-5 px-1 my-1 text-xs bg-red-100 hover:bg-red-200 duration-500"
                  onClick={() => setClickedDate(agenda.day, i)}>
                  <p className="flex-shrink-0 w-2 h-2 bg-red-500 border-red-500 hover:bg-red-400 rounded-full duration-500"></p>
                  <span className="ml-2 font-medium truncate">{agenda.judul}</span>
                </button>
              } else {
                return <button key={i} className="flex flex-shrink-0 items-center rounded-lg h-5 px-1 my-1 text-xs bg-green-100 hover:bg-green-200 duration-500"
                  onClick={() => setClickedDate(agenda.day, i)}>
                  <p className="flex-shrink-0 w-2 h-2 bg-magnet-green-normal border-magnet-green-normal hover:bg-magnet-green-light rounded-full duration-500"></p>
                  <span className="ml-2 font-medium truncate">{agenda.judul}</span>
                </button>
              }
            })
            : <button className="flex flex-shrink-0 items-center rounded-lg h-5 px-1 my-1 text-xs bg-gray-100 hover:bg-gray-200 duration-500"
              onClick={() => setClickedDate(null, null)}>
              <span className="flex-shrink-0 w-2 h-2 bg-gray-500 border-gray-500 hover:bg-gray-400 rounded-full duration-500"></span>
              <span className="ml-2 font-medium leading-none truncate">No Activity</span>
            </button>
        }
      </div>
    </div>
  );
}


class Kalender extends React.Component {
  render() {
    const {
      active_month,
      active_year,
      setActiveMonth,
      setActiveYear,
      all_agenda,
      setClickedDate,
    } = this.props

    const num_of_agenda = Object.keys(all_agenda).length
    return (
      <React.Fragment>
        {/* Navigation */}
        <div className="flex items-center justify-between mt-4">
          {active_month > 0
            ? <button className="w-28 border border-magnet-rose-normal hover:bg-magnet-rose-normal fill-magnet-rose-normal hover:fill-white hover:text-white duration-500 rounded-lg p-1 flex justify-between items-center select-none"
              onClick={() => setActiveMonth(active_month - 1)}>
              <Icons.ANGLE_LEFT className="" />
              <h5 className="text-sm mr-2">
                {months[active_month - 1].bulan}
              </h5>
            </button>
            : <button className="w-28 border border-magnet-rose-normal hover:bg-magnet-rose-normal fill-magnet-rose-normal hover:fill-white hover:text-white duration-500 rounded-lg p-1 flex justify-between items-center select-none"
              onClick={() => { setActiveMonth(11); setActiveYear(active_year - 1) }}>
              <Icons.ANGLE_LEFT className="" />
              <h5 className="text-sm mr-2">
                {months[11].bulan}
              </h5>
            </button>}
          <button className="md:text-xl text-md font-bold text-center select-none">
            {months[active_month].bulan} {active_year}
          </button>
          {active_month < 11
            ? <button className="w-28 border border-magnet-rose-normal hover:bg-magnet-rose-normal fill-magnet-rose-normal hover:fill-white hover:text-white duration-500 rounded-lg p-1 flex justify-between items-center select-none"
              onClick={() => setActiveMonth(active_month + 1)}
            >
              <h5 className="text-sm ml-2">
                {months[active_month + 1].bulan}
              </h5>
              <Icons.ANGLE_RIGHT className="" />
            </button>
            : <button className="w-28 border border-magnet-rose-normal hover:bg-magnet-rose-normal fill-magnet-rose-normal hover:fill-white hover:text-white duration-500 rounded-lg p-1 flex justify-between items-center select-none"
              onClick={() => { setActiveMonth(0); setActiveYear(active_year + 1) }}>
              <h5 className="text-sm ml-2">
                {months[0].bulan}
              </h5>
              <Icons.ANGLE_RIGHT className="" />
            </button>}
          {/* <div className="flex flex-grow"></div>
          <div className="border border-orange-200 rounded-lg -mt-3 w-4/12 bg-white flex flex-row items-center justify-between">
            <div className="px-4">
              <Icons.AIRPLAY className="h-10 fill-magnet-rose-normal" />
            </div>
            <input
              className="p-4 w-full"
              type="text"
              placeholder="Type Here..."
            />
            <div className="px-4">
              <Icons.AIRPLAY className="h-10 fill-magnet-rose-normal" />
            </div>
            <div className="px-4 cursor-pointer">
              <Icons.TIMES className="h-6 w-6 fill-magnet-gray hover:fill-magnet-rose-normal bg-gray-100 rounded-full duration-500" />
            </div>
          </div> */}
        </div>

        {/* Calendar */}
        <div className="text-gray-700">
          <div className="flex flex-grow overflow-auto">
            <div className="md:flex hidden flex-col flex-grow border border-magnet-rose-normal">
              <div className="grid w-12/12 grid-cols-7 mt-2 ">
                {days_indo.map((sebutHari, i) => {
                  return (
                    <div key={i} className="pl-1 text-sm mr-2 text-right">{sebutHari}</div>
                  );
                })}
              </div>
              <div className="border border-gray-900 grid flex-row w-12/12 h-auto grid-cols-7 grid-rows-5 gap-px pt-px mt-1 bg-gray-200">
                {
                  active_month !== 0 &&
                  Array(months[active_month - 1].end).fill(0).map((_, i) => {
                    let num = months[active_month - 1].end
                    let date = months[active_month - 1].jumlahHari - num + i + 1
                    return inactive_date_box(date)
                  })
                }
                {
                  Array(months[active_month].jumlahHari).fill(0).map((_, i) => {
                    const day = i + 1
                    let this_day_agenda = null;
                    if (day in all_agenda) {
                      this_day_agenda = all_agenda[day]
                    }
                    return active_date_box(day, active_month, this_day_agenda, setClickedDate)
                  })
                }
                {
                  active_month !== 11 &&
                  Array(7 - months[active_month + 1].start).fill(0).map((_, i) => {
                    return inactive_date_box(i + 1)
                  })
                }
              </div>
            </div>
            <div className="md:hidden flex flex-col grow border border-magnet-rose-normal rounded-lg">
              {num_of_agenda > 0
                ? <div className="min-h-[16rem] flex flex-col gap-4">
                  {Object.keys(all_agenda).map((key, i) => {
                    const date_str = days_indo[all_agenda[key][0].date.getDay()] + ", " + all_agenda[key][0].day
                    const datetime_agenda = all_agenda[key][0].date.getTime()
                    const datetime_now = new Date().getTime()
                    const is_future = datetime_agenda > datetime_now
                    const color = is_future ? "green" : "rose"

                    return <div key={i} className="flex flex-col py-2 px-4">
                      <div className="flex">
                        <p className={"text-xs bg-magnet-" + color + "-normal text-white rounded-lg px-2 py-1 mb-1 w-20 text-center"}>{date_str}</p>
                      </div>
                      <div className="flex flex-col gap-2">
                        {all_agenda[key].map((agenda, i) => {
                          return <p
                            key={i}
                            className="pl-2 text-sm bg-gray-100 hover:bg-gray-200 duration-500 cursor-pointer rounded-lg py-1"
                            onClick={() => { setClickedDate(agenda.day, i) }}>
                            {agenda.judul}
                          </p>
                        })}
                      </div>
                    </div>
                  })}
                </div>
                : <div className="h-64 flex justify-center items-center">
                  Tidak ada agenda bulan ini
                </div>}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Kalender;
