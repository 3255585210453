import React from "react";
import AboutusContent from "./sections/AboutusContent";
import { AboutusImage } from "../../entity/AboutusImage";
import AboutusConnection from "./sections/AboutusConnection";
import AboutusVision from "./sections/AboutusVision";
import AboutusTeam from "./sections/AboutusTeam";
import AboutusMgtalks from "./sections/AboutusMgtalks";

class AboutusView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="flex flex-col items-center font-poppins">
        <div className="w-full flex flex-col gap-16 mt-10 justify-center">
          <div className="w-10/12 bg-white mx-auto">
            <div className="flex flex-col md:flex-row items-center md:items-end self-center gap-8">
              <div className="w-full md:w-6/12">
                <h2 className="text-3xl md:text-5xl font-bold leading-snug bg-gradient-to-r from-magnet-rose-normal to-magnet-orange-normal bg-clip-text text-transparent">Dengan 32.000+ anggota, Mata Garuda adalah organisasi intelektual terbesar di Indonesia.</h2>
              </div>
              <div className="w-full md:w-6/12 flex flex-col">
                <p className="text-base font-medium text-gray-700 items-end mt-auto">Mata Garuda Network merupakan komunitas intelektual para alumni dan penerima Beasiswa LPDP. <br /> <br />Mata Garuda Network adalah Social Network Optimization Platform, dari dan untuk alumni LPDP. Mata Garuda Network adalah sebuah wadah untuk menyatukan, mengelola, dan mengaktivasi jejaring awardee untuk mengoptimalkan berbagai inisiatif.</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full md:w-10/12 self-center">
            <img className="w-full min-h-[323px] object-cover rounded-2xl " src={AboutusImage.aboutusbanner} alt="Banner About us"></img>
          </div>
          <AboutusContent />
          <AboutusConnection />
          <AboutusVision />
          {/* <AboutusTeam /> */}
          <AboutusMgtalks />
        </div>
      </div>
    );
  }
}

export default AboutusView;
