export const days_indo = [
  "Ahad",
  "Senin",
  "Selasa",
  "Rabu",
  "Kamis",
  "Jum'at",
  "Sabtu",
];

export const months_indo = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
];

const current_year = new Date().getFullYear()

function get_first_day_index(year, month) {
  return new Date(year, month, 1).getDay()
}

function get_last_day_index(year, month) {
  return new Date(year, month + 1, 0).getDay() + 1
}

export const months = [
  {
    no: 0,
    bulan: "Januari",
    jumlahHari: 31,
    start: get_first_day_index(current_year, 0),
    end: get_last_day_index(current_year, 0),
  },
  {
    no: 1,
    bulan: "Februari",
    jumlahHari: current_year % 4 === 0 ? 29 : 28,
    start: get_first_day_index(current_year, 1),
    end: get_last_day_index(current_year, 1),
  },
  {
    no: 2,
    bulan: "Maret",
    jumlahHari: 31,
    start: get_first_day_index(current_year, 2),
    end: get_last_day_index(current_year, 2),
  },
  {
    no: 3,
    bulan: "April",
    jumlahHari: 30,
    start: get_first_day_index(current_year, 3),
    end: get_last_day_index(current_year, 3),
  },
  {
    no: 4,
    bulan: "Mei",
    jumlahHari: 31,
    start: get_first_day_index(current_year, 4),
    end: get_last_day_index(current_year, 4),
  },
  {
    no: 5,
    bulan: "Juni",
    jumlahHari: 30,
    start: get_first_day_index(current_year, 5),
    end: get_last_day_index(current_year, 5),
  },
  {
    no: 6,
    bulan: "Juli",
    jumlahHari: 31,
    start: get_first_day_index(current_year, 6),
    end: get_last_day_index(current_year, 6),
  },
  {
    no: 7,
    bulan: "Agustus",
    jumlahHari: 31,
    start: get_first_day_index(current_year, 7),
    end: get_last_day_index(current_year, 7),
  },
  {
    no: 8,
    bulan: "September",
    jumlahHari: 30,
    start: get_first_day_index(current_year, 8),
    end: get_last_day_index(current_year, 8),
  },
  {
    no: 9,
    bulan: "Oktober",
    jumlahHari: 31,
    start: get_first_day_index(current_year, 9),
    end: get_last_day_index(current_year, 9),
  },
  {
    no: 10,
    bulan: "November",
    jumlahHari: 30,
    start: get_first_day_index(current_year, 10),
    end: get_last_day_index(current_year, 10),
  },
  {
    no: 11,
    bulan: "Desember",
    jumlahHari: 31,
    start: get_first_day_index(current_year, 11),
    end: get_last_day_index(current_year, 11),
  },
];