import React from "react";

class AboutusVision extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    return (

      <div className="w-10/12 self-center">
        <div className="flex flex-col md:flex-row justify-center items-start w-full gap-16 md:gap-32">
          <div className="w-full flex flex-col md:w-6/12 gap-12">
            <div className="flex flex-col">
              <h2 className="text-3xl md:text-5xl text-magnet-rose-normal leading-normal font-bold mb-3">Visi</h2>
              <p className="text-left text-base text-gray-700 leading-6">
                Mewujudkan Mata Garuda sebagai wadah pemberdayaan potensi alumni dalam memberi dampak kepada negeri.
              </p>
            </div>
            <div className="flex flex-col">
              <h2 className="text-3xl md:text-5xl text-magnet-rose-normal leading-normal font-bold mb-3">Misi</h2>
              <p className="text-left text-base text-gray-700 leading-6 mb-3">
                <span className="font-bold">Misi Penguatan Organisasi</span> <br /> Penguatan organisasi Mata Garuda Pusat dan peran Mata Garuda Daerah dengan memberikan dukungan infrastruktur dan logistik.
              </p>
              <p className="text-left text-base text-gray-700 leading-6 mb-3">
                <span className="font-bold">Misi Untuk Awardee </span> <br /> Bersinergi dalam menyalurkan potensi awardee di berbagai bidang.
              </p>
              <p className="text-left text-base text-gray-700 leading-6 mb-3">
                <span className="font-bold">Misi Untuk Bangsa dan Negara </span> <br /> Memberikan dampak positif bagi negeri melalui strategi dan program kerja yang pas dan berkelanjutan.
              </p>
            </div>
          </div>
          <div className="w-full flex flex-col md:w-6/12">
            <h2 className="text-magnet-rose-normal text-3xl md:text-5xl leading-normal font-bold  mb-3">Peran Magnet</h2>
            <div className="flex flex-col mb-7">
              <p className="text-zinc-700 text-lg leading-7 font-bold mb-2">Networking</p>
              <p className="text-left text-base leading-6 text-gray-700">
                Dengan jejaring 32.000+ awardee dan akses ke publik, Mata Garuda Network menjadi sarana para awardee untuk memperluas jaringan kolaborasi.
              </p>
            </div>
            <div className="flex flex-col mb-7">
              <p className="text-zinc-700 text-lg leading-7 font-bold mb-2">Career Development</p>
              <p className="text-left text-base leading-6 text-gray-700">
                Sarana pengembangan karir melalui program unggulan seperti mentoring, coaching, career searching and mapping.
              </p>
            </div>
            <div className="flex flex-col mb-7">
              <p className="text-zinc-700 text-lg leading-7 font-bold mb-2">Self Actualization</p>
              <p className="text-left text-base leading-6 text-gray-700">
                Menyediakan sarana untuk kontribusi pada sektor keminatan melalui <b><i>Mata Garuda Institute</i></b> . Berpartisipasi dalam kegiatan aksi sosial melalui program <strong><em>Massive Action</em></strong>, ataupun agenda kolaborasi lainnya.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutusVision;
