import React from 'react'
// import * as APP_DATA from '../../../ConstantData';
import { Icons } from '../../../entity/Icons';
import KontenPostBanner from '../banners/KontenPostBanner';


class KabarTerkini extends React.Component {
  render() {
    const {
      content
    } = this.props

    return (
      <div className='flex flex-col gap-2 w-full'>
        <div className='flex flex-row justify-between mt-32'>
          <div className='flex flex-col gap-2'>
            <p className='text-5xl font-extrabold text-magnet-black-normal'>Kabar Terkini</p>
            <p className='text-xl font-light text-magnet-black-light'>Konten terbaru seputar Informasi Kerja baik dalam maupun luar negeri.</p>
          </div>
        </div>
        <div className='flex xl:flex-row max-xl:flex-col gap-8 mt-4'>
          {content.map((item, i) => {
            return <KontenPostBanner
              key={i}
              img={item.img}
              author={item.author}
              title={item.title}
              content={item.content}
              date={item.date}
              time={item.time}
              url={item.url}
            />
          })}
        </div>
        <div className='flex justify-center mt-12'>
          <button className='bg-magnet-yellow-normal hover:bg-magnet-yellow-light duration-500 text-magnet-black-normal rounded-xl px-12 py-2 flex'>
            Lihat Selengkapnya
            <Icons.ANGLE_RIGHT />
          </button>
        </div>
      </div>
    )
  }
}

export default KabarTerkini