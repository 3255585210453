import React from 'react'
// import * as APP_DATA from '../../../ConstantData';
import { Icons } from '../../entity/Icons';
import './clean-select.css'


class NetworkSearchBar extends React.Component {
  render() {
    const {
      search_name,
      updateSearchName,
      doSearchName,
      categories,
      // category,
      updateSelectedCategory,
    } = this.props;

    return (
      <div className='flex flex-row md:gap-6 gap-2 items-center justify-between text-sm '>
        <div className='z-[10] w-8/12 bg-white rounded-full flex flex-row items-center justify-between'>
          <div className='px-4'>
            <Icons.SEARCH className='h-10 fill-magnet-primary' />
          </div>
          <input className='p-2 w-full text-sm focus:outline-none rounded-full' type='text' placeholder='Search by name here'
            value={search_name}
            onChange={(e) => updateSearchName(e.target.value)} />
          {search_name && <div className='px-4 cursor-pointer z-[10]'>
            <Icons.TIMES className='h-6 w-6 fill-magnet-gray hover:fill-magnet-primary bg-gray-100 rounded-full duration-500'
              onClick={() => updateSearchName('')} />
          </div>}
        </div>
        <div className='z-[9] flex w-2/12 px-4 py-0 border-2 border-white h-full rounded-full bg-white items-center justify-center text-center'>
          <select defaultValue={""} className='focus:outline-none text-magnet-black-normal text-center text-sm bg-transparent' name="expertise" id="expertise"
            onChange={(e) => updateSelectedCategory(e.target.value)}>
            <option value="" disabled>Filter by expertise</option>
            <option value="">SHOW ALL</option>
            {categories.sort().map(function ([expertise, count], i) {
              expertise = expertise.trim()
              return <option key={i} value={expertise}>{expertise} ({count})</option>
            })}
          </select>
        </div>
        <button className='z-[10] flex items-center justify-center md:w-2/12 w-3/12 bg-magnet-yellow-normal rounded-full py-2 pl-4 text-sm text-magnet-black-normal hover:bg-magnet-yellow-light duration-500'
          onClick={() => doSearchName()}>
          <span>Search</span>
          <Icons.ANGLE_RIGHT className='fill-magnet-black-normal' />
        </button>
      </div>
    )
  }
}

export default NetworkSearchBar