import React from "react";

class Copyright extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="-mx-80 -mt-4 py-4 px-4 gap-6 bg-magnet-secondary items-end content-end text-sm font-medium text-white text-center">
        Copyright © 2022 MAGNET
      </div>
    );
  }
}

export default Copyright;
