import React from "react";
// import * as APP_DATA from '../../../ConstantData';
import { Icons } from "../../../entity/Icons";
import { MitraImage } from "../../../entity/MitraImage";

class SectionGaleri extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="flex flex-col  gap-2 mt-8">
        <div className="flex flex-row justify-between">
          <div className="flex items-center gap-2">
            <div className="">
              <div className="flex justify-center items-center w-8 h-8 bg-magnet-secondary fill-magnet-primarylight rounded-full">
                <Icons.IMAGE_PLUS className="h-4" />
              </div>
            </div>
            <p className="text-2xl font-semibold text-magnet-secondarydark">
              Galeri MAGNET
            </p>
          </div>
          <div className="flex flex-row items-center">
            <p className="font-semibold text-magnet-secondary">
              Pelajari Selengkapnya
            </p>
            <Icons.ANGLE_RIGHT className="fill-magnet-secondary h-4" />
          </div>
        </div>
        <div>
          <p>
            Konten terbaru seputar Informasi Kerja baik dalam maupun luar
            negeri.
          </p>
        </div>
        <div className="flex flex-row gap-6 mt-4">
          <div className="flex flex-col w-1/3 gap-3">
            <img className="w-full" alt="" src={MitraImage.galeri4}></img>
          </div>
          <div className="flex flex-col w-1/3 gap-3">
            <img className="w-full" alt="" src={MitraImage.galeri5}></img>
          </div>
          <div className="flex flex-col w-1/3 gap-3">
            <img className="w-full" alt="" src={MitraImage.galeri6}></img>
          </div>
        </div>
        <div className="flex flex-row gap-6 mt-4">
          <div className="flex flex-col w-1/3 gap-3">
            <img className="w-full" alt="" src={MitraImage.galeri7}></img>
          </div>
          <div className="flex flex-col w-1/3 gap-3">
            <img className="w-full" alt="" src={MitraImage.galeri8}></img>
          </div>
          <div className="flex flex-col w-1/3 gap-3">
            <img className="w-full" alt="" src={MitraImage.galeri9}></img>
          </div>
        </div>
        <div className="flex flex-row gap-6 mt-4">
          <div className="flex flex-col w-1/3 gap-3">
            <img className="w-full" alt="" src={MitraImage.galeri10}></img>
          </div>
          <div className="flex flex-col w-1/3 gap-3">
            <img className="w-full" alt="" src={MitraImage.galeri11}></img>
          </div>
          <div className="flex flex-col w-1/3 gap-3">
            <img className="w-full" alt="" src={MitraImage.galeri12}></img>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionGaleri;
