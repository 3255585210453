import React from "react";
import CallforbookContent from "./sections/CallforbookContent";

class CallforbookView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="flex flex-col items-center font-poppins">
        <div className="w-10/12">
          <div className="flex flex-col gap-8 mt-10">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="text-4xl font-semibold text-center text-magnet-primary">Call for Book Chapters</h2>
              <div className="flex flex-col font-semibold text-center gap-1">
                <p className="text-lg">
                  Seri Buku “Skema Pembiayaan Inovatif untuk Pembangunan Indonesia”
                </p>
                <p className="text-center text-base font-light">
                  Kerjasama antara PT Penjaminan Infrastruktur Indonesia (Persero) dengan Mata Garuda LPDP
                </p>
                <p className="text-lg">IIGF Institute dan Mata Garuda Institute </p>
              </div>
            </div>
            <CallforbookContent />
          </div>
        </div>
      </div>
    );
  }
}

export default CallforbookView;
