import { ReactComponent as airplay } from "../../image/icons/airplay/airplay.svg";
import { ReactComponent as angle_down } from "../../image/icons/angle-down/angle-down.svg";
import { ReactComponent as angle_left } from "../../image/icons/angle-left/angle-left.svg";
import { ReactComponent as angle_right } from "../../image/icons/angle-right/angle-right.svg";
import { ReactComponent as angle_up } from "../../image/icons/angle-up/angle-up.svg";
import { ReactComponent as arrow_growth } from "../../image/icons/arrow-growth/arrow-growth.svg";
import { ReactComponent as arrow_left } from "../../image/icons/arrow-left/arrow-left.svg";
import { ReactComponent as arrow_right } from "../../image/icons/arrow-right/arrow-right.svg";
import { ReactComponent as badge } from "../../image/icons/badge/badge.svg";
import { ReactComponent as bolt } from "../../image/icons/bolt/bolt.svg";
import { ReactComponent as building } from "../../image/icons/building/building.svg";
import { ReactComponent as calendar_alt } from "../../image/icons/calendar-alt/calendar-alt.svg";
import { ReactComponent as cell } from "../../image/icons/cell/cell.svg";
import { ReactComponent as check } from "../../image/icons/check/check.svg";
import { ReactComponent as circle } from "../../image/icons/circle/circle.svg";
import { ReactComponent as circle_long } from "../../image/icons/circle-long/circle-long.svg";
import { ReactComponent as circle_long_horizontal } from "../../image/icons/circle-long-horizontal/circle-long-horizontal.svg";
import { ReactComponent as clipboard_notes } from "../../image/icons/clipboard-notes/clipboard-notes.svg";
import { ReactComponent as clock } from "../../image/icons/clock/clock.svg";
import { ReactComponent as cloud } from "../../image/icons/cloud/cloud.svg";
import { ReactComponent as cloud_drizzle } from "../../image/icons/cloud-drizzle/cloud-drizzle.svg";
import { ReactComponent as cloud_sun } from "../../image/icons/cloud-sun/cloud-sun.svg";
import { ReactComponent as clouds } from "../../image/icons/clouds/clouds.svg";
import { ReactComponent as diary } from "../../image/icons/diary/diary.svg";
import { ReactComponent as download_alt } from "../../image/icons/download-alt/download-alt.svg";
import { ReactComponent as english_to_chinese } from "../../image/icons/english-to-chinese/english-to-chinese.svg";
import { ReactComponent as estate } from "../../image/icons/estate/estate.svg";
import { ReactComponent as eye } from "../../image/icons/eye/eye.svg";
import { ReactComponent as eye_slash } from "../../image/icons/eye-slash/eye-slash.svg";
import { ReactComponent as facebook } from "../../image/icons/facebook/facebook.svg";
import { ReactComponent as file_check } from "../../image/icons/file-check/file-check.svg";
import { ReactComponent as globe } from "../../image/icons/globe/globe.svg";
import { ReactComponent as google } from "../../image/icons/google/google.svg";
import { ReactComponent as head_side } from "../../image/icons/head-side/head-side.svg";
import { ReactComponent as image_plus } from "../../image/icons/image-plus/image-plus.svg";
import { ReactComponent as instagram } from "../../image/icons/instagram/instagram.svg";
import { ReactComponent as invoice } from "../../image/icons/invoice/invoice.svg";
import { ReactComponent as linkedin } from "../../image/icons/linkedin/linkedin.svg";
import { ReactComponent as lock } from "../../image/icons/lock/lock.svg";
import { ReactComponent as map } from "../../image/icons/map/map.svg";
import { ReactComponent as map_marker } from "../../image/icons/map-marker/map-marker.svg";
import { ReactComponent as meeting_board } from "../../image/icons/meeting-board/meeting-board.svg";
import { ReactComponent as mobile_android } from "../../image/icons/mobile-android/mobile-android.svg";
import { ReactComponent as newspaper } from "../../image/icons/newspaper/newspaper.svg";
import { ReactComponent as question_circle } from "../../image/icons/question-circle/question-circle.svg";
import { ReactComponent as search } from "../../image/icons/search/search.svg";
import { ReactComponent as star } from "../../image/icons/star/star.svg";
import { ReactComponent as star_half_alt } from "../../image/icons/star-half-alt/star-half-alt.svg";
import { ReactComponent as suitcase_alt } from "../../image/icons/suitcase-alt/suitcase-alt.svg";
import { ReactComponent as thumbs_up } from "../../image/icons/thumbs-up/thumbs-up.svg";
import { ReactComponent as times } from "../../image/icons/times/times.svg";
import { ReactComponent as trash_alt } from "../../image/icons/trash-alt/trash-alt.svg";
import { ReactComponent as trees } from "../../image/icons/trees/trees.svg";
import { ReactComponent as twitter } from "../../image/icons/twitter/twitter.svg";
import { ReactComponent as user } from "../../image/icons/user/user.svg";
import { ReactComponent as whatsapp } from "../../image/icons/whatsapp/whatsapp.svg";
import { ReactComponent as youtube } from "../../image/icons/youtube/youtube.svg";
import { ReactComponent as trending_up } from "../../image/icons/trending-up/trending-up.svg";
import { ReactComponent as light_bulb } from "../../image/icons/light-bulb/light-bulb.svg";
import { ReactComponent as user_group } from "../../image/icons/user-group/user-group.svg";
import { ReactComponent as envelope } from "../../image/icons/envelope/envelope.svg";
import { ReactComponent as share } from "../../image/icons/share/share.svg";
import { ReactComponent as magnet_facebook } from "../../image/icons/magnet-facebook/magnet-facebook.svg";
import { ReactComponent as magnet_whatsapp } from "../../image/icons/magnet-whatsapp/magnet-whatsapp.svg";
import { ReactComponent as magnet_twitter } from "../../image/icons/magnet-twitter/magnet-twitter.svg";
import { ReactComponent as magnet_instagram } from "../../image/icons/magnet-instagram/magnet-instagram.svg";
import { ReactComponent as magnet_linkedin } from "../../image/icons/magnet-linkedin/magnet-linkedin.svg";
import { ReactComponent as logout } from "../../image/icons/logout/logout.svg";

export const Icons = {
  AIRPLAY: airplay,
  ANGLE_DOWN: angle_down,
  ANGLE_LEFT: angle_left,
  ANGLE_RIGHT: angle_right,
  ANGLE_UP: angle_up,
  ARROW_GROWTH: arrow_growth,
  ARROW_LEFT: arrow_left,
  ARROW_RIGHT: arrow_right,
  BADGE: badge,
  BOLT: bolt,
  BUILDING: building,
  CALENDAR_ALT: calendar_alt,
  CELL: cell,
  CHECK: check,
  CIRCLE_LONG_HORIZONTAL: circle_long_horizontal,
  CIRCLE_LONG: circle_long,
  CIRCLE: circle,
  CLIPBOARD_NOTES: clipboard_notes,
  CLOCK: clock,
  CLOUD_DRIZZLE: cloud_drizzle,
  CLOUD_SUN: cloud_sun,
  CLOUD: cloud,
  CLOUDS: clouds,
  DIARY: diary,
  DOWNLOAD_ALT: download_alt,
  ENGLISH_TO_CHINESE: english_to_chinese,
  ESTATE: estate,
  EYE_SLASH: eye_slash,
  EYE: eye,
  FACEBOOK: facebook,
  FILE_CHECK: file_check,
  GLOBE: globe,
  GOOGLE: google,
  HEAD_SIDE: head_side,
  IMAGE_PLUS: image_plus,
  INSTAGRAM: instagram,
  INVOICE: invoice,
  LINKEDIN: linkedin,
  LOCK: lock,
  MAP_MARKER: map_marker,
  MAP: map,
  MEETING_BOARD: meeting_board,
  MOBILE_ANDROID: mobile_android,
  NEWSPAPER: newspaper,
  QUESTION_CIRCLE: question_circle,
  SEARCH: search,
  STAR_HALF_ALT: star_half_alt,
  STAR: star,
  SUITCASE_ALT: suitcase_alt,
  THUMBS_UP: thumbs_up,
  TIMES: times,
  TRASH_ALT: trash_alt,
  TREES: trees,
  TWITTER: twitter,
  USER: user,
  WHATSAPP: whatsapp,
  YOUTUBE: youtube,
  TRENDING_UP: trending_up,
  LIGHT_BULB: light_bulb,
  USER_GROUP: user_group,
  ENVELOPE: envelope,
  SHARE: share,
  MAGNET_FACEBOOK: magnet_facebook,
  MAGNET_WHATSAPP: magnet_whatsapp,
  MAGNET_TWITTER: magnet_twitter,
  MAGNET_INSTAGRAM: magnet_instagram,
  MAGNET_LINKEDIN: magnet_linkedin,
  LOGOUT: logout,
};
