import React from "react";

import { Page } from "../../../entity/Pages";

class SectionDaftarSekarang extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { navigator } = this.props;

    return (
      <div className="flex flex-col gap-8 mt-16 bg-magnet-secondarylight justify-center w-screen">
        <div className="flex w-1/12 gap-2 ">
          <br />
        </div>
        <div className=" flex flex-row justify-center mx-48 rounded-4xl gap-6 mb-16">
          <p className=" text-3xl font-semibold text-left mr-4">
            Tunggu apalagi, segera daftar menjadi mitra sekarang!
          </p>
          <div onClick={() => navigator(Page.REGISTERMITRA)} className="cursor-pointer bg-magnet-secondary text-white font-semibold text-lg rounded-3xl pt-4 px-16 h-16 ">
            Bergabung
          </div>
        </div>
      </div>
    );
  }
}

export default SectionDaftarSekarang;
