import React from "react";
import BussinessContent from "./sections/BussinessContent";
import * as APP_DATA from '../../../core/ConstantData';

class BussinessView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="flex flex-col items-center font-poppins">
        <div className="w-10/12">
          <div className="flex flex-col gap-8 mt-10">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="text-4xl font-bold text-center text-magnet-rose-normal">Business Competition</h2>
              <div className="flex flex-col mt-4">
                <h3 className="text-2xl font-bold text-left">
                  Pendaftaran 5ME2045 2024
                </h3>
                <img className="w-3/12 mt-8" src={APP_DATA.LOGO_BUSINESS_COMPETITION}></img>
              </div>
            </div>
            <BussinessContent />
          </div>
        </div>
      </div>
    );
  }
}

export default BussinessView;
