import React from "react";
import IsiBerita from "./sections/IsiBerita";

class BeritaView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="flex flex-col items-center font-poppins">
        <div className="w-10/12">
          <div className="flex flex-col gap-8 mt-10">
            <div className="flex flex-col gap-4">
              <p className="text-4xl font-semibold">Temukan karir impianmu</p>
              <p className="text-lg font-light">
                Jelajahi beragam peluang terbaik untuk masa depanmu!
              </p>
            </div>
            <IsiBerita />
          </div>
        </div>
      </div>
    );
  }
}

export default BeritaView;
