import React from "react";
import * as APP_DATA from "../../../core/ConstantData";
import { Icons } from "../../entity/Icons";
import { Page } from "../../entity/Pages";

class HeaderRegisterMitraView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { navigator } = this.props;

    return (
      <React.Fragment>
        <div className="flex flex-row justify-between px-8 py-4 font-poppins">
          <div onClick={() => navigator(Page.LOGINMITRA)} className="ml-20 flex flex-row items-center gap-4 text-xl font-bold">
            MITRA
          </div>
          <div className=" flex flex-row items-center gap-4">
            <div className="flex gap-2 cursor-pointer hover:bg-gray-100 duration-500 p-2 rounded-lg fill-black">
              <img
                alt=""
                src={APP_DATA.LOGO_MAGNET}
                className="cursor-pointer"
                onClick={() => navigator(Page.MITRA)}
              ></img>
            </div>
          </div>

          <div
            id="right-menu"
            className="mr-20 flex flex-row items-center gap-6"
          >
            <div className="flex flex-row">
              <Icons.ESTATE onClick={() => navigator(Page.MITRA)} className="fill-magnet-secondary h-8 w-8" />
              <Icons.QUESTION_CIRCLE className="fill-magnet-secondary h-8 w-8" />
            </div>
            <div></div>
          </div>
        </div>
        <hr className="bg-gray-200" />
      </React.Fragment>
    );
  }
}

export default HeaderRegisterMitraView;
