import React from 'react'
import { Icons } from '../../entity/Icons'
// import * as APP_DATA from '../../ConstantData';
import { Page } from '../../entity/Pages';
import img_tl from '../../../image/banner-image/tl.png'
import img_tr from '../../../image/banner-image/tr.png'
import img_bl from '../../../image/banner-image/bl.png'
import img_br from '../../../image/banner-image/br.png'
import magnet_logo from '../../../image/new-logo.png'
import { ReactComponent as BgTiles } from '../../../image/bg-tiles.svg'


class LoginView extends React.Component {
  render() {
    const {
      navigator,
      profile_data,
      profileUpdate,
      callAPILogin,
      error_messages,
      is_login_on_process,
      password_field_type,
      switchPasswordType,
    } = this.props;

    return (
      <div className='z-[0] relative flex flex-col items-center justify-center font-poppins bg-gradient-to-tr from-magnet-rose-normal to-magnet-orange-normal h-screen overflow-hidden'>
        <BgTiles className='z-[1] opacity-10 absolute flex top-0 overflow-hidden' />
        <div className='z-[2] md:w-8/12 w-11/12 flex flex-col justify-center h-screen'>
          <div className='flex flex-row justify-center items-center'>
            <div className='md:w-1/2 md:flex hidden flex-col justify-center items-center gap-8'>
              <div className='flex flex-col gap-4 justify-center items-end'>
                <div className='flex gap-4 items-center justify-center'>
                  <img src={img_tl} className='h-32 md:h-52' alt=''></img>
                  <img src={img_tr} className='h-32 md:h-52' alt=''></img>
                </div>
                <div className='flex gap-4'>
                  <img src={img_bl} className='h-32 md:h-56' alt=''></img>
                  <img src={img_br} className='h-32 md:h-56' alt=''></img>
                </div>
              </div>
              <p className='text-white text-2xl md:w-3/4 text-center'>Jejaring Sosial Bagi Para Alumni & Awardee LPDP</p>
            </div>
            <div className='md:w-1/2 flex flex-col justify-between md:ml-10 md:px-20 px-8 md:pt-16 pt-8 pb-4 gap-16 bg-white rounded-3xl'>
              <img alt='' src={magnet_logo} className='w-40 cursor-pointer'
                onClick={() => navigator(Page.HOME)}
              ></img>

              <div className='flex flex-col gap-2'>
                <div>
                  <p className='text-lg md:text-3xl text-gray-700 font-semibold text-center md:text-left md:mt-0 mt-4'>
                    Login
                  </p>
                  <p className='text-gray-700 text-center md:text-left mb-4 md:mt-0 mt-4'>
                    Silahkan login untuk melanjutkan
                  </p>
                </div>
                <div>
                  <div className='flex items-center justify-center w-full border border-magnet-black-light rounded-lg'>
                    <Icons.USER className='ml-2 h-4 fill-magnet-black-light' />
                    <input
                      id='email'
                      className='p-2 w-full text-sm mr-2 focus:outline-none'
                      type='text'
                      placeholder='Email'
                      value={profile_data.Email}
                      onChange={(e) => profileUpdate({ 'Email': e.target.value })}
                    />
                  </div>
                </div>
                <div>
                  <div className='flex items-center justify-center w-full border border-magnet-black-light rounded-lg'>
                    <Icons.LOCK className='ml-2 h-4 fill-magnet-black-light' />
                    <input
                      id='password'
                      className='p-2 w-full text-sm mr-2 focus:outline-none'
                      type={password_field_type}
                      placeholder='Password'
                      value={profile_data.Password}
                      onChange={(e) => profileUpdate({ 'Password': e.target.value })}
                    />
                    <div className='px-1'>
                      {password_field_type === 'password'
                        ? <Icons.EYE className='ml-2 h-4 fill-magnet-black-light hover:fill-magnet-rose-normal duration-500 cursor-pointer' onClick={() => switchPasswordType()} />
                        : <Icons.EYE_SLASH className='ml-2 h-4 fill-magnet-black-light hover:fill-magnet-rose-normal duration-500 cursor-pointer' onClick={() => switchPasswordType()} />}
                    </div>
                  </div>
                </div>
                <div className='text-sm flex justify-between py-2'>
                  <div className='flex'>
                    <input type={'checkbox'}></input>
                    <p className='ml-2'>Ingat Saya</p>
                  </div>
                  <div>
                    <button className='text-magnet-rose-normal' onClick={() => navigator(Page.FORGET_PASSWORD)}>Lupa password?</button>
                  </div>
                </div>
                <div className='flex flex-col gap-1'>
                  {error_messages.map(function (msg, i) {
                    return <p className='text-magnet-secondary text-xs' key={i}>{msg}</p>
                  })}
                </div>
                <div className='text-center'>
                  {is_login_on_process ?
                    <button className='px-4 py-2 w-full rounded-xl font-medium border-2 border-gray-500  bg-gray-500  text-white duration-500 fill-white '>
                      Log in
                    </button>
                    : <button className='px-4 py-2 w-full rounded-xl font-medium border-2 border-magnet-green-normal hover:bg-white bg-magnet-green-normal hover:text-magnet-green-normal text-white duration-500 fill-white hover:fill-magnet-rose-normal'
                      onClick={() => callAPILogin()}>
                      Log in
                    </button>}
                </div>
              </div>

              <div className='flex flex-col justify-center items-center'>
                <p className='text-xs font-light text-gray-500 my-4 '>atau </p>
                <p className='text-sm'>
                  Belum punya akun? Silahkan <span className='font-semibold text-magnet-rose-normal hover:text-magnet-rose-dark cursor-pointer' onClick={() => navigator(Page.REGISTER)}>daftar di sini</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LoginView