import React from 'react'
import img_tl from '../../../../image/banner-image/tl.png'
import img_tr from '../../../../image/banner-image/tr.png'
import img_bl from '../../../../image/banner-image/bl.png'
import img_br from '../../../../image/banner-image/br.png'
import { Icons } from '../../../entity/Icons'
import { Page } from '../../../entity/Pages'


class MainBanner extends React.Component {
  render() {
    const {
      navigator,
      is_logged_in,
    } = this.props
    return (
      <div className='relative w-full bg-gradient-to-r from-magnet-rose-normal to-magnet-orange-normal rounded-3xl'>
        {/* <div className='absolute left-[-25px] top-[17vw]'>
          <div className='w-12 h-12 bg-white bg-opacity-75 hover:bg-opacity-100 duration-500 rounded-full flex justify-center items-center cursor-pointer'>
            <Icons.ANGLE_LEFT className='w-12 h-12 pr-1 fill-magnet-rose-normal' />
          </div>
        </div>
        <div className='absolute right-[-29px] top-[17vw]'>
          <div className='w-12 h-12 bg-white bg-opacity-75 hover:bg-opacity-100 duration-500 rounded-full flex justify-center items-center cursor-pointer'>
            <Icons.ANGLE_RIGHT className='w-12 h-12 pr-1 fill-magnet-rose-normal' />
          </div>
        </div> */}
        <div className='flex flex-col items-center'>
          <div className='w-10/12 py-24 text-white '>
            <div className='md:grid md:grid-cols-2 flex flex-col-reverse md:gap-0 gap-4'>
              <div className='flex flex-col md:gap-6 gap-4'>
                <p className="font-inter md:text-[16px] font-normal opacity-75">
                  Mata Garuda Network (MAGNET) adalah <br />Platform Resmi Ikatan Alumni Penerima Beasiswa LPDP
                </p>
                <p className="text-3xl md:text-5xl font-extrabold">Jejaring Sosial Bagi Para Alumni & Awardee</p>
                <p className="text-lg md:text-2xl font-light opacity-75">
                  Membuka peluang <b>Keluarga LPDP</b> untuk saling <b>Berkomunikasi dan Berkolaborasi!</b>
                </p>
                <div className='flex items-center justify-center'>
                  <button className="px-6 py-2 md:py-6 md:px-24 mt-12 bg-magnet-yellow-normal hover:bg-magnet-yellow-light duration-500 rounded-3xl shadow-lg justify-center items-center inline-flex">
                    {is_logged_in
                      ? <div className="text-magnet-black-normal md:text-lg md:font-semibold"
                        onClick={() => navigator(Page.NETWORK)}>Menuju Network!</div>
                      : <div className="text-magnet-black-normal md:text-lg md:font-semibold"
                        onClick={() => navigator(Page.REGISTER)}>Bergabung Sekarang!</div>}
                  </button>
                </div>
              </div>
              <div className='flex flex-col gap-4 justify-center md:items-end items-center'>
                <div className='flex gap-4 items-center justify-center'>
                  <img src={img_tl} className='h-32 md:h-52' alt=''></img>
                  <img src={img_tr} className='h-32 md:h-52' alt=''></img>
                </div>
                <div className='flex gap-4'>
                  <img src={img_bl} className='h-32 md:h-56' alt=''></img>
                  <img src={img_br} className='h-32 md:h-56' alt=''></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-row gap-4 justify-center items-center mb-4'>
          <Icons.CIRCLE_LONG_HORIZONTAL className='fill-white cursor-pointer' />
          <Icons.CIRCLE className='fill-white opacity-50 hover:opacity-100 duration-500 cursor-pointer' />
          <Icons.CIRCLE className='fill-white opacity-50 hover:opacity-100 duration-500 cursor-pointer' />
          <Icons.CIRCLE className='fill-white opacity-50 hover:opacity-100 duration-500 cursor-pointer' />
        </div>
      </div>
    )
  }
}

export default MainBanner