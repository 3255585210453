import { Icons } from '../../../entity/Icons'
import { KeunggulanImage } from '../../../entity/KeunggulanImage';
import { Page } from '../../../entity/Pages';
import KeunggulanBanner from '../banners/KeunggulanBanner';
import React from 'react'


class Keunggulan extends React.Component {
  constructor(props) {
    super(props)
    this.updateActiveBannerId = this.updateActiveBannerId.bind(this)
    this.interval_id = false
    this.state = {
      active_banner_id: 0,
    }
  }

  componentDidMount() {
    if (!this.interval_id) {
      var self = this
      this.interval_id = window.setInterval(function () { self.updateActiveBannerId((self.state.active_banner_id + 1) % 4) }, 5000)
    }
  }

  componentWillUnmount() {
  }

  updateActiveBannerId(id) {
    this.setState({ active_banner_id: id })
  }

  render() {
    const { navigator } = this.props

    const semua_keunggulan = [
      <KeunggulanBanner
        image={KeunggulanImage.TERPERCAYA}
        title={'Terpercaya'}
        content={'Kami, sebagai pihak yang bertanggung jawab dalam penyaluran beasiswa LPDP, memastikan bahwa seluruh pengguna beasiswa tersebut adalah para Awardee LPDP yang telah memenuhi persyaratan yang telah ditetapkan. Sebagai lembaga yang berkomitmen untuk memberikan peluang pendidikan yang lebih baik bagi para pemuda-pemudi Indonesia, LPDP selalu mengutamakan kualitas dan integritas dalam proses seleksi calon penerima beasiswa.'}
      />,
      <KeunggulanBanner
        image={KeunggulanImage.RESMI}
        title={'Resmi'}
        content={'Mata Garuda Network adalah platform resmi dari Mata Garuda yang berada di bawah lembaga LPDP'}
      />,
      <KeunggulanBanner
        image={KeunggulanImage.KOMUNIKASI_CEPAT}
        title={'Komunikasi Cepat'}
        content={'Mencari rekan satu hobi maupun satu profesi'}
      />,
      <KeunggulanBanner
        image={KeunggulanImage.JARINGAN_LUAS}
        title={'Jaringan Luas'}
        content={'Terbuka peluang untuk membangun jaringan seluas-luasnya'}
      />,
    ]
    var self = this

    return (
      <div className='w-full flex md:flex-row flex-col my-16 gap-4'>
        <div className='flex flex-col gap-4 md:w-4/12 text-center px-12 justify-center'>
          <h1 className=' text-3xl md:text-5xl uppercase font-bold'>KEUNGGULAN</h1>
          <p className='md:text-xl opacity-50'>Mata Garuda Network berkomitmen untuk melayani dengan sepenuh hati</p>
          <button className='bg-magnet-yellow-normal flex items-center justify-center py-3 rounded-2xl md:mt-8'
            onClick={() => navigator(Page.ABOUTUS)}>
            Pelajari Selengkapnya
            <Icons.ANGLE_RIGHT />
          </button>
        </div>

        <div className='md:w-7/12'>
          {semua_keunggulan[self.state.active_banner_id]}
        </div>

        <div className='w-1/12 md:flex hidden flex-col gap-2 justify-center items-center'>
          {
            semua_keunggulan.map(function (data, i) {
              if (i === self.state.active_banner_id) {
                return <Icons.CIRCLE_LONG
                  key={i}
                  onClick={() => self.updateActiveBannerId(i)}
                  className='fill-magnet-yellow-normal cursor-pointer' />
              } else {
                return <Icons.CIRCLE
                  key={i}
                  onClick={() => self.updateActiveBannerId(i)}
                  className='fill-magnet-yellow-normal opacity-50 hover:opacity-100 duration-500 cursor-pointer' />
              }
            })
          }
        </div>

        <div className='flex md:hidden flex-row gap-4 justify-center items-center mb-4'>
          {
            semua_keunggulan.map(function (data, i) {
              if (i === self.state.active_banner_id) {
                return <Icons.CIRCLE_LONG_HORIZONTAL
                  key={i}
                  onClick={() => self.updateActiveBannerId(i)}
                  className='fill-magnet-yellow-normal cursor-pointer' />
              } else {
                return <Icons.CIRCLE
                  key={i}
                  onClick={() => self.updateActiveBannerId(i)}
                  className='fill-magnet-yellow-normal opacity-50 hover:opacity-100 duration-500 cursor-pointer' />
              }
            })
          }
        </div>
      </div>
    )
  }
}

export default Keunggulan