import galeri0 from "../../image/mitra/galeri0.png";
import galeri1 from "../../image/mitra/galeri1.png";
import galeri2 from "../../image/mitra/galeri2.png";
import galeri3 from "../../image/mitra/galeri3.png";
import galeri4 from "../../image/mitra/galeri4.png";
import galeri5 from "../../image/mitra/galeri5.png";
import galeri6 from "../../image/mitra/galeri6.png";
import galeri7 from "../../image/mitra/galeri7.png";
import galeri8 from "../../image/mitra/galeri8.png";
import galeri9 from "../../image/mitra/galeri9.png";
import galeri10 from "../../image/mitra/galeri10.png";
import galeri11 from "../../image/mitra/galeri11.png";
import galeri12 from "../../image/mitra/galeri12.png";
import logo4 from "../../image/mitra/image 43.png";
import logo5 from "../../image/mitra/image 44.png";
import logo1 from "../../image/mitra/image 45.png";
import logo7 from "../../image/mitra/image 46.png";
import logo6 from "../../image/mitra/image 49.png";
import logo3 from "../../image/mitra/image 51.png";
import logo2 from "../../image/mitra/image 52.png";
import logo8 from "../../image/mitra/image 25.png";
import img1 from "../../image/mitra/Img.svg";
import img2 from "../../image/mitra/img2.png";
import img3 from "../../image/mitra/img3.png";
import img4 from "../../image/mitra/img4.png";
import indonesia from "../../image/mitra/indonesia.svg";
import mitra1 from "../../image/mitra/tokopedia.png";
import mitra2 from "../../image/mitra/lifepal.png";
import mitra3 from "../../image/mitra/bukalapak.png";
import mitra4 from "../../image/mitra/lifepack.png";
import mitra5 from "../../image/mitra/kitabisa.png";
import mitra6 from "../../image/mitra/amartha.png";
import mitra7 from "../../image/mitra/grab.png";
import PP1 from "../../image/mitra/PP.png";
import PP2 from "../../image/mitra/PP2.png";
import thumbnail1 from "../../image/mitra/Rec.png";
import thumbnail2 from "../../image/mitra/Rec2.png";
import thumbnail3 from "../../image/mitra/Rec3.png";
import ico1 from "../../image/mitra/Ico.png";
import ico2 from "../../image/mitra/Ico2.png";
import ico3 from "../../image/mitra/Ico3.png";
import vector1 from "../../image/mitra/LOGO-SQ.svg";
import logomatagaruda from "../../image/mitra/logo-matagaruda.png";
import logopii from "../../image/mitra/logo-pt-pii.png";
import logoiigf from "../../image/mitra/logo-iigf-institute.png";

import vector4 from "../../image/mitra/Vector4.svg";
import { ReactComponent as vector4svg } from "../../image/mitra/Vector4.svg";
import vector3 from "../../image/mitra/LOGO-SQ2.svg";
import vector2 from "../../image/mitra/Vector2.png";
import graph from "../../image/mitra/Graph.png";

import undraw from "../../image/mitra/undraw.svg";
import logored from "../../image/mitra/LOGORED.svg";

export const MitraImage = {
  galeri0: galeri0,
  galeri1: galeri1,
  galeri2: galeri2,
  galeri3: galeri3,
  galeri4: galeri4,
  galeri5: galeri5,
  galeri6: galeri6,
  galeri7: galeri7,
  galeri8: galeri8,
  galeri9: galeri9,
  galeri10: galeri10,
  galeri11: galeri11,

  galeri12: galeri12,
  logo1: logo1,
  logo2: logo2,
  logo3: logo3,
  logo4: logo4,
  logo5: logo5,
  logo6: logo6,
  logo7: logo7,
  logo8: logo8,
  img1: img1,
  img2: img2,
  img3: img3,
  img4: img4,
  indonesia: indonesia,
  mitra1: mitra1,
  mitra2: mitra2,
  mitra3: mitra3,
  mitra4: mitra4,
  mitra5: mitra5,
  mitra6: mitra6,
  mitra7: mitra7,
  PP1: PP1,
  PP2: PP2,
  thumbnail1: thumbnail1,
  thumbnail2: thumbnail2,
  thumbnail3: thumbnail3,
  ico1: ico1,
  ico2: ico2,
  ico3: ico3,
  vector1: vector1,
  vector2: vector2,
  vector3: vector3,
  vector4: vector4,
  MataGarudaLogograph: vector4svg,
  graph: graph,
  logored: logored,
  undraw: undraw,
  logomatagaruda : logomatagaruda,
  logopii : logopii,
  logoiigf : logoiigf
};
