import * as APP_DATA from '../../ConstantData';
import UserProfileView from './UserProfileView';
import React from 'react'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom';
import { read_email, read_jwt, read_username } from '../../utils/JwtToken';
import axios from 'axios';
import { API } from '../../entity/API';
import UserProfileEdit from './UserProfileEdit';


class UserProfileController extends React.Component {
  constructor(props) {
    super(props)
    this.read_login_username = read_username.bind(this)
    this.read_login_email = read_email.bind(this)
    this.read_jwt = read_jwt.bind(this)

    this.profileUpdate = this.profileUpdate.bind(this)
    this.switchEditing = this.switchEditing.bind(this)
    this.onSelectImage = this.onSelectImage.bind(this)
    this.onSelectCV = this.onSelectCV.bind(this)

    this.callAPIUserPost = this.callAPIUserPost.bind(this)

    this.large_image_message = 'Ukuran gambar lebih besar dari 1 MB'

    this.state = {
      is_editing: false,
      username: '',
      is_own_profile: false,
      error_messages: [],
      is_saving_on_process: false,
      progress: 0,
    }
  }

  componentDidMount() {
    const username = this.check_current_view_username()
    this.callAPIUserGet(username)
  }

  check_current_view_username() {
    const u = window.location.pathname.split('/')[2]
    if (this.state.username !== u) {
      this.setState({
        username: u,
        is_own_profile: u === this.read_login_username()
      })
    }
    return u
  }

  profileUpdate(key_val) {
    this.props.profileModel.update(key_val)
  }

  switchEditing(val) {
    this.setState({ is_editing: val })
  }

  createNullMessage(field) {
    return 'Bagian ' + field + ' harus diisi.'
  }

  removeValInArray(val, arr) {
    if (arr.includes(val)) {
      var index = arr.indexOf(val);
      if (index !== -1) {
        arr.splice(index, 1);
      }
    }
    return arr
  }

  onSelectImage(e) {
    if (!e.target.files || e.target.files.length === 0) {
      return
    } else {
      let err = this.state.error_messages
      err = this.removeValInArray(this.large_image_message, err)

      if (e.target.files && e.target.files[0].size > 1024000) {
        // file larger than 1 MB
        err.push(this.large_image_message)
        this.setState({ error_messages: err })
      } else {
        this.setState({ error_messages: err })

        const selectedFile = e.target.files[0]
        const objectUrl = URL.createObjectURL(selectedFile)
        this.props.profileModel.update({ 'Picture': objectUrl })
      }
    }
  }

  onSelectCV(e) {
    if (!e.target.files || e.target.files.length === 0) {
      // no file
      return
    } else {
      const selectedFile = e.target.files[0]
      const objectUrl = URL.createObjectURL(selectedFile)
      this.props.profileModel.update({ 'CV': objectUrl })
    }
  }

  async callAPIUserGet(username) {
    const self = this
    try {
      const response = await axios.get(
        API.GET_USER_PROFILE,
        {
          headers: {
            'Authorization': `Bearer ${self.read_jwt()}`,
          },
          params: {
            username: username
          }
        });
      // console.log(response.data)
      if (response.data.status === 'OK') {
        self.profileUpdate(response.data.user)
        self.profileUpdate({ 'Email': self.read_login_email() })
      } else {
      }
    }
    catch (error) {
      if (error.response !== undefined) {
        // console.log(error.response);
      } else {
        // console.log(error);
      }
    }
  }

  async callAPIUserPost() {
    this.setState({ is_saving_on_process: true, progress: 0 })
    const self = this
    const req = async () => {
      const formData = await this.props.profileModel.read_as_formdata()
      try {
        const response = await axios.post(
          API.POST_USER_PROFILE,
          formData,
          {
            headers: {
              'Authorization': `Bearer ${self.read_jwt()}`,
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => { self.setState({ progress: Math.floor((progressEvent.loaded / progressEvent.total) * 100) }) }
          });
        // console.log(response.data);
        if (response.data.status === 'OK') {
          self.setState({ is_saving_on_process: false })
        }
      }
      catch (error) {
        // if (error.response !== undefined) {
        //   console.log(error.response);
        // } else {
        //   console.log(error)
        // }
        this.setState({ is_saving_on_process: false, progress: 0 })
      }
    }
    await req()
  }

  render() {
    document.title = APP_DATA.APP_NAME
    if (this.props.mode === 'edit') {
      if (this.state.is_own_profile) {
        return (
          <UserProfileEdit
            navigator={this.props.navigate}
            profile_data={this.props.profileModel.read()}
            profileUpdate={this.profileUpdate}
            is_editing={this.state.is_editing}
            is_own_profile={this.state.is_own_profile}
            is_saving_on_process={this.state.is_saving_on_process}
            progress={this.state.progress}
            switchEditing={this.switchEditing}
            callAPIUserPost={this.callAPIUserPost}
            onSelectImage={this.onSelectImage}
            onSelectCV={this.onSelectCV}
            error_messages={this.state.error_messages}
          />
        )
      } else {
        // force redirect to profile if the user is not owner of the profile
        let path = window.location.pathname
        let path_profile = path.replace('/sunting', '')
        window.location.href = path_profile
        return <></>
      }
    } else {
      return (
        <UserProfileView
          navigate={this.props.navigate}
          profile_data={this.props.profileModel.read()}
          is_own_profile={this.state.is_own_profile}
        />
      )
    }
  }
}


export default ((props) => {
  const navigate = useNavigate()
  const Controller = observer(UserProfileController) // bad practices, change it if there is a good approach
  return (
    <Controller {...props} navigate={navigate} />
  )
})