import { useNavigate } from 'react-router-dom';
import * as APP_DATA from '../../ConstantData';
import React from 'react'
import VerifyEmailView from './VerifyEmailView';
import axios from 'axios';
import { API } from '../../entity/API';
import { Page } from '../../entity/Pages';


class VerifyEmailController extends React.Component {
  constructor(props) {
    super(props)
    this.navigator = this.navigator.bind(this)
    this.callAPIVerifyEmail = this.callAPIVerifyEmail.bind(this)
    this.redirectToLogin = this.redirectToLogin.bind(this)
    this.token = new URLSearchParams(window.location.search).get('key');
    this.is_verifying = false

    this.state = {
      is_verified: false,
      error_message: null,
    }
  }

  componentDidMount() {
    if (!this.is_verifying) this.callAPIVerifyEmail()
  }

  navigator(path) {
    this.props.navigate(path)
  }

  redirectToLogin() {
    window.location.href = Page.LOGIN
  }

  async callAPIVerifyEmail() {
    this.is_verifying = true
    const self = this
    const formData = new FormData();
    formData.append('token', this.token)

    try {
      const response = await axios.post(
        API.POST_VERIFY_EMAIL,
        formData,
        {
          headers: {
            // 'Authorization': `Bearer ${jwt}`,
            'Content-Type': 'multipart/form-data'
          }
        });
      // console.log(response.data);
      if (response.data.status === 'OK') {
        self.setState({ is_verified: true })
        setTimeout(self.redirectToLogin, 3000);
      } else {
        self.setState({ error_message: response.data.message[0] })
      }
    }
    catch (error) {
      if (error.response !== undefined) {
        // console.log(error.response);
      }
    }
  }

  render() {
    document.title = `Verify Email | ${APP_DATA.APP_NAME}`
    return (
      <VerifyEmailView
        is_verified={this.state.is_verified}
        error_message={this.state.error_message}
      />
    )
  }
}


export default (props) => {
  const navigate = useNavigate();
  return (
    <VerifyEmailController {...props} navigate={navigate} />
  )
}