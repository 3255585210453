import * as APP_DATA from "../../ConstantData";
import TnCView from "./TnCView";
import React from "react";

class TnCController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    document.title = APP_DATA.APP_NAME;
    return <TnCView />;
  }
}

export default TnCController;