import * as APP_DATA from "../../ConstantData";
import AboutusView from "./AboutusView";
import React from "react";

class AboutusController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    console.log('ENV', process.env.NODE_ENV)
    document.title = APP_DATA.APP_NAME;
    return <AboutusView />;
  }
}

export default AboutusController;