import { makeAutoObservable } from 'mobx'

class UserModel {
    constructor() {
        makeAutoObservable(this)
    }

    model = {
        Token: null,
    }

    CreateToken(value) {
        if (this.model.Token === null)
            this.model.Token = value;
    }

    ReadToken() {
        // force login on developement 
        if (process.env.NODE_ENV === 'development') {
            // return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYiLCJuYmYiOjE2NDE2MTQ2NzcsImV4cCI6MTY0MjIxOTQ3NywiaWF0IjoxNjQxNjE0Njc3fQ.BoIcSarHeE9FdPvVwsBXiBKvu8GXI7DF3AxE-4T9oF0';
            // return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjUiLCJuYmYiOjE2NDE2MTE1MTAsImV4cCI6MTY0MjIxNjMxMCwiaWF0IjoxNjQxNjExNTEwfQ.pws0-N1qgZTtBvnuJGh798bL3xxW9WMvR5JPxYweAj0';
            return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJuYmYiOjE2NDE5Njk0NDksImV4cCI6MTY0MjU3NDI0OSwiaWF0IjoxNjQxOTY5NDQ5fQ.KWfZ9UtoUQ4UbQv6-x-c9SLYwr_6KhQVA0uWOJ8kobA';

        }
        return this.model.Token;
    }

    UpdateToken(value) {
        this.model.Token = value;
    }

    DeleteToken() {
        if (this.model.Token !== null)
            this.model.Token = null;
    }
}

export default UserModel