import React from 'react'


class VerifyEmailView extends React.Component {
  render() {
    const { is_verified, error_message } = this.props;

    return (
      <div className='h-64 flex items-center justify-center'>
        <p className='text-xl'>
          {error_message
            ? "Error: " + error_message
            : is_verified
              ? "Email verified. Redirecting to login..."
              : "Verifying email..."
          }

        </p>
      </div>
    )
  }
}

export default VerifyEmailView