import React from "react";
//import * as APP_DATA from "../../../ConstantData";
import { MgtalksImage } from "../../../entity/MgtalksImage";
import { MitraImage } from '../../../entity/MitraImage';

class AboutusContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const iframe_yt_mg = <iframe
      className="rounded-2xl"
      width="100%"
      height="720"
      src="https://www.youtube.com/embed/vAD6ZgEUwRM"
      title="Mata Garuda Network"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen></iframe>

    const iframe_yt_mg_small = <iframe
      className="rounded-2xl"
      width="100%"
      height="360"
      src="https://www.youtube.com/embed/vAD6ZgEUwRM"
      title="Mata Garuda Network"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen></iframe>
    return (
      <div className="w-10/12 bg-white self-center">
        <div className="flex flex-col w-full">
          <div className="md:flex hidden bg-white my-6 md:my-12 rounded-2xl">
            {iframe_yt_mg}
          </div>
          <div className="md:hidden flex bg-white mb-12 md:my-12 rounded-2xl">
            {iframe_yt_mg_small}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-y-7 gap-x-7 pb-12">
            <div className="flex flex-col rounded-2xl overflow-hidden shadow-xl">
                <img className="w-auto max-h-[420px] object-cover" src={MgtalksImage.mgtalks1} alt="" />
                <div className="px-6 pt-6 pb-8 flex-1">
                    <div className="flex flex-col h-full justify-between">
                        <div className="flex flex-row items-start gap-4 mb-4">
                            <img alt='' src={MitraImage.logomatagaruda} className='cursor-pointer w-12' />
                            <div className="flex flex-col">
                              <span className="mb-2 text-zinc-500 font-semibold">Mata Garuda</span>
                              <h4 className="font-semibold text-base text-zinc-800 leading-6 mb-2">Lulusan S2 Gampang Cari Kerja ? - Eps. 1 MG Talks</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col rounded-2xl overflow-hidden shadow-xl">
                <img className="w-auto max-h-[420px] object-cover" src={MgtalksImage.mgtalks2} alt="" />
                <div className="px-6 pt-6 pb-8 flex-1">
                    <div className="flex flex-col h-full justify-between">
                        <div className="flex flex-row items-start gap-4 mb-4">
                            <img alt='' src={MitraImage.logomatagaruda} className='cursor-pointer w-12' />
                            <div className="flex flex-col">
                              <span className="mb-2 text-zinc-500 font-semibold">Mata Garuda</span>
                              <h4 className="font-semibold text-base text-zinc-800 leading-6 mb-2">Berapa Gaji Lulusan Luar Negeri ? - Eps. 2 MG Talks</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col rounded-2xl overflow-hidden shadow-xl">
                <img className="w-auto max-h-[420px] object-cover" src={MgtalksImage.mgtalks3} alt="" />
                <div className="px-6 pt-6 pb-8 flex-1">
                    <div className="flex flex-col h-full justify-between">
                        <div className="flex flex-row items-start gap-4 mb-4">
                            <img alt='' src={MitraImage.logomatagaruda} className='cursor-pointer w-12' />
                            <div className="flex flex-col">
                              <span className="mb-2 text-zinc-500 font-semibold">Mata Garuda</span>
                              <h4 className="font-semibold text-base text-zinc-800 leading-6 mb-2">Apakah Lulusan LPDP Harus Pulang Ke Indonesia ? - Eps. 3 MG Talks</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutusContent;
