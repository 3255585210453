import React from "react";
import SectionJadiMitra from "./sections/SectionJadiMitra";
import SectionStepMitra from "./sections/SectionStepMitra";
import SectionPartnerKami from "./sections/SectionPartnerKami";
import SectionIndonesia from "./sections/SectionIndonesia";
import SectionTestimoni from "./sections/SectionTestimoni";
import SectionKeuntunganMitra from "./sections/SectionKeuntunganMitra";
import SectionCardSolusi from "./sections/SectionCardSolusi";
import SectionArtikel from "./sections/SectionArtikel";
import SectionGaleri from "./sections/SectionGaleri";
import SectionDaftarSekarang from "./sections/SectionDaftarSekarang";
import SectionFaq from "./sections/SectionFaq";


class MitraView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { navigator } = this.props;

    return (
      <div className="flex flex-col items-center font-poppins">
        <div className="w-10/12">
          <div className="flex flex-col gap-8 mt-10">
            <SectionJadiMitra navigator={navigator} />
            <SectionKeuntunganMitra />
          </div>
        </div>
        <div className="w-12/12">
          <div className="flex flex-col gap-8 mt-10 bg-gray-100 w-screen">
            <SectionCardSolusi />
          </div>
        </div>
        <div className="w-10/12">
          <div className="flex flex-col gap-8 mt-10 relative ">
            <SectionStepMitra />
            <SectionTestimoni />
            <SectionIndonesia />{" "}
          </div>
        </div>
        <div className="w-12/12">
          <SectionPartnerKami />
        </div>
        <div className="w-10/12">
          <div className="flex flex-col gap-8 mt-10 ">
            <SectionArtikel />
            <SectionGaleri />

            <SectionFaq />
          </div>
        </div>
        <div className="w-12/12">
          <SectionDaftarSekarang navigator={navigator} />
        </div>
      </div>
    );
  }
}

export default MitraView;
