import * as APP_DATA from "../../../core/ConstantData";
import MgtalksView from "./MgtalksView";
import React from "react";
import { API } from '../../entity/API';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

class MgtalksController extends React.Component {
  constructor(props) {
    super(props);
    this.updateCurrentPage = this.updateCurrentPage.bind(this)
    this.updateSearchName = this.updateSearchName.bind(this)
    this.doSearchName = this.doSearchName.bind(this)

    this.state = {
      page_number: 1,
      prev_page_number: null,
      search_name: '',
      prev_search_name: '',
      mgtalks: [],
      total_mgtalk: null,
      mgtalk_each_page: null,
    }
  }

  componentDidMount() {
    this.callAPIMgtalks()
  }

  componentDidUpdate() {
    if (this.state.prev_page_number !== this.state.page_number) {
      this.callAPIMgtalks()
    }

  }

  async callAPIMgtalks() {
    const self = this
    try {
      const response = await axios.get(
        API.GET_MGTALKS,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          params: {
            page: self.state.page_number,
          }
        });
      var resultRes = response.data;
      if (resultRes.success === true) {
        self.setState({
          mgtalks: resultRes.data.data,
          prev_page_number: self.state.page_number,
          total_mgtalk: resultRes.data.total,
          mgtalk_each_page: resultRes.data.per_page,
        })
      } else {

      }
    }
    catch (error) {
      if (error.response !== undefined) {
        // console.log(error.response);
      }
    }
  }

  async callAPISearchMgtalks(search_name, page_number) {
    const self = this
    try {
      const response = await axios.get(
        API.GET_COMPANIES,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          params: {
            page: page_number,
            keyword: search_name,
          }
        });
      var resultRes = response.data;
      if (resultRes.success === true) {
        self.setState({
          mgtalks: resultRes.data.data,
          prev_search_name: self.state.search_name,
          prev_page_number: self.state.page_number,
          total_mgtalk: resultRes.data.total,
          mgtalk_each_page: resultRes.data.per_page,
        });
      } else {

      }
    }
    catch (error) {
      if (error.response !== undefined) {
        // console.log(error.response);
      }
    }
  }

  updateCurrentPage(value) {
    if (value !== this.state.page_number) {
      this.setState({
        page_number: value,
        mgtalks: [],
      })
    }
  }

  updateSearchName(value) {
    if (value !== this.state.search_name) {
      this.setState({ search_name: value })
    }
  }

  doSearchName() {
    if (this.state.search_name !== this.state.prev_search_name) {
      let trimmed_search_name = this.state.search_name.toString().trim()
      if (trimmed_search_name) {
        this.callAPISearchMgtalks(trimmed_search_name, 1)
      }
      else {
        this.callAPIMgtalks()
        this.setState({ prev_search_name: '' })
      }
    }
  }

  getPageNumberInfo() {
    let current_page = this.state.page_number
    let is_far_left_page = false
    let is_far_right_page = false

    let num_showed_page = APP_DATA.MAX_PAGE_NUMBER_SHOWED
    let number_of_pages = Math.ceil(this.state.total_mgtalk / this.state.mgtalk_each_page)
    let page_number_for_render = []

    // build page number for render
    if (current_page <= 3) {
      // for small page number show all
      for (let i = 0; i < num_showed_page; i++) {
        if ((i + 1) <= number_of_pages)
          page_number_for_render.push(i + 1)
      }
    } else if (current_page > 3) {
      // big page number, show half before and half after of the current page
      for (let i = 0; i < num_showed_page; i++) {
        // page num to render, start to lower possible by `- Math.floor(APP_DATA.MAX_PAGE_NUMBER_SHOWED / 2)`
        const p = i + current_page - Math.floor(APP_DATA.MAX_PAGE_NUMBER_SHOWED / 2)
        if (p <= number_of_pages) // prevent page num to render exceed the maximum page number
          page_number_for_render.push(p)
      }
    }

    // whether show far right / maximum number / total pages
    if (page_number_for_render.includes(number_of_pages) === false) {
      if (page_number_for_render.includes(number_of_pages - 1)) {
        page_number_for_render.push(number_of_pages)
      } else {
        is_far_right_page = true
      }
    }

    // whether show far left / minimum number / 1
    if (page_number_for_render.includes(1) === false) {
      if (page_number_for_render.includes(2)) {
        page_number_for_render.unshift(1)
      } else {
        is_far_left_page = true
      }
    }

    const page_number_info = {
      current_page: current_page,
      total_page: number_of_pages,
      active_page_number: page_number_for_render,
      is_show_far_left_page: is_far_left_page,
      is_show_far_right_page: is_far_right_page,
    }
    return page_number_info
  }

  render() {
    document.title = APP_DATA.APP_NAME;
    return <MgtalksView 
      navigator={this.props.navigate}
      search_name={this.state.search_name}
      updateCurrentPage={this.updateCurrentPage}
      updateSearchName={this.updateSearchName}
      doSearchName={this.doSearchName}
      mgtalks={this.state.mgtalks}
      page_number_info={this.getPageNumberInfo()}
    />;
  }
}

export default (props) => {
  const navigate = useNavigate();
  return (
    <MgtalksController {...props} navigate={navigate} />
  )
}