import React from 'react'
import { Icons } from '../../entity/Icons';
import { Page } from '../../entity/Pages';

class SubHeaderView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  render() {
    const {
      handleShowSubmenu,
      navigator,
      is_submenu_show,
    } = this.props;

    return (
      <React.Fragment>
        <div className='flex flex-row justify-center font-poppins bg-white drop-shadow-md'>
          <div className='text-magnet-black-normal fill-magnet-black-normal font-medium flex md:flex-row flex-col md:w-10/12 w-full md:px-0 px-8 relative'>
            <button type='button' onClick={(e) => handleShowSubmenu(e)} className='cursor-pointer bg-white hover:bg-gray-200 duration-500 h-full flex md:p-4 p-2'>
              MG Institute
              {!is_submenu_show
                ? <Icons.ANGLE_RIGHT />
                : <Icons.ANGLE_DOWN />}
            </button>
            {is_submenu_show ?
              <button className='px-7 py-3 md:absolute md:left-0 md:top-14 flex flex-col bg-gray-200 hover:bg-gray-300 text-magnet-black-normal duration-500'
                onClick={() => navigator(Page.CALLFORBOOK)}>
                <p className='md:text-center text-left'>Call for book Chapter</p>
              </button>
              : <></>
            }

            {/* <p className='cursor-pointer bg-white hover:bg-gray-200 duration-500 h-full md:p-4 p-2'>MG Epicentrum</p> */}
            <button onClick={() => navigator(Page.MGTALKS)} className='text-left md:text-center cursor-pointer bg-white hover:bg-gray-200 duration-500 h-full md:p-4 p-2'>MG Talks</button>
            {/* <p className='cursor-pointer bg-white hover:bg-gray-200 duration-500 h-full md:p-4 p-2'>Komunitas</p> */}
            <button onClick={() => navigator(Page.FAQ)} className='text-left md:text-center cursor-pointer bg-white hover:bg-gray-200 duration-500 h-full md:p-4 p-2'>FAQ</button>
            <button onClick={() => navigator(Page.ABOUTUS)} className='text-left md:text-center cursor-pointer bg-white hover:bg-gray-200 duration-500 h-full md:p-4 p-2'>About Us</button>
            <button onClick={() => window.location.href = Page.COMPANY} className='text-left md:text-center cursor-pointer bg-white hover:bg-gray-200 duration-500 h-full md:p-4 p-2'>Mitra</button>
            <button onClick={() => window.location.href = Page.JOB} className='text-left md:text-center cursor-pointer bg-white hover:bg-gray-200 duration-500 h-full md:p-4 p-2'>Lowongan</button>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default SubHeaderView