import KabarTerkini from './sections/KabarTerkini'
import Keunggulan from './sections/Keunggulan'
import MainBanner from './sections/MainBanner'
import MulaiBergabung from './sections/MulaiBergabung'
import PartnerKami from './sections/PartnerKami'
import ProgramMagnet from './sections/ProgramMagnet'
import React from 'react'
import SalamPengguna from './sections/SalamPengguna'


class LandingView extends React.Component {
  render() {
    const {
      postShowcase,
    } = this.props;

    return (
      <div className='flex flex-col items-center font-poppins'>
        <div className='w-10/12 flex flex-col gap-8 mt-10 justify-center items-center'>
          <MainBanner {...this.props} />
          <Keunggulan {...this.props} />
          <SalamPengguna {...this.props} />
          <ProgramMagnet />
          <PartnerKami />
          <MulaiBergabung {...this.props} />
          <KabarTerkini content={postShowcase} />
        </div>
      </div>
    )
  }
}

export default LandingView