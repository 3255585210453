import * as APP_DATA from "../../ConstantData";
import FaqView from "./FaqView";
import React from "react";

class FaqController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    document.title = APP_DATA.APP_NAME;
    return <FaqView />;
  }
}

export default FaqController;