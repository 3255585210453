const JWT_KEY = 'auth'
const USERNAME_KEY = 'user'
const EMAIL_KEY = 'email'

export function store_username(val) {
  localStorage.setItem(USERNAME_KEY, val)
}

export function read_username() {
  return localStorage.getItem(USERNAME_KEY)
}

export function delete_username() {
  localStorage.removeItem(USERNAME_KEY)
}

export function store_email(val) {
  localStorage.setItem(EMAIL_KEY, val)
}

export function read_email() {
  return localStorage.getItem(EMAIL_KEY)
}

export function delete_email() {
  localStorage.removeItem(EMAIL_KEY)
}

export function store_jwt(val) {
  localStorage.setItem(JWT_KEY, val)
}

export function read_jwt() {
  return localStorage.getItem(JWT_KEY)
}

export function delete_jwt() {
  localStorage.removeItem(JWT_KEY)
}