import React from "react";
import Company from "./sections/Company";
import FilterCompanyList from "./sections/FilterCompanyList";

class CompanyView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      navigator,
      search_name,
      updateSearchName,
      doSearchName,
      updateCurrentPage,
      companies,
      page_number_info,
    } = this.props

    return (
      <div className="flex flex-col max-xl:mt-10 items-center font-poppins">
        <div className="w-10/12">
          <div className="flex flex-col gap-8 mt-10">
            <div className="flex flex-col gap-4">
              <p className="text-4xl font-semibold">Temukan perusahaan pilihanmu</p>
              <p className="text-lg font-light">
                Jelajahi beragam profil perusahaan yang ada!
              </p>
            </div>
            <FilterCompanyList
              search_name={search_name}
              updateSearchName={updateSearchName}
              doSearchName={doSearchName}
            />
            <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>

            <div className='w-full flex items-center justify-center'>
              <div className='w-full flex flex-col gap-8 items-center justify-center'>
                <div className="w-full flex flex-col mt-8">
                  <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 mt-2 mb-6">
                    { 
                      companies.map((item, i) => {
                        return <Company key={i} companies={item} navigator={navigator} />
                      }) 
                    }
                  </div>
                </div>
                <div>
                  {page_number_info.total_page > 0
                    && <div className='flex md:flex-row flex-col justify-center items-center gap-2'>
                      <p>Pilih halaman</p>
                      <div className='flex flex-row'>
                        {page_number_info.is_show_far_left_page
                          && <button className='mx-1 text-sm w-8 py-1 rounded-lg duration-500 border border-gray-500 bg-white text-gray-500 hover:text-white hover:border-magnet-rose-normal hover:bg-magnet-rose-normal'
                            onClick={() => updateCurrentPage(1)}>
                            1
                          </button>}
                        {page_number_info.is_show_far_left_page && <p className='font-bold'>. . .</p>}
                        {page_number_info.active_page_number.map(function (page_num, i) {
                          let btn_bg = ''
                          if (page_num === page_number_info.current_page) {
                            btn_bg = ' border border-gray-500 bg-gray-500 text-white hover:border-gray-500 hover:bg-gray-500'
                          } else {
                            btn_bg = ' border border-gray-500 bg-white text-gray-500 hover:text-white hover:border-magnet-rose-normal hover:bg-magnet-rose-normal'
                          }
                          return <button key={i} className={'mx-1 text-sm w-8 py-1 rounded-lg duration-500' + btn_bg}
                            onClick={() => updateCurrentPage(page_num)}>
                            {page_num}
                          </button>
                        })}
                        {page_number_info.is_show_far_right_page && <p className='font-bold'>. . .</p>}
                        {page_number_info.is_show_far_right_page
                          && <button className='mx-1 text-sm w-8 py-1 rounded-lg duration-500 border border-gray-500 bg-white text-gray-500 hover:text-white hover:border-magnet-rose-normal hover:bg-magnet-rose-normal'
                            onClick={() => updateCurrentPage(page_number_info.total_page)}>
                            {page_number_info.total_page}
                          </button>}
                      </div>
                    </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyView;
