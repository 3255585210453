import React from 'react'
import { CompanyLogo } from '../../../entity/CompanyLogo';
import { MitraImage } from '../../../entity/MitraImage';


class PartnerKami extends React.Component {
  render() {
    return (
      <div className='md:my-40 my-20'>
        <div className='bg-gray-50 py-10 w-[98vw] flex items-center justify-center'>
          <div className='flex flex-col gap-2 w-10/12'>
            <div className='flex md:flex-row flex-col justify-center'>
              <p className='text-2xl font-semibold text-magnet-black-normal md:text-left text-center'>
                We've More Than <span className=' text-magnet-rose-normal'>100+</span> Partners
              </p>
            </div>
            <div className='grid grid-cols-3 md:flex md:flex-row md:gap-24 gap-4 overflow-hidden my-10 items-center justify-center'>
              <img alt='' src={CompanyLogo.CompanyF} className='md:h-16 max-md:w-64'></img>
              {/* <img alt='' src={MitraImage.logoiigf} className='md:h-16 max-md:w-64'></img> */}
              {/* <img alt='' src={MitraImage.logopii} className='md:h-16 max-md:w-64'></img> */}
              <img alt='' src={MitraImage.logomatagaruda} className='md:h-24 max-md:w-64'></img>
              {/* <img alt='' src={CompanyLogo.CompanyG} className='md:h-16 max-md:w-64'></img>
              <img alt='' src={CompanyLogo.CompanyJ} className='md:h-16 max-md:w-64'></img>
              <img alt='' src={CompanyLogo.CompanyI} className='md:h-16 max-md:w-64'></img> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PartnerKami