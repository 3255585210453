import React from "react";
import { Icons } from "../../../entity/Icons";

class PageTwo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className=" gap-6">
        <div className="text-xl font-bold text-magnet-secondarydark">
          Profil Mitra
        </div>
        <div className="flex flex row justify-between">
          <div className="flex flex-col mr-6 mb-6">
            <div className="flex mt-4 flex-row text-lg font-semibold">
              <div>Foto Perusahaan</div>
              <div className="text-magnet-secondary">*</div>
            </div>
            <div className="flex mt-4 text-sm text-gray font-normal">
              Format gambar .jpg .jpeg .png dan ukuran minimum 300 x 300px
              (Untuk gambar optimal gunakan ukuran minimum 700 x 700 px).
            </div>
            <div className="flex mt-4 text-sm text-gray font-normal">
              Pilih foto produk atau tarik dan letakkan hingga 5 foto sekaligus
              di sini. Cantumkan min. 3 foto yang menarik agar produk semakin
              menarik pembeli.
            </div>
          </div>
          <div className="flex flex-row gap-6">
            <div className="flex items-center justify-center">
              <label
                for="dropzone-file"
                className="flex flex-col items-center justify-center w-24 h-24 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <Icons.IMAGE_PLUS className="fill-gray-400" />
                </div>
                <input id="dropzone-file" type="file" className="hidden" />
              </label>
            </div>
            <div className="flex items-center justify-center">
              <label
                for="dropzone-file"
                className="flex flex-col items-center justify-center w-24 h-24 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <Icons.IMAGE_PLUS className="fill-gray-400" />
                </div>
                <input id="dropzone-file" type="file" className="hidden" />
              </label>
            </div>
            <div className="flex items-center justify-center">
              <label
                for="dropzone-file"
                className="flex flex-col items-center justify-center w-24 h-24  border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <Icons.IMAGE_PLUS className="fill-gray-400" />
                </div>
                <input id="dropzone-file" type="file" className="hidden" />
              </label>
            </div>
            <div className="flex items-center justify-center">
              <label
                for="dropzone-file"
                className="flex flex-col items-center justify-center w-24 h-24  border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <Icons.IMAGE_PLUS className="fill-gray-400" />
                </div>
                <input id="dropzone-file" type="file" className="hidden" />
              </label>
            </div>
            <div className="flex items-center justify-center">
              <label
                for="dropzone-file"
                className="flex flex-col items-center justify-center w-24 h-24  border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <Icons.IMAGE_PLUS className="fill-gray-400" />
                </div>
                <input id="dropzone-file" type="file" className="hidden" />
              </label>
            </div>
          </div>
        </div>

        <div className="flex flex-row text-lg font-semibold">
          <div>Budaya Perusahaan</div>
        </div>
        <input
          className="shadow mt-2 mb-6  appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
          id="budaya"
          type="text"
          placeholder="Budaya"
        ></input>
        <hr className="border-magnet-secondarylight my-4" />
        <div className="flex flex-row justify-between ">
          <div className="flex flex-col w-full mr-4 ">
            <div className="flex flex-row text-lg font-semibold">
              <div>Industri</div>
            </div>
            <input
              className="shadow mt-2 mb-6  appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
              id="industri"
              type="text"
              placeholder="Pilih Jenis"
            ></input>
          </div>
          <div className="flex flex-col w-full ml-4">
            <div className="flex flex-row text-lg font-semibold">
              <div>Jumlah karyawan</div>
            </div>
            <input
              className="shadow mt-2 mb-6  appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
              id="karyawan"
              type="text"
              placeholder="Pilih Jenis"
            ></input>
          </div>
        </div>
        <div className="flex flex-row text-lg font-semibold">
          <div>Nama Penanggungjawab</div>
        </div>
        <input
          className="shadow mt-2 mb-6  appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
          id="penanggungjawab"
          type="text"
          placeholder="Penanggungjawab"
        ></input>
        <div className="flex flex-row justify-between ">
          <div className="flex flex-col w-full mr-4 ">
            <div className="flex flex-row text-lg font-semibold">
              <div>Email</div>
            </div>
            <input
              className="shadow mt-2 mb-6  appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Email"
            ></input>
          </div>
          <div className="flex flex-col w-full ml-4">
            <div className="flex flex-row text-lg font-semibold">
              <div>Telepon</div>
            </div>
            <input
              className="shadow mt-2 mb-6  appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
              id="phone"
              type="tel"
              placeholder="Nomor Telepon"
            ></input>
          </div>
        </div>
        <div className="flex flex-row text-lg font-semibold">
          <div>Nama orang PIC (Person in Charge Pemesanan)</div>
          <div className="text-magnet-secondary">*</div>
        </div>
        <input
          className="shadow mt-2 mb-6  appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
          id="pic"
          type="text"
          placeholder="Nama PIC"
        ></input>
        <div className="flex flex-row text-lg font-semibold">
          <div>
            Nomor Ijin Operasional Klinik (isi “0000” jika dalam pengurusan){" "}
          </div>
          <div className="text-magnet-secondary">*</div>
        </div>
        <input
          className="shadow mt-2 mb-6  appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
          id="ijin"
          type="text"
          placeholder="Nomor ijin"
        ></input>
      </div>
    );
  }
}

export default PageTwo;
