import React from "react";
import { Icons } from "../../../entity/Icons";
import { MitraImage } from "../../../entity/MitraImage";

class SectionCardSolusi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="flex flex-col m-24 relative">
        <div
          className="absolute z-0
         -right-20 -top-32 "
        >
          <img className=" mt-8" alt="" src={MitraImage.graph}></img>
        </div>
        <div className="flex flex-row ">
          <div className="flex items-center gap-2 ml-4 ">
            <p className="text-4xl font-semibold w-1/3">
              Solusi Mudah atasi Semua Masalah
            </p>
            <br />

            <p className="text-normal text-magnet-secondarydark w-3/12">
              Mata Garuda berkomitmen untuk membantu Mitra dalam menemukan
              talenta terbaik untuk mendukung organisasi maupun mengembangkan
              bisnis.
            </p>
          </div>
        </div>
        <div className="flex flex-row gap-4 mt-12 ">
          <div className="bg-white flex flex-col w-full rounded-xl border border-gray-300 hover:border-magnet-secondary duration-500 p-6">
            <div className="flex flex-row   justify-center">
              <div className="flex flex-col">
                <img className="" alt="" src={MitraImage.galeri1}></img>
              </div>
            </div>
            <div className="flex flex-row gap-2 mt-4 justify-start p-2">
              <p className="text-lg font-semibold hover:text-magnet-secondary duration-500 cursor-pointer">
                Job Posting
              </p>
            </div>
            <div className="flex flex-col gap-1 mt-4  p-2">
              <p className="text-gray-500 ">
                Unggah lowongan kerja dan dapatkan akses database serta
                manajemen kandidat dalam satu platform.
              </p>
            </div>
            <div className="flex flex-col gap-1 mt-4">
              <div className="flex flex-row">
                <Icons.CELL className="fill-magnet-secondary w-16 h-6 -ml-4 mb-2" />{" "}
                <p className="text-black font-semibold">
                  Pasang Lowongan GRATIS
                </p>
              </div>{" "}
              <div className="flex flex-row">
                <Icons.CELL className="fill-magnet-secondary w-16 h-6 -ml-3 mb-8" />{" "}
                <p className="text-black font-semibold">
                  Akses database pelamar selamanya
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center mt-4 text-semibold bg-magnet-secondary text-white font-medium rounded-xl px-4 py-1 cursor-default">
              <div>
                <button className="text-semibold bg-magnet-secondary text-white font-medium rounded-xl px-4 py-1 cursor-default">
                  Selengkapnya
                </button>
              </div>
            </div>
          </div>
          <div className="bg-white flex flex-col w-full rounded-xl border border-gray-300 hover:border-magnet-secondary duration-500 p-6">
            <div className="flex flex-row   justify-center">
              <div className="flex flex-col">
                <img className="" alt="" src={MitraImage.galeri2}></img>
              </div>
            </div>
            <div className="flex flex-row gap-2 mt-4 justify-start p-2">
              <p className="text-lg font-semibold hover:text-magnet-secondary duration-500 cursor-pointer">
                TalentHunt
              </p>
            </div>
            <div className="flex flex-col gap-1 mt-4  p-2">
              <p className="text-gray-500 ">
                Temukan talenta yang tepat dengan waktu yang efisien.
              </p>
              <br />
              <br />
            </div>
            <div className="flex flex-col gap-1 mt-4">
              <div className="flex flex-row">
                <Icons.CELL className="fill-magnet-secondary w-16 h-6 -ml-4 mb-2" />{" "}
                <p className="text-black font-semibold">
                  Pasang Lowongan GRATIS
                </p>
              </div>{" "}
              <div className="flex flex-row">
                <Icons.CELL className="fill-magnet-secondary w-16 h-6 -ml-3 mb-8" />{" "}
                <p className="text-black font-semibold">
                  Akses database pelamar selamanya
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center mt-4 text-semibold bg-magnet-secondary text-white font-medium rounded-xl px-4 py-1 cursor-default">
              <div>
                <button className="text-semibold bg-magnet-secondary text-white font-medium rounded-xl px-4 py-1 cursor-default">
                  Selengkapnya
                </button>
              </div>
            </div>
          </div>
          <div className="z-30 bg-white flex flex-col w-full rounded-xl border border-gray-300 hover:border-magnet-secondary duration-500 p-6">
            <div className="flex flex-row   justify-center">
              <div className="flex flex-col">
                <img className="" alt="" src={MitraImage.galeri3}></img>
              </div>
            </div>
            <div className="flex flex-row gap-2 mt-4 justify-start p-2">
              <p className="text-lg font-semibold hover:text-magnet-secondary duration-500 cursor-pointer">
                Recruitment Management
              </p>
            </div>
            <div className="flex flex-col gap-1 mt-4  p-2">
              <p className="text-gray-500 ">
                Ekspansi secara cepat tanpa perlu memikirkan setup.
              </p>
              <br />
              <br />
            </div>
            <div className="flex flex-col gap-1 mt-4">
              <div className="flex flex-row">
                <Icons.CELL className="fill-magnet-secondary w-16 h-6 -ml-4 mb-2" />{" "}
                <p className="text-black font-semibold">
                  Pasang Lowongan GRATIS
                </p>
              </div>{" "}
              <div className="flex flex-row">
                <Icons.CELL className="fill-magnet-secondary w-16 h-6 -ml-3 mb-8" />{" "}
                <p className="text-black font-semibold">
                  Akses database pelamar selamanya
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center mt-4 text-semibold bg-magnet-secondary text-white font-medium rounded-xl px-4 py-1 cursor-default">
              <div className="flex items-end ">
                <button className=" text-semibold bg-magnet-secondary text-white font-medium rounded-xl px-4 py-1 cursor-default">
                  Selengkapnya
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionCardSolusi;
