import React from "react";
import { useNavigate } from "react-router-dom";
import FooterLoginMitraView from "./FooterLoginMitraView";
// import * as APP_DATA from '../../../core/ConstantData';

class FooterLoginMitraController extends React.Component {
  constructor(props) {
    super(props);
    this.navigator = this.navigator.bind(this);
    this.state = {};
  }

  navigator(path) {
    this.props.navigate(path);
  }

  render() {
    return <FooterLoginMitraView />;
  }
}

export default (props) => {
  const navigate = useNavigate();
  return <FooterLoginMitraController {...props} navigate={navigate} />;
};
