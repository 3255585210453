import React from "react";
import DetailJob from "./sections/DetailJob";
import SidebarJob from "./sections/SidebarJob";

class JobDetailView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      detail_job,
      navigator,
      is_logged_in,
      callAPIFavoritedJob,
      callAPIApplyJob,
    } = this.props

    return (
      <div className="flex flex-col max-xl:mt-10 items-center font-poppins">
        <div className="w-10/12">
          <div className="flex flex-col gap-8 mt-10">
            <div className="rounded-2xl bg-neutral-200 p-4 md:p-5 w-full text-neutral-main">
              <div className="flex flex-row gap-x-4 text-sm">
                <span className="text-magnet-rose-normal">Lowongan Pekerjaan</span>
                <span className="text-neutral-600">/</span>
                <span className="text-magnet-rose-normal">{detail_job.partner_name}</span>
                <span className="text-neutral-600">/</span>
                <span className="text-neutral-600">{detail_job.title}</span>
              </div>
            </div>
            <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700" />
            <div className="w-full my-4 md:my-8 flex flex-col md:flex-row gap-x-8 text-neutral-900">
              <DetailJob detail_job={detail_job} is_logged_in={is_logged_in} navigator={navigator} callAPIFavoritedJob={callAPIFavoritedJob} callAPIApplyJob={callAPIApplyJob}/>
              <SidebarJob detail_job={detail_job} is_logged_in={is_logged_in} navigator={navigator}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default JobDetailView;
