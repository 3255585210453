import React from "react";
//import * as APP_DATA from "../../../ConstantData";
// import { Icons } from "../../../entity/Icons";

class BussinessContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // const { navigateToCareer } = this.props;

    return (
      <div className="flex flex-col md:text-base text-sm">
        <div className="flex flex-col mb-6">
          <div className="flex flex-col">
              <p className="text-left text-base font-normal">
                <strong>5ME2045: Business Competition 2024</strong> diadakan sebagai upaya untuk membantu meningkatkan perkembangan kewirausahaan Indonesia.
              </p>
              <p className="text-base text-left font-normal">Sebelum melakukan pendaftaran pastikan telah membaca booklet panduan  serta mempersiapkan proposal bisnis sesuai dengan template. </p>
              <ol className="text-left list-disc ml-8 font-normal text-base">
                <li>
                  <a href="https://drive.google.com/file/d/1dh8edrOTPWEhnOf45D7XIGH3kkGMd_3i/view" className="text-magnet-rose-normal underline">Booklet Panduan</a>
                </li>
                <li>
                  <a href="https://drive.google.com/drive/folders/1wIbw30kntRgBwU1PRHHszn8mtJGd2br6" className="text-magnet-rose-normal underline">Template BIP</a>
                </li>
                <li>
                  <a href="https://drive.google.com/drive/folders/1StJQDZIT0Ho_nthLv789s2Ra1nYqwRjp" className="text-magnet-rose-normal underline">Template BGP</a>
                </li>
              </ol>
              <div className="text-left text-base font-normal mt-4">
                Atau untuk lebih detailnya silahkan kunjungi link informasi berikut ini :  
                <a href="https://s.id/5me2045" className="text-magnet-rose-normal"> Link Informasi</a>
              </div>
          </div>
          <div className="my-8 flex flex-col">
            <h4 className="text-xl font-bold mb-4">Formulir Pendaftaran 5ME2045: Business Competition 2024 </h4>
            <p className="font-normal text-base mb-8">Note : Sebelum melakukan pendaftaran, anda harus login akun google anda terlebih dahulu!</p>
            <div className="w-full flex flex-col justify-start">
              <iframe title="Form Registrasi" src="https://docs.google.com/forms/d/e/1FAIpQLSdUbBBT39GmnN1d9IdYVb5yqabWsTeDFD6mB6hWGInPCVtSwg/viewform?embedded=true" width="100%" height="540" frameBorder="0" marginHeight={0} marginWidth="0">Memuat…</iframe>
            </div>
            <p className="mb-8"> Atau Klik tombol berikut ini untuk menuju google form pendaftaran 5ME2045: Business Competition 2024 </p>
            <div className="flex flex-row">
              <a href="https://forms.gle/atj5xvNTSRjmKxtN6" target="_blank" className="rounded-2xl bg-magnet-rose-normal px-6 py-4 font-bold text-white font-poppins">FORMULIR PENDAFTARAN 5ME2045 2024</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BussinessContent;
