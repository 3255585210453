import React from "react";
import HeaderDetailCompany from "./sections/HeaderDetailCompany";
import DetailCompany from "./sections/DetailCompany";
import SidebarCompany from "./sections/SidebarCompany";

class CompanyDetailView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      detail_company,
      navigate
    } = this.props

    return (
      <div className="flex flex-col max-xl:mt-10 items-center font-poppins">
        <div className="w-10/12">
          <div className="flex flex-col gap-8 mt-10">
            <div className="rounded-2xl bg-neutral-200 p-4 md:p-5 w-full text-neutral-main">
              <div className="flex flex-row gap-x-4 text-sm">
                <span className="text-magnet-rose-normal">Perusahaan</span>
                <span className="text-neutral-600">/</span>
                <span className="text-neutral-600">{detail_company.name}</span>
              </div>
            </div>
            <HeaderDetailCompany detail_company={detail_company} />
            <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700" />
            <div className="w-full flex flex-col md:flex-row md:gap-x-4">
              <DetailCompany detail_company={detail_company}/>
              <SidebarCompany detail_company={detail_company}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyDetailView;
