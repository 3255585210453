
import * as APP_DATA from "../../ConstantData";
import JobDetailView from "./JobDetailView";
import { read_jwt } from '../../utils/JwtToken';
import React from "react";
import { Page } from '../../entity/Pages';
import { API } from '../../entity/API';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

class JobDetailController extends React.Component {
  constructor(props) {
    super(props)
    this.read_jwt = read_jwt.bind(this)
    this.callAPIFavoritedJob = this.callAPIFavoritedJob.bind(this)
    this.callAPIApplyJob = this.callAPIApplyJob.bind(this)

    this.navigator = this.navigator.bind(this)
    this.state = {
      detail_job: [],
      is_logged_in: false,
    }
  }

  componentDidMount() {
    const slugParams =  window.location.pathname.split('/')[2]
    if (this.read_jwt()) {
      this.setState({
        is_logged_in : true
      })
    }
    this.callAPIDetailJob(slugParams)
  }

  navigator(path) {
    this.props.navigate(path)
  }

  async callAPIDetailJob(slugParams) {
    const self = this
    try {
      const response = await axios.get(
        API.GET_JOBS + '/' + slugParams,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${self.read_jwt()}`,
          },
        });
      var resultRes = response.data;
      if (resultRes.success === true) {
        console.log(resultRes.data);
        self.setState({
          detail_job: resultRes?.data,
        })
      } else {
        Swal.fire({
          title : "Error",
          text : 'Gagal memuat data pekerjaan!',
          icon : "error"
        });
      }
    }
    catch (error) {
      if (error.response !== undefined) {
        Swal.fire({
          title : "Error",
          text : 'Gagal memuat data. Terjadi Kesalahan teknis!',
          icon : "error"
        });
      }
      console.log(error);
    }
  }

  async callAPIFavoritedJob(action) {
    var slugParams =  window.location.pathname.split('/')[2]
    const self = this
    try {
      const response = await axios.post(
        API.GET_JOBS + '/' + slugParams + '/favorite',
        {
          action : action,
          slug : slugParams
        },
        {
          headers : {
            'Content-Type' : 'application/json',
            'Authorization': `Bearer ${self.read_jwt()}`,
          }
        }
      );

      const resultRes = response.data;
      if (resultRes.success === true) {
        Swal.fire({
          title : "Berhasil",
          text : resultRes.message,
          icon : "success"
        });
        this.callAPIDetailJob(slugParams)
      } else {
        if (resultRes.code == 401) {
          Swal.fire({
            title : "Error",
            text : resultRes.message,
            icon : "error",
            timer : 3000,
            willClose : () => {
              this.navigator(Page.LOGIN); 
            }
          });
        } else {
          Swal.fire({
            title : "Error",
            text : resultRes.message,
            icon : "error"
          });
        }
      }
    } catch (error) {
      if (error.response !== undefined) {
        Swal.fire({
          title : "Error",
          text : 'Gagal memfavoritkan pekerjaan. Terjadi Kesalahan teknis!',
          icon : "error"
        });
      }
      console.log(error);
    }
  }

  async callAPIApplyJob(action) {
    var slugParams =  window.location.pathname.split('/')[2]
    const self = this
    try {
      const response = await axios.post(
        API.GET_JOBS + '/' + slugParams + '/apply',
        {
          action : action,
          slug : slugParams
        },
        {
          headers : {
            'Content-Type' : 'application/json',
            'Authorization': `Bearer ${self.read_jwt()}`,
          }
        }
      );

      const resultRes = response.data;
      
      if (resultRes.success === true) {
        Swal.fire({
          title : "Berhasil",
          text : resultRes.message,
          icon : "success"
        });
        this.callAPIDetailJob(slugParams)
      } else {
        if (resultRes.code == 401) {
          Swal.fire({
            title : "Error",
            text : resultRes.message,
            icon : "error",
            timer : 3000,
            willClose : () => {
              this.navigator(Page.LOGIN); 
            }
          });
        } else {
          Swal.fire({
            title : "Error",
            text : resultRes.message,
            icon : "error"
          });
        }
      }
    } catch (error) {
      if (error.response !== undefined) {
        Swal.fire({
          title : "Error",
          text : 'Gagal melamar pekerjaan. Terjadi Kesalahan teknis!',
          icon : "error"
        });
      }
      console.log(error);
    }
  }
  

  render() {
    document.title = APP_DATA.APP_NAME;
    return (
      <JobDetailView
        navigator={this.props.navigate}
        detail_job={this.state.detail_job}
        is_logged_in={this.state.is_logged_in}
        callAPIFavoritedJob={this.callAPIFavoritedJob}
        callAPIApplyJob={this.callAPIApplyJob}
      />
    )
  }
}

export default (props) => {
  const navigate = useNavigate();
  return (
    <JobDetailController {...props} navigate={navigate} />
  )
}
