import * as APP_DATA from "../../../core/ConstantData";
import BeritaView from "./BeritaView";
import React from "react";

class BeritaController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    document.title = APP_DATA.APP_NAME;
    return <BeritaView />;
  }
}

export default BeritaController;
