import React from "react";
import { Icons } from "../../../entity/Icons";
import { Page } from "../../../entity/Pages";
import { MitraImage } from "../../../entity/MitraImage";

class SectionJadiMitra extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { navigator } = this.props;
    return (
      <div className="flex flex-col">
        <div className="lg:flex flex-row">
          <div className="w-6/12 flex flex-col gap-6 ">
            <div className="flex gap-2">
              <p className="text-md font-semibold text-magnet-secondary">
                Mari Bergabung
              </p>
            </div>
            <p className="text-5xl font-semibold">Menjadi Mitra Mata Garuda</p>
            <p className="text-lg">
              Mata Garuda adalah ikatan alumni penerima beasiswa Lembaga
              Pengelola Dana Pendidikan (LPDP) yang bertujuan sebagai pusat
              kontribusi para anggotanya dalam pembangunan Indonesia.
            </p>
            <div className="flex flex-row">
              <button onClick={() => navigator(Page.REGISTERMITRA)} className="mr-4 py-2 px-12 text-white font-semibold rounded-2xl bg-magnet-secondary text-md">
                Bergabung
              </button>
              <button className="py-2 px-6 text-white font-semibold text-magnet-secondarydark rounded-2xl bg-red-200 text-md">
                Tonton Video <Icons.ANGLE_DOWN className="inline-flex ml-2" />
              </button>
            </div>
          </div>
          <div className="w-6/12 -mt-16 ml-4  justify-end items-center z-30">
            <img className="" alt="" src={MitraImage.galeri0}></img>
          </div>
        </div>
        <div className="flex flex-col mt-16 relative">
          <div
            className="absolute z-0
         -right-40 -top-60 object-bottom-left "
          >
            <img className=" mt-8" alt="" src={MitraImage.vector3}></img>
          </div>
          <hr className="bg-gray-200 z-30" />
          <div className="flex justify-between flex-row my-8 z-30">
            <img className="mr-2" alt="" src={MitraImage.logo1}></img>
            <img className="mx-2" alt="" src={MitraImage.logo2}></img>
            <img className="mx-2" alt="" src={MitraImage.logo3}></img>
            <img className="mx-2" alt="" src={MitraImage.logo4}></img>
            <img className="mx-2" alt="" src={MitraImage.logo5}></img>
            <img className="mx-2" alt="" src={MitraImage.logo6}></img>
            <img className="mx-2" alt="" src={MitraImage.logo7}></img>
            <img className="ml-2" alt="" src={MitraImage.logo8}></img>
          </div>
          <hr className="bg-gray-200 z-30" />
          <div className="flex flex-row mt-16">
            <div className="w-6/12 text-4xl font-bold mr-8">
              100+ Mitra Mata Garuda telah bergabung
            </div>
            <div className="w-6/12 text-sm mx-16 text-justify">
              Mata Garuda berkomitmen untuk membantu Mitra dalam menemukan
              talenta terbaik untuk mendukung organisasi maupun mengembangkan
              bisnis.
              <br />
              <br />
              <button className="mr-4 py-2 px-12 text-magnet-secondary hover:text-white hover:bg-magnet-secondary font-semibold rounded-2xl border border-magnet-secondary text-md">
                Pelajari Lebih Lanjut
              </button>
            </div>
          </div>
          <div className="flex flex-row mt-16">
            <div className="w-4/12 text-4xl font-bold mr-8">
              <img className="mr-2" alt="" src={MitraImage.img1}></img>
            </div>
            <div className="w-8/12 mx-16 mt-16">
              <p className="text-md font-semibold text-magnet-secondary">
                Mari Bergabung
              </p>
              <p className="text-2xl font-bold my-2">
                Ekosistem Yang Terintegrasi
              </p>
              <p className="text-sm text-justify mt-2">
                Organisasi Mata Garuda secara resmi berdiri pada tahun 2015.
                Eksistensinya dipelopori oleh para alumni generasi pertama LPDP.
              </p>
              <br />
              <button className="mr-4 py-2 px-12 text-magnet-secondary hover:text-white hover:bg-magnet-secondary font-semibold rounded-2xl border border-magnet-secondary text-md">
                Pelajari Lebih Lanjut
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionJadiMitra;
