import React from "react";
//import * as APP_DATA from "../../../ConstantData";
// import { Icons } from "../../../entity/Icons";

class CallforbookContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // const { navigateToCareer } = this.props;

    return (
      <div className="flex flex-col mt-4 lg:px-4 xl:px-8 md:text-base text-sm">
        <div className="flex flex-col mb-6">
          <h4 className="text-xl font-semibold mb-4 text-magnet-primary">Pendahuluan</h4>
          <p className="font-light mb-2">
            Infrastruktur adalah fondasi pembangunan ekonomi, dan itu termasuk sistem kritis seperti transportasi, energi, pasokan air, dan jaringan komunikasi. Pembangunan infrastruktur dapat mengarah pada akses yang lebih baik ke layanan dasar, meningkatkan produktivitas, dan menciptakan lapangan kerja baru, yang pada gilirannya dapat berkontribusi pada pertumbuhan ekonomi dan pembangunan sosial secara keseluruhan.
          </p>
          <p className="font-light mb-2">
            Namun, pembangunan infrastruktur membutuhkan investasi dan pembiayaan yang signifikan, yang merupakan tantangan besar bagi negara berkembang seperti Indonesia. Dana pensiun dan dana filantropi merupakan sumber potensial modal kelembagaan jangka panjang untuk pembiayaan pembangunan infrastruktur di negara ini. Dana pensiun adalah jenis dana investasi yang memberikan manfaat pensiun kepada individu dan dapat berinvestasi di berbagai instrumen keuangan, termasuk proyek infrastruktur. Dana filantropi adalah jenis yayasan amal yang memberikan hibah dan sumbangan untuk mendukung berbagai tujuan sosial dan lingkungan, termasuk pembangunan infrastruktur.
          </p>
          <p className="font-light mb-2">
            Terlepas dari potensinya sebagai sumber pendanaan, menginvestasikan dana pensiun dan filantropi dalam proyek infrastruktur memerlukan pertimbangan yang cermat dari berbagai faktor, seperti regulasi, strategi investasi, manajemen risiko, dan evaluasi kinerja. Peraturan yang mengatur dana pensiun dan filantropi seringkali memberlakukan pembatasan dan persyaratan pada jenis investasi yang dapat mereka lakukan dan tingkat risiko yang dapat mereka ambil. Oleh karena itu, optimalisasi yang efektif dari dana tersebut untuk berinvestasi dalam infrastruktur memerlukan perencanaan dan analisis yang cermat untuk memastikan bahwa investasi tersebut sejalan dengan tujuan dana tersebut dengan tetap mematuhi peraturan dan memitigasi risiko. Selain itu, evaluasi kinerja sangat penting untuk memastikan bahwa investasi dana menghasilkan pengembalian yang diharapkan dan memenuhi dampak sosial dan lingkungan yang diinginkan.
          </p>
          <p className="font-light mb-2">
            Atas hal tersebut di atas, PT Penjaminan Infrastruktur Indonesia (Persero) melalui IIGF Institute, bekerjasama dengan Mata Garuda melalui Mata Garuda Institute, mengundang para penulis untuk mengirimkan artikel yang akan dikompilasi pada tiga seri buku dengan topik utama “Skema Pembiayaan Inovatif untuk Pembangunan Indonesia”
          </p>
        </div>
        <div className="flex flex-col mb-6">
          <h4 className="text-xl font-semibold mb-4 text-magnet-primary">Buku 1: Optimalisasi Dana Pensiun untuk Pembangunan Infrastruktur di Indonesia</h4>
          <div className="flex flex-col">
            <p className="font-light mb-2">
              Seri Buku pertama ini bertujuan menggali potensi dana pensiun untuk berperan signifikan dalam pembiayaan pembangunan infrastruktur di Indonesia. Dana pensiun, sebagai sumber modal kelembagaan jangka panjang yang signifikan, berpotensi menyediakan pendanaan yang stabil dan berjangka panjang untuk proyek-proyek infrastruktur. Namun, optimalisasi dana pensiun yang efektif untuk berinvestasi di bidang infrastruktur memerlukan pertimbangan yang cermat terhadap beberapa faktor, antara lain regulasi dana pensiun, strategi investasi, manajemen risiko, dan evaluasi kinerja.
            </p>
            <p className="font-light mb-2">
              Bab-bab dalam buku ini dapat menjawab beberapa pertanyaan kunci terkait optimalisasi dana pensiun untuk pembangunan infrastruktur, seperti: Bagaimana dana pensiun dapat dimobilisasi untuk berinvestasi pada proyek infrastruktur? Apa saja tantangan dan risiko utama yang terkait dengan investasi dana pensiun di bidang infrastruktur, dan bagaimana hal ini dapat dikurangi? Apa praktik dan strategi terbaik untuk menginvestasikan dana pensiun di bidang infrastruktur, baik di Indonesia maupun di dunia? Bagaimana kinerja investasi dana pensiun di bidang infrastruktur dapat diukur dan dievaluasi?
            </p>
            <p className="font-light mb-2">
              Penulis didorong untuk menyerahkan bab berbasis penelitian yang memberikan bukti empiris, wawasan teoretis, dan rekomendasi praktis tentang ini dan masalah terkait lainnya. Secara keseluruhan, buku ini berupaya memberikan gambaran yang komprehensif dan terkini tentang potensi peran dana pensiun dalam pembiayaan pembangunan infrastruktur di Indonesia, serta tantangan dan peluang utama yang terkait dengan topik penting ini.
            </p>
          </div>
        </div>
        <div className="flex flex-col mb-6">
          <h4 className="text-xl font-semibold mb-4 text-magnet-primary">Buku 2: Dana Filantropi untuk Pembangunan di Indonesia</h4>
          <div className="flex flex-col">
            <p className="font-light mb-2">
              Buku kedua dapat menggali potensi dana filantropi untuk mendukung pembangunan di Indonesia di berbagai sektor, termasuk ekonomi hijau. Dana filantropi, termasuk yayasan dan organisasi amal, dapat menyediakan sumber daya yang signifikan untuk berbagai proyek pembangunan, termasuk proyek sosial, prakarsa pemberdayaan ekonomi, pembangunan infrastruktur, dan ekonomi hijau. Namun, menarik dana filantropi untuk berinvestasi dalam proyek-proyek pembangunan membutuhkan pertimbangan yang cermat dari beberapa faktor, termasuk peraturan dana filantropi, strategi penggalangan dana, penilaian dampak sosial, dan keterlibatan pemangku kepentingan.
            </p>
            <p className="font-light mb-2">
              Bab-bab dalam buku ini dapat menjawab beberapa pertanyaan kunci terkait menarik dana filantropi untuk pembangunan di Indonesia, termasuk ekonomi hijau, seperti: Bagaimana dana filantropi dapat dimotivasi untuk berinvestasi dalam proyek pembangunan? Apa saja tantangan dan risiko utama yang terkait dengan investasi dana filantropi dalam pembangunan, termasuk ekonomi hijau, dan bagaimana cara mengatasinya? Apa praktik terbaik dan strategi penggalangan dana dan keterlibatan pemangku kepentingan untuk menarik dana filantropi untuk pembangunan di Indonesia?
            </p>
            <p className="font-light mb-2">
              Penulis didorong untuk menyerahkan bab berbasis penelitian yang memberikan bukti empiris, wawasan teoretis, dan rekomendasi praktis tentang ini dan masalah terkait lainnya. Secara keseluruhan, buku ini berupaya memberikan gambaran menyeluruh dan terkini tentang potensi peran dana filantropi dalam mendukung pembangunan di Indonesia, di berbagai sektor, termasuk ekonomi hijau, serta tantangan dan peluang utama yang terkait dengan topik penting ini.
            </p>
          </div>
        </div>
        <div className="flex flex-col mb-6">
          <h4 className="text-xl font-semibold mb-4 text-magnet-primary">Buku 3: Skema Pembiayaan Kreatif dan Inovatif untuk Pembangunan Infrastruktur</h4>
          <div className="flex flex-col">
            <p className="font-light mb-2">
              Buku ketiga seri ini bertujuan untuk menggali potensi skema pembiayaan yang kreatif dan inovatif untuk pembangunan infrastruktur di Indonesia. Pembangunan infrastruktur sangat penting untuk pertumbuhan ekonomi dan pembangunan sosial, tetapi membutuhkan investasi dan pembiayaan yang signifikan. Skema pembiayaan tradisional mungkin tidak selalu cukup atau sesuai untuk semua proyek infrastruktur. Oleh karena itu, buku ini berupaya menggali berbagai skema pembiayaan kreatif dan inovatif yang dapat diterapkan pada proyek-proyek infrastruktur di Indonesia.
            </p>
            <p className="font-light mb-2">
              Bab-bab dalam buku ini dapat menjawab beberapa pertanyaan kunci terkait skema pembiayaan yang kreatif dan inovatif untuk pembangunan infrastruktur di Indonesia, seperti: Apa tren dan inovasi terkini dalam pembiayaan infrastruktur, dan bagaimana penerapannya pada proyek-proyek di Indonesia? Apa saja tantangan dan risiko utama yang terkait dengan skema pembiayaan alternatif untuk infrastruktur, dan bagaimana cara mengatasinya? Apa praktik dan strategi terbaik untuk merancang dan mengimplementasikan skema pembiayaan yang kreatif dan inovatif untuk proyek infrastruktur di Indonesia?
            </p>
            <p className="font-light mb-2">
              Penulis didorong untuk menyerahkan bab berbasis penelitian yang memberikan bukti empiris, wawasan teoretis, dan rekomendasi praktis tentang ini dan masalah terkait lainnya. Topik yang menarik dapat mencakup, namun tidak terbatas pada, pembiayaan proyek, kemitraan publik-swasta, pembiayaan ramah lingkungan, crowdfunding, dan model pembiayaan alternatif lain yang cocok untuk pembangunan infrastruktur di Indonesia. Secara keseluruhan, buku ini berupaya memberikan gambaran yang komprehensif dan terkini tentang potensi skema pembiayaan yang kreatif dan inovatif untuk pembangunan infrastruktur di Indonesia, serta tantangan dan peluang utama yang terkait dengan topik penting ini.
            </p>
          </div>
        </div>
        <div className="flex flex-col mb-6">
          <h4 className="text-xl font-semibold mb-4 text-magnet-primary">Kriteria / Persyaratan Peserta</h4>
          <div className="flex flex-col">
            <ul className="list-disc list-inside indent-4">
              <li>Penulis adalah awardee LPDP, dan minimal sedang menjalani perkuliahan pada jenjang S2/master/magister.</li>
              <li>Penulis non-awardee LPDP dapat ikut serta pada kegiatan ini, dengan bekerja sama sebagai join author(s) dengan awardee LPDP.</li>
              <li>Setiap artikel/chapter dapat ditulis oleh maksimal 3 orang penulis, dengan sekurang-kurangnya satu penulis merupakan awardee LPDP.</li>
              <li>Peserta diharuskan melakukan registrasi secara online pada link berikut ini: <a className="text-magnet-primary" href="https://bit.ly/IIGFMG_regist">bit.ly/IIGFMG_regist</a> paling lambat <b>tanggal 20 Juli 2023 pukul 23:00 WIB.</b></li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col mb-6">
          <h4 className="text-xl font-semibold mb-4 text-magnet-primary">Panduan Pengajuan</h4>
          <div className="flex flex-col mb-4">
            <ul className="list-disc list-inside indent-4">
              <li>Artikel yang dikirimkan harus asli dan belum pernah dipublikasikan dan/atau sedang ditinjau (peer review) di tempat lain.</li>
              <li>Artikel yang dikirimkan memiliki panjang tulisan antara 8.000-10.000 kata, termasuk referensi.</li>
              <li>Tulisan bersifat ilmiah-populer dan untuk dibaca khalayak ramai dari berbagai latar belakang keilmuan.</li>
              <li>Artikel ditulis pada kertas A4, dengan huruf Times New Roman, ukuran 12, spasi 1,5.</li>
              <li>Sitasi menggunakan APA 7th Edition (2020), dan dapat disertai footnote/catatan kaki</li>
              <li>Ilustrasi yang diperkenankan pada naskah artikel hanya berupa tabel dan grafik disertai penjelasannya.</li>
              <li>Artikel yang dikirimkan minimal berisi: Latar Belakang, Rumusan Masalah, Kajian Inovasi yang Ditawarkan, Kajian Implementasinya di Indonesia. Tidak ada ketentuan baku untuk penulisan judul dan sub-judul; para penulis dapat dengan kreatif memilih diksi untuk judul dan sub-judul yang menarik dan sesuai muatan isi tulisannya.</li>
              <li>Semua artikel yang masuk seleksi akan menjalani proses blind peer-review yang ketat.</li>
              <li>Batas waktu pengiriman artikel adalah <b>tanggal 30 Juli 2023 pukul 23:00 WIB</b></li>
              <li>Link submission artikel akan dikirimkan ke <b>email yang telah anda daftarkan</b></li>
              <li>
                Dalam satu email pengiriman artikel, para penulis harus menyertakan 3 file sebagai berikut :
                <ol className="list-disc list-inside pl-6">
                  <li>File 1 : MsWord Cover Artikel, yang di dalamnya terdapat Judul Artikel, Nama Lengkap para Penulis, dan biografi singkat para penulis (50-100 kata).</li>
                  <li>File 2 : MsWord Artikel, yang di dalamnya HANYA menyertakan Judul Artikel dan Isi Artikel, tanpa identitas para penulis</li>
                  <li>File 3 : PDF, CV dari seluruh penulis yang terlibat</li>
                </ol>
              </li>
              <li>Untuk perincian lebih lanjut, silakan klik link berikut ini : <a className="text-magnet-primary" href="https://www.canva.com/design/DAFixw3dz-8/UKn9aCn6XcxZ1kNq2t_r7A/view?utm_content=DAFixw3dz-8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink#8">Link Informasi</a></li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col mb-6">
          <h4 className="text-xl font-semibold mb-4 text-magnet-primary">Formulir Registrasi</h4>
          <p className="font-semibold">Note : Sebelum melakukan registrasi, anda harus login akun google anda terlebih dahulu</p>
          <div className="my-8 flex flex-col">
            <iframe title="Form Registrasi" src="https://docs.google.com/forms/d/e/1FAIpQLSc1HAxYW1eEcuXTxtCkgve75i_0H_YEGALTj7cYONlhuB4fqA/viewform?embedded=true" width="100%" height="480" frameBorder="0" marginHeight={0} marginWidth="0">Memuat…</iframe>
            <p className="my-8"> Atau Klik tombol berikut ini untuk menuju formulir registrasi</p>
            <div className="flex flex-row justify-center">
              <a href="https://bit.ly/IIGFMG_regist" className="rounded-2xl bg-magnet-primary px-6 py-4 font-bold text-white font-poppins">FORMULIR REGISTRASI</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CallforbookContent;
