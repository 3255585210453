import React from 'react'
import { Icons } from '../../entity/Icons'
import { Page } from '../../entity/Pages'
import { API } from '../../entity/API'
// import * as APP_DATA from '../../../ConstantData';
// import { Icons } from '../../entity/Icons';
import d_img from '../../../image/dummyimg-person.png'
import { TopFunction } from '../../utils/TopFunction'


class ProfileCard extends React.Component {
  openNewTab(url) {
    window.open(url, '_blank').focus()
  }

  checkLink(url) {
    if (!url.includes('http')) {
      url = 'https://' + url
    }
    return url
  }

  trimText(text, length) {
    text = text.substring(0, length)
    text = text + '...'
    return text
  }

  render() {
    const {
      navigator,
      user,
      updateSelectedCategory,
      category,
    } = this.props

    const {
      city,
      country,
      // created_at,
      description,
      expertise,
      // linkedin,
      fullname,
      username,
      // id,
      // instagram,
      majorstudy,
      picture,
      // pk,
      // reason,
      // twitter,
      university,
      // updated_at,
      whatsapp,
      is_verified,
    } = user

    // const user_id = 'razifrizqullah'
    const profile_path = Page.USER_PROFILE.replace(':user_id', username)
    const whatsapp_url = 'https://wa.me/' + whatsapp

    const picture_url = API.HOST + '/upload/profile_pictures/' + picture

    return (
      <div className='flex flex-col w-[325px] md:w-[225px] xl:w-[275px] 2xl:w-[325px] border justify-between border-gray-300 hover:border-magnet-black-normal duration-1000 ease-in-out shadow-lg shadow-gray-300 rounded-lg hover:-translate-y-1 bg-white'>
        <div className='relative w-[325px] md:w-[225px] xl:w-[275px] 2xl:w-[325px] h-[350px] md:h-[300px] xl:h-[350px] 2xl:h-[350px] rounded-xl'>
          <img alt='profile-picture'
            className='object-cover w-[323px] md:w-[223] xl:w-[273px] 2xl:w-[323px] h-[350px] md:h-[300px] xl:h-[350px] 2xl:h-[350px] rounded-lg'
            src={picture_url}
            onError={({ currentTarget }) => {
              if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                currentTarget.src = d_img;
              } else {
                currentTarget.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/768px-Windows_10_Default_Profile_Picture.svg.png";
              }
              currentTarget.onerror = null; // prevents looping
            }}></img>
          <div className='absolute rounded-xl bg-gradient-to-t from-magnet-black-normal to-transparent inset-0'></div>
          <div className='relative bottom-[140px] text-white p-4 flex flex-col gap-1'>
            <div className='flex items-center'>
              <p className='font-semibold hover:text-magnet-yellow-normal duration-500 cursor-pointer'
                onClick={() => { TopFunction(); navigator(profile_path) }}>
                {fullname}
              </p>
              <div className='has-tooltip'>
                <span className='tooltip -mt-6 -ml-16 text-xs bg-magnet-black-normal bg-opacity-75 border-2 border-magnet-orange-normal text-white rounded-xl px-2'>Verified LPDP Awardee</span>
                {is_verified && <Icons.BADGE className=' fill-magnet-orange-normal ml-1 h-4' />}
              </div>
            </div>
            <p className='text-sm font-light'>{majorstudy}, {university}</p>
            <div className='flex flex-wrap gap-1'>
              {expertise.split(",").map(function (item, i) {
                item = item.trim()
                item = item.charAt(0).toUpperCase() + item.substring(1);
                let color_bg;
                if (category.toUpperCase() === item.toUpperCase()) {
                  color_bg = 'bg-magnet-primary text-white border-magnet-primary'
                } else {
                  color_bg = ''
                }
                return <button key={i}
                  className={'px-2 pb-[2px] pt-[1px] border border-white border-opacity-50 bg-transparent hover:bg-white rounded-full text-white hover:text-magnet-black-normal text-xs duration-500' + color_bg}
                  onClick={() => updateSelectedCategory(item)}>
                  {item}
                </button>
              })}
            </div>
          </div>
        </div>
        <div className='flex flex-grow flex-col justify-between p-4'>
          <div className='py-2 min-h-[100px]'>
            <p className='text-sm'>
              {this.trimText(description, 200)}
            </p>
          </div>
          <div className='flex'>
            <Icons.MAP_MARKER className='fill-magnet-black-normal opacity-50 h-5 -ml-1' />
            <p className='text-sm text-magnet-black-normal opacity-50'>
              {city}, {country}.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default ProfileCard