import React from "react";
import { Icons } from "../../../entity/Icons";
import HTMLReactParser from "html-react-parser";

class DetailCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { detail_company } = this.props;
    var displayJobVacancies = detail_company?.job_vacancies?.map(function(item, i) {
      return (
        <div className="border border-primary-disabled shadow flex flex-col p-4 md:p-6 rounded-xl text-neutral-main">
          <div className="flex items-start justify-between mb-4">
            <a href="#">
              <img src={item.partner_logo_url} className="min-w-16 max-w-16 min-h-16 max-h-16 md:min-w-32 md:max-w-32 md:max-h-32 md:min-h-32 rounded-xl"
                onError={({ currentTarget }) => {
                  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    currentTarget.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/768px-Windows_10_Default_Profile_Picture.svg.png";
                  } else {
                    currentTarget.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/768px-Windows_10_Default_Profile_Picture.svg.png";
                  }
                  currentTarget.onerror = null; // prevents looping
                }} />
            </a>
          </div>
          <a href="#" className="flex flex-col">
            <p className="text-primary-dark text-base font-bold mb-2">{item.title}</p>
            <div className="flex flex-row items-center mb-2">
              <Icons.MAP_MARKER className="w-4 h-4" />
              <p className="text-sm font-bold ml-2">
                {item.city_name ? item.city_name : ''}
                {item.state_name ? ', ' + item.state_name : ''}
              </p>
            </div>
            <div className="flex flex-row items-center mb-1">
              <Icons.INVOICE className="h-4 w-4" />
              <p className="text-sm font-bold ml-2">
                {item.min_salary ? item.min_salary : ''}
                {item.max_salary ? ' - ' + item.max_salary : ''}
              </p>
            </div>
            <div className="flex flex-row items-center mb-3">
              <Icons.SUITCASE_ALT className="h-4 w-4" />
              <p className="text-sm ml-2">{item.min_experience}</p>
            </div>
          </a>
        </div>
      )
    });

    return (
      <div className="flex flex-col w-full md:w-8/12 lg:w-9/12">
        <div className="flex flex-col bg-white shadow px-4 md:px-6 py-4 rounded-2xl mb-6">
          <h3 className="text-base md:text-lg font-montserrat font-bold mb-8">Tentang {detail_company.name}</h3>
          <div className="flex flex-col mb-6">
            <h4 className="text-sm md:text-base font-bold">Deskripsi</h4>
            <hr className="mt-2 mb-3" />
            <p className="md:text-sm text-justify">{HTMLReactParser(`${detail_company.description}`)}</p>
          </div>
          <div className="flex flex-col mb-6">
            <h4 className="text-sm md:text-base font-bold">Kultur Perusahaan</h4>
            <hr className="mt-2 mb-3" />
            <p className="md:text-sm text-justify">{HTMLReactParser(`${detail_company.culture}`)}</p>
          </div>
          <div className="flex flex-col mb-6">
            <h4 className="text-sm md:text-base font-bold">Alamat</h4>
            <hr className="mt-2 mb-3" />
            <p className="md:text-sm text-justify hover:text-magnet-rose-normal">
              {detail_company.address} 
              {(detail_company.zip_code ? ', ' + detail_company.zip_code : '')}
              {(detail_company.city_name ? ', ' + detail_company.city_name : '')}
              {(detail_company.state_name ? ', ' + detail_company.state_name : '')}
            </p>
            <a href={detail_company.map_url} className="text-sm mt-4 underline text-primary-main" target="_blank">{detail_company.map_url}</a>
          </div>
        </div>
        <div className="flex flex-col bg-white shadow px-4 md:px-6 py-4 rounded-2xl mb-6">
          <h3 className="text-base md:text-lg font-montserrat font-bold pb-4 border-b border-neutral-disabled">
            Lowongan saat ini di {detail_company.name}</h3>
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-4 my-6">
            {(displayJobVacancies != '' ? displayJobVacancies : 'Belum ada Lowongan')}
          </div>
          <div className="flex justify-center md:justify-end mt-4">
            <a href="#" className="flex px-4 md:px-8 py-3 text-sm font-bold bg-primary-main rounded-xl text-white">
              <span>Lihat Lowongan Pekerjaan di&nbsp;</span>
              <span className="hidden md:block"> {detail_company.name}</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailCompany;
