import React from 'react'
// import { Icons } from '../../../entity/Icons'


class CredentialInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }

  }

  render() {
    const {
      profileUpdate,
      profile_data,
    } = this.props;

    let asteric_symbol = <span className="text-magnet-rose-normal">&nbsp;*</span>

    return (
      <div>
        <p className="text-xl font-bold text-magnet-rose-normal mb-6">
          Data kredensial akun anda
        </p>
        <div className='flex flex-col gap-6'>
          <div>
            <div className="md:text-lg text-sm md:font-semibold md:mb-0 mb-2">
              <p>Alamat email{asteric_symbol}</p>
            </div>
            <input
              className="md:text-base text-sm shadow appearance-none border rounded-lg w-full md:py-4 py-2 px-4  text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
              id="email"
              type="email"
              value={profile_data.Email}
              onChange={(e) => { profileUpdate({ 'Email': e.target.value }); }}
              placeholder="Masukkan alamat email"
            ></input>
          </div>
          <div>
            <div className="md:text-lg text-sm md:font-semibold md:mb-0 mb-2">
              <p>Password{asteric_symbol}</p>
            </div>
            <input
              className="md:text-base text-sm shadow appearance-none border rounded-lg w-full md:py-4 py-2 px-4  text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
              id="password"
              type="password"
              placeholder="Masukkan password"
              value={profile_data.Password}
              onChange={(e) => { profileUpdate({ 'Password': e.target.value }); }}
            ></input>
          </div>
          <div>
            <div className="md:text-lg text-sm md:font-semibold md:mb-0 mb-2">
              <p>Konfirmasi password{asteric_symbol}</p>
            </div>
            <input
              className="md:text-base text-sm shadow appearance-none border rounded-lg w-full md:py-4 py-2 px-4  text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
              id="re-password"
              type="password"
              placeholder="Ketik ulang password"
              value={profile_data.RetypePassword}
              onChange={(e) => { profileUpdate({ 'RetypePassword': e.target.value }); }}
            ></input>
          </div>
          <hr className="border-magnet-rose-normal mt-8" />
        </div>
      </div>
    );
  }
}


export default CredentialInfo