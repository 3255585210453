import React from "react";


class TnCView extends React.Component {
  render() {
    return (
      <div className="flex flex-col items-center font-poppins">
        <div className="w-10/12 flex flex-col gap-16 justify-center">
          <div className="flex flex-col items-center justify-center gap-10">
            <div className="flex flex-col items-center mx-auto gap-8">
              <h2 className="p-4 text-3xl mx-auto max-w-fit md:text-5xl font-bold leading-snug bg-gradient-to-r from-magnet-rose-normal to-magnet-orange-normal bg-clip-text text-transparent">
                Ketentuan Pengguna
              </h2>
            </div>
            <div className="w-11/12 md:w-3/4">
              <div className="flex flex-col gap-2 text-justify">
                <p>Ketentuan penggunaan ini berlaku terhadap seluruh pengguna dan pengunjung situs magnet.or.id (Website Magnet), aplikasi Magnet, maupun setiap fitur, konten dan produk yang disediakan oleh Yayasan Mata Garuda (Mata Garuda atau kami) untuk selanjutnya secara bersama-sama akan disebut sebagai Platform. Pengguna, untuk selanjutnya disebut user atau Anda.</p>

                <p>Dengan menggunakan Platform, maka Anda telah setuju bahwa Anda telah membaca, memahami, menyetujui dan tunduk kepada Ketentuan Penggunaan ini dan Kebijakan Privasi kami termasuk setiap perubahan, modifikasi, penambahan dan/atau amandemennya dari waktu ke waktu.</p>
                <p>Bila Anda tidak setuju dengan Ketentuan Penggunaan ini, maka Anda tidak diperkenankan menggunakan Platform kami.</p>

                <p>Ketentuan Penggunaan ini dapat diubah, dimodifikasi, ditambah, atau diamandemen dari waktu ke waktu berdasarkan kebijakan kami, dan dengan tanpa adanya pemberitahuan terlebih dahulu kepada Anda. Anda setuju dan menerima kewajiban Anda untuk memeriksa Ketentuan Penggunaan ini secara rutin pada Platform kami.</p>

                <h3 className="font-bold text-xl">Pendaftaran Akun</h3>
                <p>Jika Anda membuat akun pada Platform kami:</p>

                <p>1. Anda harus berusia minimal 18 (delapan belas) tahun, atau telah menikah dan tidak berada di bawah perwalian atau pengampuan (Usia Dewasa), yang berarti Anda secara hukum memiliki kapasitas dan berhak untuk mengikatkan diri pada Ketentuan Penggunaan ini. Apabila Anda berada di bawah Usia Dewasa, maka tindakan Anda dalam mendaftar, mengakses, menggunakan dan melakukan aktivitas dalam Platform kami harus dalam sepengetahuan, pengawasan dan persetujuan yang sah dari orang tua, wali atau pengampu Anda, dan oleh karena itu, orang tua, wali atau pengampu Anda tersebut akan bertanggung jawab secara penuh atas setiap dan seluruh tindakan Anda dalam Platform kami.Dengan mendaftar dan/atau menggunakan Platform kami, maka orang tua, wali atau pengampu Anda dianggap telah membaca, memahami dan menyetujui, dan oleh karenanya, terikat pada Ketentuan Penggunaan ini; dan</p>
                <p>2. Anda setuju untuk menyatakan dan menjamin bahwa seluruh informasi yang Anda sampaikan pada saat proses pendaftaran adalah akurat, lengkap dan terkini.</p>

                <p>Keamanan dan kerahasiaan akun Anda, termasuk namun tidak terbatas pada seluruh informasi pribadi yang Anda berikan kepada kami melalui formulir pendaftaran pada Platform kami, sepenuhnya merupakan tanggung jawab pribadi Anda. Segala kerugian dan risiko yang timbul akibat atau sehubungan dengan kelalaian Anda dalam menjaga keamanan dan kerahasiaan akun Anda ditanggung oleh Anda sendiri dan/atau orang tua, wali atau pengampu Anda (bagi Pengguna yang berada di bawah Usia Dewasa). Dengan demikian, kami akan menganggap setiap penggunaan atau pesanan yang dilakukan melalui akun Anda sebagai permintaan yang sah dari Anda.</p>

                <h3 className="font-bold text-xl">Hak Kekayaan Intelektual</h3>
                <p>Anda mengakui dan menyetujui bahwa Platform kami, termasuk namun tidak terbatas pada semua isi dan konten yang terdapat pada Platform, nama, logo, kode program, desain, merek dagang, teknologi, basis data, proses dan model bisnis, dilindungi oleh hak ciYayasana, merek, paten, hak moral dan hak kekayaan intelektual lainnya dimiliki oleh dan terdaftar secara sah pada badan pemerintahan yang berwenang atas nama Yayasan Mata Garuda . Penggunaan isi dan konten Platform tunduk dan mengacu kepada Ketentuan Penggunaan ini. Isi, konten, gambar, suara, dan video pada Platform hanya boleh digunakan utuh apa adanya tanpa ada perubahan, untuk kebutuhan penggunaan pribadi dan non-komersial. Dilarang untuk melakukan pengunduhan (download) dan penggunaan lainnya yang melanggar Ketentuan Penggunaan ini.</p>

                <h3 className="font-bold text-xl">Akses Platform</h3>
                <p>Terdapat beberapa area dengan akses yang dibatasi. Kami memiliki hak untuk membatasi akses sebagian maupun keseluruhan Platform, sesuai dengan kebijakan yang berlaku sepanjang tidak mempengaruhi keamanan dan kenyamanan layanan pada Platform kami. Apabila digunakan User ID, maka User ID dan kata sandi sepenuhnya merupakan hak dan tanggung jawab Pengguna sekaligus pemilik User ID dan kata sandi tersebut. Semua penggunaan dan aktivitas terkait dengan User ID terkait akan menjadi tanggung jawab pemilik yang terdaftar dalam basis data kami. Kami memiliki hak untuk mengubah ataupun menonaktifkan User ID yang terkait apabila didapatkan melanggar Ketentuan Penggunaan ini atau atas permintaan dari pemilik dari User ID tersebut.</p>

                <h3 className="font-bold text-xl">Jaminan Layanan</h3>
                <p>Penggunaan Platform dengan segala risiko apa pun yang mungkin terjadi merupakan sepenuhnya tanggung jawab pribadi Pengguna, atau orang tua atau wali Pengguna (bagi Pengguna yang berada di bawah Usia Dewasa). Isi dan konten dalam Platform ini tersedia atas dasar sebagaimana adanya dan sebagaimana tersedia tanpa ada jaminan apapun termasuk dengan jaminan terkait untuk keperluan perdagangan, jual-beli, ataupun penggunaan khusus lainnya. Tidak terdapat jaminan bahwa akses terhadap Platform akan selalu bebas gangguan ataupun bebas dari kesalahan lainnya.</p>

                <h3 className="font-bold text-xl">Jaminan Privasi</h3>
                <p>Kami berkomitmen untuk menjaga keamanan dan kerahasiaan informasi pribadi yang diberikan Pengguna saat mengakses dan menggunakan Platform (Informasi Pribadi). Dalam hal ini, Informasi Pribadi diberikan oleh Pengguna secara sadar dan tanpa adanya tekanan atau paksaan dari pihak manapun, serta ikut bertanggung jawab penuh dalam menjaga kerahasiaan Informasi Pribadi tersebut. Informasi lebih lengkap terkait dengan keamanan privasi dapat dilihat pada bagian Kebijakan Informasi Pribadi.</p>

                <p>Anda dapat meminta kepada Kami untuk mengakses dan/atau mengoreksi Informasi Pribadi anda yang berada dalam kepemilikan dan penguasaan kami, dengan menghubungi kami di perincian yang disediakan di bawah ini.</p>

                <p>Anda dengan ini menyatakan bahwa Anda telah membaca dan memahami secara penuh konten dan sebab-akibat dari Kebijakan Informasi Pribadi kami, dan anda secara tidak dapat dicabut kembali telah menyetujui dan menerima untuk terikat dengan ketentuan-ketentuan dari Kebijakan Informasi Pribadi kami.</p>

                <h3 className="font-bold text-xl">Link Menuju Platform</h3>
                <p>Penggunaan tautan menuju Platform oleh pihak ketiga di luar Yayasan Mata Garuda  diperbolehkan selama tidak melanggar Ketentuan Penggunaan ini. Tidak terdapat jaminan terhadap ketepatan dan/atau ketersediaan konten berdasarkan informasi yang diberikan oleh pihak ketiga melalui tautan menuju Platform kami tersebut. Penggunaan tautan menuju Platform kami tidak disarankan bila terkait dengan aktivitas perdagangan, maupun sebagai suatu bentuk promosi dan/atau dukungan terhadap ide, produk, layanan, atau bentuk lainnya milik pihak ketiga. Kami berhak untuk menarik dan/atau membatalkan pemberian izin tautan milik pihak ketiga yang menuju ke Platform kami.</p>

                <p>Anda setuju bahwa, dalam hal terdapat tautan dan akses ke situs web dan konten lain yang disediakan oleh pihak ketiga di luar Yayasan Mata Garuda  pada Platform kami, kami tidak memiliki kendali atas konten situs web tersebut. Kami tidak bertanggung jawab atas bagian apapun dari isi konten yang disediakan oleh pihak ketiga. Akses atau penggunaan konten dari pihak ketiga tersebut oleh Pengguna merupakan bentuk persetujuan Anda untuk tunduk pada ketentuan penggunaan, kebijakan privasi serta kebijakan lainnya yang ditetapkan oleh pihak ketiga tersebut.</p>

                <h3 className="font-bold text-xl">Pengembangan Platform</h3>
                <p>Kami berhak untuk melakukan pengembangan, perubahan, dan/atau penyesuaian isi dan layanan Platform secara tetap ataupun untuk waktu tertentu, dengan atau tanpa adanya pemberitahuan sebelumnya kepada Pengguna.</p>

                <h3 className="font-bold text-xl">Tidak Ada Penggunaan yang Bertentangan dengan Hukum atau Terlarang</h3>
                <p>Anda hanya dapat menggunakan Platform jika tidak bertentangan dengan atau melanggar peraturan perundang-undangan yang berlaku. Dengan menggunakan Platform, Anda menerima tanggungjawab penuh bahwa penggunaan atau akses Anda ke Platform tidak melanggar peraturan perundang-undangan yang berlaku. Untuk melaksanakan ketentuan ini, kami memiliki hak untuk menolak keanggotaan, atau menangguhkan atau menghentikan akun Anda segera dan tanpa pemberitahuan sebelumnya atas kebijakan kami sendiri.</p>

                <h3 className="font-bold text-xl">Batasan Penggunaan</h3>
                <p>Dalam menggunakan Platform, Anda setuju untuk tidak atau mencoba untuk:</p>


                <p>1. Anda setuju untuk menyatakan dan menjamin bahwa seluruh informasi yang Anda sampaikan pada saat proses pendaftaran adalah akurat, lengkap dan terkini;</p>
                <p>2. Menyediakan informasi atau dokumen yang salah, tidak akurat, menyesatkan dan tidak lengkap;</p>
                <p>3. Membuat, mengubah, memodifikasi, mengadakan, dan/atau mengunggah informasi atau dokumen yang salah, tidak patut, menyesatkan dan tidak akurat pada atau melalui Platform;</p>
                <p>4. Dengan cara apapun menggunakan Platform atau konten apapun di dalamnya secara melawan hukum, menghina, mengancam, berbau asusila, rasis, menipu, melanggar hak pihak ketiga manapun, dan/atau melanggar norma kepatutan;</p>
                <p>5. Mentransmisikan, mengalihkan, atau menyebarkan komunikasi informasi atau dokumen yang salah, menyesatkan, menipu atau melanggar hukum;</p>
                <p>6. Menyamar sebagai Pengguna lain atau pihak lain mana pun;</p>
                <p>7. Secara tidak sah mengakses akun Pengguna lain tanpa memiliki izin, hak, atau kewenangan;</p>
                <p>8. Membuat salinan, memodifikasi, mendistribusikan, dan/atau mengirimkan dokumen atau informasi apa pun yang disimpan dalam Platform tanpa hak, wewenang, dan kapasitas dari pemilik sah atau pihak yang memiliki kewenangan;</p>
                <p>9. Melanggar hak kekayaan intelektual (sebagai contoh: hak ciYayasana, paten, merek dagang, dll.) milik Yayasan Mata Garuda  atau pihak ketiga manapun;</p>
                <p>10. Mengunggah virus, dokumen dan/atau data apa pun yang terinfeksi oleh virus, dan/atau malware yang dapat membahayakan atau mengganggu Platform;</p>
                <p>11. Mengganggu atau menghalangi pengoperasian Platform atau server atau jaringan yang terhubung ke Platform, termasuk memasang, mengirimkan, mentransmisikan, mengunggah, atau menyediakan materi apa pun yang berisi virus piranti lunak, worms atau kode komputer, file, atau program lain yang dirancang untuk mengganggu, menghancurkan atau membatasi kegunaan piranti lunak komputer atau perangkat keras atau peralatan telekomunikasi;</p>
                <p>12. Membuat tiruan dari Platform atau bagian dari Platform, atau secara sistematis mengunduh dan menyimpan konten apa pun yang terdapat dalam Platform;</p>
                <p>13. Secara tidak sah melakukan intersepsi atau penyadapan informasi, data, dan dokumen apa pun; dan</p>
                <p>14. Melanggar Ketentuan Penggunaan ini atau Kebijakan Privasi kami, termasuk amandemen dan/atau penambahannya.</p>

                <h3 className="font-bold text-xl">Laporan Kemungkinan Pelanggaran</h3>
                <p>Jika Anda menemukan konten apapun pada Platform yang Anda yakini melanggar hak kekayaan intelektual apapun, merusak nama baik, bersifat pornografis atau tidak senonoh, rasis, atau dengan cara-cara lain menyebabkan pelanggaran secara luas, atau yang merupakan peniruan identitas, penyalahgunaan, spam, atau sebaliknya menyalahi Ketentuan Penggunaan ini maupun peraturan perundang-undangan yang berlaku, mohon dapat dilaporkan kepada kami dengan cara menghubungi layanan pelanggan kami pada bagian kontak.</p>

                <h3 className="font-bold text-xl">Penangguhan dan Pembertahuan terhadap Akun Anda</h3>
                <p>Anda memahami dan setuju bahwa kami berhak, atas kebijakan kami sendiri, untuk menangguhkan, menghentikan, atau menutup akun Anda pada Platform dengan alasan apa pun dan setiap saat tanpa memberikan pemberitahuan sebelumnya kepada Anda dan/atau mendapat persetujuan sebelumnya dari anda. Kami dapat melakukan penangguhan, pemberhentian, atau penutupan akun Anda pada Platform dalam kondisi-kondisi tertentu, yang termasuk namun tidak terbatas pada (i) pelanggaran atas Ketentuan Penggunaan ini, (ii) adanya indikasi pelanggaran terhadap peraturan perundang-undangan yang berlaku, (iii) adanya indikasi pelanggaran apapun terhadap hak kekayaan intelektual milik Yayasan Mata Garuda  atau pihak ketiga manapun dan (iv) berdasarkan permintaan yang Anda sampaikan kepada kami.</p>

                <h3 className="font-bold text-xl">Tanggung Jawab Pengguna</h3>
                <p>Anda bertanggung jawab secara penuh atas setiap kerugian dan/atau klaim yang timbul dari penggunaan Platform melalui akun Anda, baik oleh Anda atau pihak lain yang menggunakan akun Anda, dengan cara yang bertentangan dengan Ketentuan Penggunaan ini, peraturan perundang-undangan yang berlaku, pelanggaran hak kekayaan intelektual dan/atau aktivitas lain yang merugikan publik dan/atau pihak lain manapun atau yang dapat atau dianggap dapat merusak reputasi kami. Kami tidak menjamin bahwa Platform akan aman atau terbebas dari bug atau virus. Anda bertanggung jawab untuk mengatur teknologi informasi, program komputer, serta platform yang Anda gunakan untuk mengakses Platform kami. Anda harus menggunakan perangkat lunak anti-virus Anda sendiri.</p>

                <h3 className="font-bold text-xl">Batasan Tanggung Jawab</h3>
                <p>Anda memahami dan menyetujui bahwa layanan dalam Platform kami disediakan sebagaimana tersedia. Sejauh dimungkinkan oleh peraturan perundang-undangan yang berlaku, kami tidak, baik secara tegas maupun tersirat, membuat suatu jaminan, mendukung atau memberikan pernyataan dalam bentuk apapun sehubungan dengan pemberian layanan dalam Platform kami. Hal ini termasuk namun tidak terbatas pada jaminan tersirat sehubungan dengan penjualan dan kesesuaian untuk tujuan tertentu dan tidak adanya pelanggaran (implied warranties of merchantability and suitability for a particular purpose and no infringement), dan jaminan bahwa akses untuk atau penggunaan Platform tidak akan diganggu atau tidak ada kesalahan (error) atau cacat pada Platform akan diperbaiki.</p>

                <p>Sejauh diperbolehkan oleh peraturan perundang-undangan yang berlaku, dalam hal apapun kami tidak akan bertanggung jawab atas segala bentuk kerugian atau kerusakan, baik yang terjadi secara langsung atau tidak langsung, atau hilangnya data, penggunaan, goodwill, atau kerugian tidak berwujud lainnya, yang diakibatkan karena (i) akses Anda untuk menggunakan atau penggunaan atas atau ketidakmampuan untuk mengakses atau menggunakan Platform, (ii) setiap tindakan atau konten yang disediakan oleh pihak ketiga yang berkaitan dengan Platform, (iii) akses, penggunaan atau perubahan yang tidak sah atas konten apapun pada Platform atau (iv) permasalahan-permasalahan lainnya yang muncul bukan disebabkan oleh pelanggaran kami terhadap Ketentuan Penggunaan ini. Sejauh diperbolehkan oleh peraturan perundang-undangan yang berlaku, total tanggung jawab Yayasan Mata Garuda atas segala klaim yang timbul dari pelanggarannya terhadap Ketentuan Penggunaan ini, hanya terbatas pada jumlah yang Anda bayarkan kepada Yayasan Mata Garuda untuk menggunakan layanan pada Platform kami.</p>

                <p>Dengan menggunakan dan mengakses Platform kami, Anda setuju bahwa setiap jawaban, informasi, materi dan konten yang ditampilkan pada Platform telah sesuai dengan pengetahuan dan maksud terbaik kami, dan Anda setuju untuk memverifikasi secara independen keaslian dan kebenaran jawaban, informasi, materi dan konten yang diterbitkan tersebut. Setiap tindakan yang Anda ambil atas dasar informasi yang terdapat di Platform adalah tanggung jawab dan risiko Anda sendiri dan kami tidak bertanggung jawab atas konsekuensi tindakan yang Anda lakukan.</p>

                <h3 className="font-bold text-xl">Keadaan Kahar</h3>
                <p>Kami dapat diinterupsi oleh kejadian di luar kewenangan atau kontrol kami, termasuk namun tidak terbatas pada bencana alam, gangguan listrik, gangguan telekomunikasi, kebijakan pemerintah, persebaran virus, dan lain-lain (Keadaan Kahar). Anda setuju untuk membebaskan dan melepaskan Yayasan Mata Garuda  dari setiap klaim dan tuntutan, jika kami tidak dapat memfasilitasi akses dan/atau penggunaan Platform, baik sebagian maupun seluruhnya, karena suatu Keadaan Kahar.</p>

                <h3 className="font-bold text-xl">Hukum yang Berlaku</h3>
                <p>Ketentuan Penggunaan ini diatur berdasarkan dan ditafsirkan sesuai dengan hukum Republik Indonesia. Anda setuju bahwa setiap dan seluruh sengketa yang timbul dari atau sehubungan dengan Ketentuan Penggunaan ini (Sengketa) akan diupayakan untuk diselesaikan secara damai dalam waktu 60 hari. Dalam hal penyelesaian secara damai tidak dapat dicapai dalam jangka waktu tersebut, Anda setuju untuk tunduk kepada wilayah hukum non-eksklusif Pengadilan Jakarta Selatan. Penyerahan pada yurisdiksi tersebut oleh Anda tidak akan (dan tidak akan ditafsirkan sebagai) membatasi hak kami untuk memulai tindakan hukum atau proses hukum atas Sengketa di hadapan atau dengan pengadilan negeri lain atau pengadilan lain di jurisdiksi manapun, baik secara bersamaan atau terpisah, sebagaimana diizinkan oleh hukum yang berlaku dan sebagaimana ditentukan oleh kami atas kebijaksanaan kami sendiri.</p>

                <h3 className="font-bold text-xl">Pengesampingan, Keterpisahan, dan Pengalihan</h3>
                <p>Kegagalan kami untuk melaksanakan ketentuan apapun dari Ketentuan Penggunaan ini bukan merupakan pengesampingan atas hak kami untuk melaksanakan ketentuan tersebut di kemudian hari. Jika ada ketentuan dalam Ketentuan Penggunaan ini yang terbukti tidak dapat diberlakukan, maka ketentuan-ketentuan lain dari Ketentuan Penggunaan ini akan tetap berlaku penuh dan dapat diberlakukan, dan ketentuan atau ketentuan-ketentuan yang tidak dapat diberlakukan tersebut akan diganti untuk mencerminkan maksud kami sedekat mungkin. Anda tidak dapat mengalihkan hak Anda berdasarkan Ketentuan Penggunaan ini, dan segala upaya untuk melakukan hal tersebut akan batal.</p>


                <p>Kami dapat mengalihkan hak kami kepada afiliasi atau anak perusahaan kami, atau kepada setiap penerus yang memiliki kepentingan bisnis terkait dengan layanan kami.</p>

                <h3 className="font-bold text-xl">Keseluruhan Perjanjian</h3>
                <p>Persyaratan ini merupakan seluruh perjanjian antara Anda dan Yayasan Mata Garuda berkenaan dengan hal-hal yang diatur dalam Ketentuan Penggunaan ini, dan meniadakan dan menggantikan versi sebelumnya dari Ketentuan Penggunaan ini.</p>

                <h3 className="font-bold text-xl">Perubahan Ketentuan Penggunaan</h3>
                <p>Kami berhak untuk mengubah, memodifikasi, menambahkan atau mengamandemen Ketentuan Penggunaan ini dari waktu ke waktu tanpa memberikan pemberitahuan kepada Pengguna terlebih dahulu, dan setiap perubahan tersebut akan ditampilkan pada halaman ini. Dengan tetap menggunakan Platform kami setelah adanya perubahan, modifikasi, penambahan atau amandemen terhadap Ketentuan Penggunaan, Anda memberikan persetujuan Anda terhadap perubahan, modifikasi, penambahan dan/atau amandemen tersebut. kami menyarankan Anda untuk secara berkala melihat dan meninjau setiap perubahan, modifikasi, penambahan dan/atau amandemen atas Ketentuan Penggunaan ini. Ketika perubahan, modifikasi, penambahan dan/atau amandemen tersebut dilakukan, kami akan mengubah tanggal pada bagian “Terakhir diubah” di atas dan memasang Ketentuan Penggunaan yang baru.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TnCView;
