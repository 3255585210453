import React, { Component } from 'react'
import NesiaHubProvider from './core/Provider'
import RootStore from './core/model/RootStore'

const rootStore = new RootStore()

class App extends Component {
    render() {
        return (
            <NesiaHubProvider store={rootStore.getStores()}></NesiaHubProvider>
        )
    }
}
export default App