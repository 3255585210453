import React from "react";
import { Icons } from "../../../entity/Icons";
import { MitraImage } from "../../../entity/MitraImage";

class SectionTestimoni extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="z-30 
      flex flex-col bg-magnet-secondary rounded-3xl justify-center gap-2"
      >
        <div className="flex flex-row my-8 mx-16 ">
          <div className="flex m-4 w-3/12 ">
            <p className="text-4xl  text-white font-semibold">
              Testimoni Mitra Mata Garuda
            </p>
          </div>{" "}
          <div className="flex-col w-1/3 mx-16 rounded-xl hover:border-magnet-primary duration-500 ">
            <Icons.BOLT className="my-2 mb-4" />
            <p className=" text-white hover:text-magnet-primary duration-500 cursor-pointer">
              Sangat memudahkan untuk proses perekrutan. Terima kasih TalentHub
              Mata Garuda.
            </p>

            <div className="flex flex-row gap-2 justify-start">
              <div className="flex flex-row gap-2 mt-4 justify-start">
                <img
                  className="w-16 h-16 mt-4"
                  alt=""
                  src={MitraImage.PP1}
                ></img>
                <div className="flex flex-col gap-1 mt-4">
                  <p className="text-white font-bold text-sm text-left">
                    David
                  </p>
                  <p className="text-white text-sm text-left">
                    VP Human Capital
                  </p>
                  <p className="text-magnet-secondarydark text-sm font-semibold text-left">
                    Go-jek Indonesia
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-2 mt-4 justify-start">
              <Icons.STAR className="fill-white" />
              <Icons.STAR className="fill-white" />
              <Icons.STAR className="fill-white" />
              <Icons.STAR className="fill-white" />
              <Icons.STAR className="fill-white" />
            </div>
          </div>{" "}
          <div className="flex-col w-1/3 rounded-xl hover:border-magnet-primary duration-500 ">
            <Icons.BOLT className="my-2 mb-4" />
            <p className="text-white hover:text-magnet-primary duration-500 cursor-pointer">
              Sangat memudahkan untuk proses perekrutan. Terima kasih TalentHub
              Mata Garuda.
            </p>

            <div className="flex flex-row gap-2 justify-start">
              <div className="flex flex-row gap-2 mt-4 justify-start">
                <img
                  className="w-16 h-16 mt-4"
                  alt=""
                  src={MitraImage.PP2}
                ></img>
                <div className="flex flex-col gap-1 mt-4">
                  <p className="text-white font-bold text-sm text-left">
                    dr. Putro Muhammad
                  </p>
                  <p className="text-white text-sm text-left">
                    Pengurus EMT-IDI
                  </p>
                  <p className="text-magnet-secondarydark text-sm font-semibold text-left">
                    Peneliti IDI
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-2 mt-4 justify-start mb-4">
              <Icons.STAR className="fill-white" />
              <Icons.STAR className="fill-white" />
              <Icons.STAR className="fill-white" />
              <Icons.STAR className="fill-white" />
              <Icons.STAR className="fill-white" />
            </div>
          </div>{" "}
        </div>{" "}
      </div>
    );
  }
}

export default SectionTestimoni;
