import React from "react";
import HeaderLoginMitraView from "./HeaderLoginMitraView";
import { useNavigate } from "react-router-dom";

class HeaderLoginMitraController extends React.Component {
  constructor(props) {
    super(props);
    this.navigator = this.navigator.bind(this);
    this.state = {};
  }

  navigator(path) {
    this.props.navigate(path);
  }

  render() {
    return <HeaderLoginMitraView />;
  }
}

export default (props) => {
  const navigate = useNavigate();
  return <HeaderLoginMitraController {...props} navigate={navigate} />;
};
