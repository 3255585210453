import React from 'react'
import * as APP_DATA from '../../../core/ConstantData';
import { Icons } from '../../entity/Icons';
import { Page } from '../../entity/Pages';
import { MitraImage } from '../../entity/MitraImage';


class FooterView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }

  }

  render() {
    const {
      navigator
    } = this.props;

    return (
      <React.Fragment>
        <div id='mgnt-footer' className='bg-white z-0 flex justify-center py-12 font-poppins'>
          <div className='flex flex-col xl:items-end relative w-10/12'>
            <div className='flex flex-row w-full'>
              <div className='flex flex-row items-center justify-center gap-x-2 md:gap-x-4 lg:gap-x-8 xl:gap-x-12 w-full'>
                <div>
                  <img alt='' src={APP_DATA.NEW_LOGO_MAGNET} className='cursor-pointer w-32 h-auto' onClick={() => navigator(Page.HOME)}></img>
                </div>
                {/* <div>
                  <img alt='' src={MitraImage.logoiigf} className='cursor-pointer w-32'></img>
                </div> */}
                {/* <div>
                  <img alt='' src={MitraImage.logopii} className='cursor-pointer w-32'></img>
                </div> */}
                <div>
                  <img alt='' src={MitraImage.logomatagaruda} className='cursor-pointer w-24'></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex items-center justify-center bg-gradient-to-r from-magnet-rose-normal to-magnet-orange-normal'>
          <div className='flex flex-col xl:items-end relative w-10/12 py-9'>
            <div className='flex flex-row max-xl:flex-col justify-between'>
              <div className='xl:w-7/12 max-xl:w-full flex max-xl:flex-row'>
                <p className="max-xl:text-center text-white max-xl:mt-4">Semua Hak Dilindungi Undang-Undang. Merek Mata Garuda Network adalah merek dagang terdaftar Indonesia dan negara lainnya. Hubungi kami untuk kerjasama dan informasi lebih lanjut.</p>
              </div>
              <div className='max-xl:mt-8 flex flex-row gap-6 fill-white xl:w-3/12 max-xl:w-full xl:justify-end max-xl:justify-center'>
                <Icons.LINKEDIN className='cursor-pointer' />
                <Icons.INSTAGRAM className='cursor-pointer' />
                <Icons.TWITTER className='cursor-pointer' />
                <Icons.YOUTUBE className='cursor-pointer' />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
      // <div className="w-[1440px] h-32 px-[76px] py-9 bg-gradient-to-r from-rose-600 to-rose-600 justify-between items-center gap-2.5 inline-flex">
      //   <div className="w-[849px] text-white text-opacity-75 text-[18px] font-semibold leading-7">Semua Hak Dilindungi Undang-Undang. Merek Mata Garuda Network adalah merek dagang terdaftar Indonesia dan negara lainnya. Hubungi kami untuk kerjasama dan informasi lebih lanjut.</div>
      //   <div className="justify-start items-start gap-7 flex">
      //     <div className="w-6 justify-start items-start gap-[30px] flex">
      //       <div className="w-6 h-6 relative" />
      //     </div>
      //     <div className="w-[24.15px] justify-start items-start gap-[30px] flex" />
      //     <div className="w-[24px] justify-start items-start gap-[30px] flex" />
      //     <div className="w-[24.24px] justify-start items-start gap-[30px] flex" />
      //   </div>
      // </div>
    )
  }
}

export default FooterView