import React from "react";
import HeaderMitraView from "./HeaderMitraView";
import { useNavigate } from "react-router-dom";

class HeaderMitraController extends React.Component {
  constructor(props) {
    super(props);
    this.navigator = this.navigator.bind(this);
    this.state = {};
  }

  navigator(path) {
    this.props.navigate(path);
  }

  render() {
    return <HeaderMitraView navigator={this.navigator} />;
  }
}

export default (props) => {
  const navigate = useNavigate();
  return <HeaderMitraController {...props} navigate={navigate} />;
};
