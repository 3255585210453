import React from "react";
import { MitraImage } from "../../../entity/MitraImage";

class SectionPartnerKami extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="flex flex-col gap-8 mt-16 bg-magnet-secondarylight w-screen">
        <div className="flex items-center gap-2  mt-16  justify-center">
          <p className="text-4xl font-semibold text-center">Partner Kami</p>
          <br />
        </div>
        <div className="flex flex-row justify-center  rounded-3xl gap-6">
          <div>
            <img
              className="bg-white rounded-lg p-4"
              alt=""
              src={MitraImage.mitra1}
            ></img>
          </div>
          <div>
            <img
              className="bg-white rounded-lg p-4"
              alt=""
              src={MitraImage.mitra2}
            ></img>
          </div>
          <div>
            <img
              className="bg-white rounded-lg p-4"
              alt=""
              src={MitraImage.mitra3}
            ></img>
          </div>
          <div>
            <img
              className="bg-white rounded-lg p-4"
              alt=""
              src={MitraImage.mitra4}
            ></img>
          </div>
        </div>
        <div className="flex flex-row justify-center  rounded-3xl gap-6">
          <div>
            <img
              className="bg-white rounded-lg p-4"
              alt=""
              src={MitraImage.mitra5}
            ></img>
          </div>
          <div>
            <img
              className="bg-white rounded-lg p-4"
              alt=""
              src={MitraImage.mitra6}
            ></img>
          </div>{" "}
          <div>
            <img
              className="bg-white rounded-lg p-4 "
              alt=""
              src={MitraImage.mitra7}
            ></img>
          </div>
        </div>
        <div className="flex items-center gap-2 mb-16 justify-center">
          <p className="text-lg font-semibold text-magnet-secondarydark text-center">
            180+ Hiring Parnter Lainnya
          </p>
          <br />
        </div>
      </div>
    );
  }
}

export default SectionPartnerKami;
