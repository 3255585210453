import React from "react";
import { MitraImage } from "../../../entity/MitraImage";

class SectionIndonesia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-center ">
          <div
            className="absolute z-0
         -left-40 bottom-96 object-bottom-left "
          >
            <img className=" mt-8" alt="" src={MitraImage.vector1}></img>
          </div>
          <div className="flex items-center gap-2 z-20">
            <p className="text-3xl text-center font-semibold ">
              Anggota Mata Garuda tersebar di berbagai daerah untuk
              berkontribusi positif memberikan nilai tambah bagi perkembangan
              Indonesia
            </p>
            <br />
          </div>
        </div>{" "}
        <img
          className="w-full mt-8 z-20"
          alt=""
          src={MitraImage.indonesia}
        ></img>
      </div>
    );
  }
}

export default SectionIndonesia;
