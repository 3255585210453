import * as APP_DATA from "./ConstantData";
import { CompanyList } from "./entity/CompanyList";
import { CourseThumbnail } from "./entity/CourseThumbnail";

import img_a from '../image/news/9-poin-di-perppu-ciptaker-yang-ditolak-partai-buruh-via-demo-hari-ini_169.jpeg'
import img_b from '../image/news/dasar-hukum-mahkamah-agung-hingga-tugas-pokok-dan-fungsinya_169.jpeg'
import img_c from '../image/news/mal-pelayanan-publik-mpp-bone_169.jpeg'
import img_d from '../image/news/masa-depan-muslim-moderat-di-indonesia-zbu.webp'

import { Icons } from "./entity/Icons";

export const DUMMYLOWONGAN = [
  {
    image: CompanyList.Company1,
    nama: "Data Analyst Jakarta",
    perusahaan: "PT Home Credit Indonesia",
    alamat: "Jakarta",
    country: "ID",
    salarymin: 50000000,
    salarymax: 80000000,
    experiencemin: 5,
    time: "60 menit yang lalu",
    jenis: "Kontrak",
  },
  {
    image: CompanyList.Company1,
    nama: "Manager Depok",
    perusahaan: "PT Home Credit Indonesia",
    alamat: "Depok",
    country: "ID",
    salarymin: 50000000,
    salarymax: 80000000,
    experiencemin: 5,
    time: "60 menit yang lalu",
    jenis: "Kontrak",
  }, {
    image: CompanyList.Company2,
    ver: <Icons.BADGE className="h-4 fill-magnet-primary" />,
    nama: "System Analist",
    perusahaan: "Meta Plarforms",
    alamat: "Jakarta",
    country: "ID",
    salarymin: 75000000,
    experiencemin: 8,
    experiencemax: 10,
    time: "60 menit yang lalu",
    jenis: "Full Time",
  }, {
    image: CompanyList.Company3,
    nama: "Web Trainer",
    ver: <Icons.BADGE className="h-4 fill-magnet-primary" />,
    perusahaan: "Binar Academy",
    alamat: "Jakarta",
    country: "ID",
    login: true,
    salarymin: 50000000,
    experiencemin: 2,
    experiencemax: 3,
    time: "60 menit yang lalu",
    jenis: "Full Time",
  }, {
    image: CompanyList.Company4,
    nama: "Name of job",
    ver: <Icons.BADGE className="h-4 fill-magnet-primary" />,
    perusahaan: "Unilever",
    alamat: "Jakarta",
    country: "ID",
    salarymin: 50000000,
    experiencemin: 5,
    time: "60 menit yang lalu",
    jenis: "Full Time",
  },
];

export const DUMMYCOURSE = [
  {
    thumbnail: CourseThumbnail.Course1,
    course: "Menjadi Guru Blended Learning",
    harga: 100000,
    hargacoret: 150000,
    diskon: 30,
    lembaga: "PT Aveceena Sejahtera Abadi",
    rating: 4.6,
    peserta: 1083,
    tipe: "Kelas",
  },
  {
    thumbnail: CourseThumbnail.Course1,
    course: "Menjadi Guru Blended Learning",
    harga: 100000,
    hargacoret: 150000,
    diskon: 30,
    lembaga: "PT Aveceena Sejahtera Abadi",
    rating: 4.6,
    peserta: 1083,
    tipe: "Kelas",
  },
  {
    thumbnail: CourseThumbnail.Course1,
    course: "Menjadi Guru Blended Learning",
    harga: 100000,
    hargacoret: 150000,
    diskon: 30,
    lembaga: "PT Aveceena Sejahtera Abadi",
    rating: 4.6,
    peserta: 1083,
    tipe: "Kelas",
  },
  {
    thumbnail: CourseThumbnail.Course1,
    course: "Menjadi Guru Blended Learning",
    harga: 100000,
    hargacoret: 150000,
    diskon: 30,
    lembaga: "PT Aveceena Sejahtera Abadi",
    rating: 4.6,
    peserta: 1083,
    tipe: "Kelas",
  },
  {
    thumbnail: CourseThumbnail.Course1,
    course: "Menjadi Guru Blended Learning",
    harga: 100000,
    hargacoret: 150000,
    diskon: 30,
    lembaga: "PT Aveceena Sejahtera Abadi",
    rating: 4.6,
    peserta: 1083,
    tipe: "Kelas",
  },
  {
    thumbnail: CourseThumbnail.Course1,
    course: "Menjadi Guru Blended Learning",
    harga: 100000,
    hargacoret: 150000,
    diskon: 30,
    lembaga: "PT Aveceena Sejahtera Abadi",
    rating: 4.6,
    peserta: 1083,
    tipe: "Kelas",
  },
  {
    thumbnail: CourseThumbnail.Course1,
    course: "Menjadi Guru Blended Learning",
    harga: 100000,
    hargacoret: 150000,
    diskon: 30,
    lembaga: "PT Aveceena Sejahtera Abadi",
    rating: 4.6,
    peserta: 1083,
    tipe: "Webinar",
  },
  {
    thumbnail: CourseThumbnail.Course1,
    course: "Menjadi Guru Blended Learning",
    harga: 100000,
    hargacoret: 150000,
    diskon: 30,
    lembaga: "PT Aveceena Sejahtera Abadi",
    rating: 4.6,
    peserta: 1083,
    tipe: "Webinar",
  },
  {
    thumbnail: CourseThumbnail.Course1,
    course: "Menjadi Guru Blended Learning",
    harga: 100000,
    hargacoret: 150000,
    diskon: 30,
    lembaga: "PT Aveceena Sejahtera Abadi",
    rating: 4.6,
    peserta: 1083,
    tipe: "Webinar",
  },
  {
    thumbnail: CourseThumbnail.Course1,
    course: "Menjadi Guru Blended Learning",
    harga: 0,
    lembaga: "TalentHub Mata Garuda",
    rating: 4.6,
    peserta: 1083,
    tipe: "MG Talks",
  },
  {
    thumbnail: CourseThumbnail.Course1,
    course: "Menjadi Guru Blended Learning",
    harga: 0,
    diskon: "Terbatas",
    lembaga: "TalentHub Mata Garuda",
    rating: 4.6,
    peserta: 1083,
    tipe: "MG Talks",
  },
  {
    thumbnail: CourseThumbnail.Course1,
    course: "Menjadi Guru Blended Learning",
    harga: 0,
    diskon: "Terbatas",
    lembaga: "TalentHub Mata Garuda",
    rating: 4.6,
    peserta: 1083,
    tipe: "MG Talks",
  },
];

export const DUMMYCOMPANY = [
  {
    perusahaan: "PT Home Credit Indonesia",
    motto: "Cicilan ringan tanpa beban",
    alamat: "Jakarta Selatan",
    country: "ID",
    image: CompanyList.Company1,
    lowongan: "2 Job",
  },
  {
    perusahaan: "Meta Platforms ",
    ver: <Icons.BADGE className="h-6 fill-magnet-primary" />,
    motto: "Social media office",
    alamat: "Jakarta",
    country: "ID",
    image: CompanyList.Company2,
    lowongan: "1 Job",
  },
  {
    perusahaan: "Binar Academy",
    motto: "Saatnya #MeretasBatas Kemampuan",
    alamat: "City",
    country: "Country ID",
    image: CompanyList.Company3,
    lowongan: "1 Job",
  },
  {
    perusahaan: "Unilever ",
    ver: <Icons.BADGE className="h-6 fill-magnet-primary" />,
    motto: "We meet everyday needs for nutrition",
    alamat: "Jakarta",
    country: "ID",
    image: CompanyList.Company4,
    lowongan: "1 Job",
  },
  {
    perusahaan: "Groupon",
    motto: "Find local delas",
    alamat: "Chicago",
    country: "US",
    image: CompanyList.Company5,
    lowongan: "1 Job",
  },

  {
    perusahaan: "Maximtaxy",
    ver: <Icons.BADGE className="h-6 fill-magnet-primary" />,
    motto: "Mulai hasilkan uang sekarang juga",
    alamat: "City",
    country: "Country ID",
    image: CompanyList.Company6,
    lowongan: "1 Job",
  },
  {
    perusahaan: "Groupon",
    motto: "Find local delas",
    alamat: "Chicago",
    country: "US",
    image: CompanyList.Company5,
    lowongan: "1 Job",
  },
  {
    perusahaan: "Unilever ",
    ver: <Icons.BADGE className="h-6 fill-magnet-primary" />,
    motto: "We meet everyday needs for nutrition",
    alamat: "Jakarta",
    country: "ID",
    image: CompanyList.Company4,
    lowongan: "1 Job",
  },
  {
    perusahaan: "Maximtaxy",
    ver: <Icons.BADGE className="h-6 fill-magnet-primary" />,
    motto: "Mulai hasilkan uang sekarang juga",
    alamat: "City",
    country: "Country ID",
    image: CompanyList.Company6,
    lowongan: "1 Job",
  },
];

export const HISTORYAGENDA = [
  {
    date: "7 Februari 2023",
    timeY: "16.00 - 17.30",
    yellow: "Collage-friendly cooking classes",
    timeGri: "16.00 - 17.30",
    green: "Community service events",
    timeGre: "16.00 - 17.30",
    grey: "Create a student mentorship program",
    timeR: "16.00 - 17.30",
    red: "Career fairs",
  },
  {
    date: "6 Februari 2023",
    time: "16.00 - 17.30",

    timeY: "16.00 - 17.30",
    yellow: "Speed interviews",

    timeR: "16.00 - 17.30",
    red: "Industry networking events",

    timeO: "16.00 - 17.30",
    orange: "Meet your (professional) mentor",
  },
  {
    date: "5 Februari 2023",

    timeGre: "16.00 - 17.30",
    grey: "Skills seminar",
  },
];

export const DUMMY_KONTEN = [
  {
    img: img_b,
    author: "Ahmad Yani",
    title:
      "Mengembalikan Marwah MA",
    content:
      "Fenomena korupsi berjamaah di lingkungan Mahkamah Agung (MA) yang melibatkan beberapa oknum hakim agung pada akhir tahun 2022 patut disesalkan. Institusi MA merupakan benteng terakhir dalam penegakan hukum dan keadilan di Indonesia. Praktik korupsi membuat institusi MA menjadi oleng dan akan mengalami disfungsi jika tidak dilakukan pembenahan secara serius dan menyeluruh. Jika dicermati dalam Undang-Undang Kekuasaan Kehakiman tergambar beberapa fungsi MA.",
    date: "16 Januari 2023",
    time: "09:50 WIB",
    url: "https://news.detik.com/kolom/d-6517388/mengembalikan-marwah-ma"
  },
  {
    img: img_c,
    author: "Asep Cahyana",
    title:
      "Mal Pelayanan Publik, Cukupkah?",
    content:
      "Awal Desember 2022, 26 Mal Pelayanan Publik (MPP) di 26 kabupaten/kota diresmikan Menteri PANRB. Peresmian itu disaksikan Wakil Presiden K.H. Maruf Amin. Jumlah MPP menjadi 103 MPP di seluruh wilayah Indonesia. Menurut Wapres, keberadaan 103 MPP itu bukan hanya menandakan pemenuhan target pemerintah pada 2022 melainkan juga bentuk konkret kolaborasi antarsektor menuju integrasi pelayanan publik berkualitas.",
    date: "03 Jan 2023",
    time: "13:10 WIB",
    url: "https://news.detik.com/kolom/d-6495808/mal-pelayanan-publik-cukupkah"
  },
  {
    img: APP_DATA.CITY,
    author: "Ridwan",
    title:
      "Masa Depan Muslim Moderat di Indonesia",
    content:
      "BAGAIMANA kita memprediksi wajah muslim moderat di 2023 dan tahun-tahun mendatang? Apakah trennya akan positif? Atau wajah konservatisme agama akan menguat? Tulisan singkat ini akan mendiskusikan beberapa pertanyaan ini dengan melihat lintasan sejarah Islam, terutama paska reformasi, dan mengulik aspek sosio-politik dari muslim di Tanah Air.",
    date: "27 Januari 2023",
    time: "15:23 WIB",
    url: "https://nasional.sindonews.com/read/1007133/18/masa-depan-muslim-moderat-di-indonesia-1674806484"
  },
  {
    img: img_a,
    author: "Ahmad Yani",
    title:
      "Memberi Legitimasi pada Perppu Cipta Kerja",
    content:
      "Presiden Joko Widodo telah menerbitkan Peraturan Pemerintah Pengganti Undang-Undang (Perppu) No. 2 Tahun 2022 tentang Cipta Kerja (Perppu Cipta Kerja) untuk menjamin kepastian berusaha di tengah badai resesi yang menghadang berbagai negara termasuk Indonesia.",
    date: "13 Februari 2023",
    time: "11:30 WIB",
    url: "https://news.detik.com/kolom/d-6565755/memberi-legitimasi-pada-perppu-cipta-kerja"
  },
];


export const DUMMY_SALAM_PENGGUNA = [{
  FullName: '',
  University: '',
  Description: '',
  City: '',
  Country: '',
  Picture: '',
  PK: '',
  Expertise: '',
  Whatsapp: '',
  Instagram: '',
  Linkedin: '',
  Twitter: '',
},
]