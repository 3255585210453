import React from "react";
import { AboutusImage } from "../../../entity/AboutusImage";

class AboutusTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    return (

      <div className="w-full flex flex-col">
        <div className="w-full bg-zinc-100 px-8 md:px-32 py-8">
          <div className="flex flex-col ">
            <h2 className="w-fit text-left text-3xl md:text-5xl font-bold leading-snug bg-gradient-to-r from-magnet-rose-normal to-magnet-orange-normal  bg-clip-text text-transparent">Meet Our Team </h2>
          </div>
        </div>
        <div className="w-full px-8 md:px-32 py-8">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-x-7 gap-y-10">
            <div className="flex flex-col">
              <img className="object-cover rounded-2xl mb-3 h-[341px]" src={AboutusImage.board1} alt="Team person 1" />
              <div className="flex flex-col pl-3 text-zinc-600">
                <p className="font-bold text-[18px] mb-1">Leonardo Gavaza</p>
                <p className="text-sm">Ketua Umum Mata Garuda</p>
              </div>
            </div>
            <div className="flex flex-col">
              <img className="object-cover rounded-2xl mb-3 h-[341px]" src={AboutusImage.board2} alt="Team person 1" />
              <div className="flex flex-col pl-3 text-zinc-600">
                <p className="font-bold text-[18px] mb-1">Wahyu Bagus Yuliantok</p>
                <p className="text-sm">Sekretaris Jenderal</p>
              </div>
            </div>
            <div className="flex flex-col">
              <img className="object-cover rounded-2xl mb-3 h-[341px]" src={AboutusImage.board3} alt="Team person 1" />
              <div className="flex flex-col pl-3 text-zinc-600">
                <p className="font-bold text-[18px] mb-1">Hamzah Assaddudin</p>
                <p className="text-sm">Direktur Engagement Alumni & Komunikasi</p>
              </div>
            </div>
            <div className="flex flex-col">
              <img className="object-cover rounded-2xl mb-3 h-[341px]" src={AboutusImage.board4} alt="Team person 1" />
              <div className="flex flex-col pl-3 text-zinc-600">
                <p className="font-bold text-[18px] mb-1">Aryo Adilantip</p>
                <p className="text-sm">Direktur Strategic Alliance dan Monetization</p>
              </div>
            </div>
            <div className="flex flex-col">
              <img className="object-cover rounded-2xl mb-3 h-[341px]" src={AboutusImage.board5} alt="Team person 1" />
              <div className="flex flex-col pl-3 text-zinc-600">
                <p className="font-bold text-[18px] mb-1">Albertus Andhika</p>
                <p className="text-sm">Direktur Pengembangan Organisasi</p>
              </div>
            </div>
            <div className="flex flex-col">
              <img className="object-cover rounded-2xl mb-3 h-[341px]" src={AboutusImage.board6} alt="Team person 1" />
              <div className="flex flex-col pl-3 text-zinc-600">
                <p className="font-bold text-[18px] mb-1">Farina Rose</p>
                <p className="text-sm">Direktur Center of Excellence</p>
              </div>
            </div>
            <div className="flex flex-col">
              <img className="object-cover rounded-2xl mb-3 h-[341px]" src={AboutusImage.board7} alt="Team person 1" />
              <div className="flex flex-col pl-3 text-zinc-600">
                <p className="font-bold text-[18px] mb-1">Boyke Rahmanda</p>
                <p className="text-sm">General Treasury</p>
              </div>
            </div>
            <div className="flex flex-col">
              <img className="object-cover rounded-2xl mb-3 h-[341px]" src={AboutusImage.board8} alt="Team person 1" />
              <div className="flex flex-col pl-3 text-zinc-600">
                <p className="font-bold text-[18px] mb-1">M Adi Pranata</p>
                <p className="text-sm">Chief of Staff</p>
              </div>
            </div>
            <div className="flex flex-col">
              <img className="object-cover rounded-2xl mb-3 h-[341px]" src={AboutusImage.team1} alt="Team person 1" />
              <div className="flex flex-col pl-3 text-zinc-600">
                <p className="font-bold text-[18px] mb-1">Ifa Alif</p>
                <p className="text-sm">SEVP Magnet</p>
              </div>
            </div>
            <div className="flex flex-col">
              <img className="object-cover rounded-2xl mb-3 h-[341px]" src={AboutusImage.team2} alt="Team person 1" />
              <div className="flex flex-col pl-3 text-zinc-600">
                <p className="font-bold text-[18px] mb-1">Muhammad Razif</p>
                <p className="text-sm">VP Technology</p>
              </div>
            </div>
            <div className="flex flex-col">
              <img className="object-cover rounded-2xl mb-3 h-[341px]" src={AboutusImage.team3} alt="Team person 1" />
              <div className="flex flex-col pl-3 text-zinc-600">
                <p className="font-bold text-[18px] mb-1">Hilwin Nisa'</p>
                <p className="text-sm">VP Product</p>
              </div>
            </div>
            <div className="flex flex-col">
              <img className="object-cover rounded-2xl mb-3 h-[341px]" src={AboutusImage.team4} alt="Team person 1" />
              <div className="flex flex-col pl-3 text-zinc-600">
                <p className="font-bold text-[18px] mb-1">Ferianti</p>
                <p className="text-sm">VP Activation</p>
              </div>
            </div>
            <div className="flex flex-col">
              <img className="object-cover rounded-2xl mb-3 h-[341px]" src={AboutusImage.team5} alt="Team person 1" />
              <div className="flex flex-col pl-3 text-zinc-600">
                <p className="font-bold text-[18px] mb-1">Imas Chandra</p>
                <p className="text-sm">VP Outreach</p>
              </div>
            </div>
            <div className="flex flex-col">
              <img className="object-cover rounded-2xl mb-3 h-[341px]" src={AboutusImage.team6} alt="Team person 1" />
              <div className="flex flex-col pl-3 text-zinc-600">
                <p className="font-bold text-[18px] mb-1">Riska Amalia</p>
                <p className="text-sm">VP Design</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutusTeam;
