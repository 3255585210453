import React from "react";
import { Icons } from "../../../entity/Icons";

function format_number(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const bintang = [1, 2, 3, 4, 5];

export function kelasMG(
  thumbnail,
  course,
  harga,
  hargacoret,
  diskon,
  lembaga,
  rating,
  peserta,
  tipe
) {
  return (
    <div className="border justify-center w-screen content-center items-center border-gray rounded-xl gap-4 p-4 m-4">
      <div className="-m-4 -p-4 rounded-t-xl">
        <img
          alt=""
          src={thumbnail}
          className="h-30 w-screen rounded-t-xl"
        ></img>
      </div>
      <div className="flex flex-row ">
        <div className="w-2/3 font-semibold text-sm mt-8 ml-1">{course}</div>
        <div className="flex flex-grow"></div>
        <div className="mb-4 ">
          <button className="bg-green-200 text-green-500 text-xs font-medium font-bold py-1 px-3 mt-8 ">
            {tipe}
          </button>
        </div>
      </div>
      <div className="flex flex-row">
        {harga > 1 ? (
          <div className="normal-nums font-bold text-magnet-primary text-normal ml-1 mt-2">
            Rp{format_number(harga)},-
          </div>
        ) : (
          <div className="normal-nums font-bold text-magnet-primary text-normal ml-1 mt-2">
            GRATIS
          </div>
        )}
        {diskon === undefined && <div className="pl-2"></div>}
        {diskon > 1 && (
          <div className="px-1 bg-magnet-primary rounded-sm text-white font-medium text-normal text-magnet-primary ml-1 mt-2">
            {diskon}%
          </div>
        )}
        {diskon === "Terbatas" && (
          <div className="px-1 bg-magnet-primary rounded-sm text-white font-medium text-normal text-magnet-primary ml-1 mt-2">
            {diskon}
          </div>
        )}
        {hargacoret === undefined ? (
          <div className="pl-2"></div>
        ) : (
          <div className="line-through font-normal text-magnet-gray text-sm ml-1 mt-3">
            Rp{format_number(hargacoret)},-
          </div>
        )}
        <div className="flex flex-grow"></div>
      </div>
      <div className="flex flex-row">
        <div className="mt-4 text-magnet-gray">oleh </div>
        <div className="mt-4 font-semibold ml-1 truncate block w-9/12">
          {lembaga}
        </div>
        <div className="flex flex-row"></div>
      </div>
      <div className="flex flex-row mt-4">
        {bintang.map((jumlahbintang) => {
          if (jumlahbintang <= rating)
            return (
              <div className="fill-magnet-primary">
                <Icons.STAR ClassName="h-4 w-4 inline " />
              </div>
            );
          return <></>;
        })}
        {Number.isInteger(rating) === false && (
          <div className="fill-magnet-primary">
            <Icons.STAR_HALF_ALT ClassName="h-4 w-4 inline " />
          </div>
        )}

        <div className="fill-magnet-primary ml-2 font-bold">{rating}</div>
        <div className="fill-magnet-primary text-xs mt-2">/5</div>
        <div className="fill-magnet-primary text-xs ml-2 mt-2">({peserta})</div>
        <div className="flex flex-grow"></div>
      </div>
    </div>
  );
}

class CourseList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { content } = this.props;

    return (
      <div className="flex flex-col gap-2 mt-4">
        <div className="flex flex-row justify-between">
          <div className="flex items-center gap-2">
            <div className="">
              <div className="flex justify-center items-center w-8 h-8 bg-magnet-primary fill-magnet-primarylight rounded-full">
                <Icons.DIARY className="h-4" />
              </div>
            </div>
            <p className="text-2xl font-semibold text-magnet-dark">
              Hot Courses
            </p>
          </div>
          <div className="flex flex-row items-center">
            <p className="font-semibold text-magnet-primary">
              Lihat Lebih Banyak
            </p>
            <Icons.ANGLE_RIGHT className="fill-magnet-primary h-4" />
          </div>
        </div>
        <div>
          <p>Baru saja di-post nih, yakin kamu gak tertarik?</p>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row -ml-4">
            {content.map((item, i) => {
              if (item.tipe === "Kelas" && i < 3)
                return kelasMG(
                  item.thumbnail,
                  item.course,
                  item.harga,
                  item.hargacoret,
                  item.diskon,
                  item.lembaga,
                  item.rating,
                  item.peserta,
                  item.tipe
                );
              return <></>;
            })}
          </div>
          <div className="flex flex-row -ml-4">
            {content.map((item, i) => {
              if (item.tipe === "Kelas" && i > 2 && i < 6)
                return kelasMG(
                  item.thumbnail,
                  item.course,
                  item.harga,
                  item.hargacoret,
                  item.diskon,
                  item.lembaga,
                  item.rating,
                  item.peserta,
                  item.tipe
                );
              return <></>;
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default CourseList;
