import React from 'react'


class KontenPostBanner extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }

  }

  render() {
    const { img, author, title, content, date, time, url } = this.props

    return (
      <div className='flex flex-col xl:w-full gap-3 duration-1000 ease-in-out shadow-lg shadow-gray-300 rounded-lg hover:-translate-y-1 bg-white'>
        <img alt='' className='h-[300px] rounded-t-3xl' src={img}></img>
        <div className='px-4 flex flex-col justify-between h-full'>
          <div className='flex flex-col gap-3'>
            <div className='flex font-semibold text-xs cursor-pointer'>
              <p className='border border-gray-400 text-gray-400 px-2 py-1 rounded-lg'>{author}</p>
            </div>
            <p className='font-medium text-lg hover:text-magnet-rose-normal duration-500 cursor-pointer'
              onClick={() => window.open(url, '_blank')}>
              {title}
            </p>
            <p className='font-light text-xs'>{content}</p>
          </div>
          <p className='opacity-50 text-magnet-black-normal text-sm mb-4'>{date} - {time}</p>
        </div>
      </div>
    )
  }
}

export default KontenPostBanner