import React from "react";

class SidebarCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { detail_company } = this.props;
    return (
      <div className="flex flex-col w-full md:w-4/12 lg:w-3/12">
        <div className="flex flex-col bg-white shadow px-4 md:px-6 py-4 rounded-2xl mb-6">
          <h3 className="text-sm md:text-base font-bold mb-3">Kontak</h3>
          <div className="grid grid-cols-1 md:grid-cols-2">
            <span className="text-base md:text-sm text-neutral-muted font-montserrat font-bold">Nomor Aktif</span>
            <span className="text-base md:text-sm">{detail_company.phone}</span>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2">
            <span className="text-base md:text-sm text-neutral-muted font-montserrat font-bold">Email</span>
            <span className="text-base md:text-sm">{detail_company.email}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default SidebarCompany;
