import React, { useState } from "react";
import { MitraImage } from "../../entity/MitraImage";
import { Icons } from "../../entity/Icons";
import { Page } from "../../entity/Pages";

function ShowHide() {
  const [open, setOpen] = useState(0);

  return (
    <div className="relative flex flex-row text-gray-700 leading-tight focus:shadow-outline shadow appearance-none border rounded-2xl">
      <div
        onClick={() => setOpen(0)}
        className={
          open === 1
            ? "right-2 h-6 w-6 text-white absolute cursor-pointer visible"
            : "  right-2  h-6 w-6  text-white  absolute cursor-pointer invisible"
        }
      >
        <Icons.EYE
          className={
            open === 1
              ? "z-20 cursor-pointer right-0 -top-0 absolute pointer-events-none fill-gray-700 visible"
              : "z-20 cursor-pointer right-0 -top-0 absolute pointer-events-none fill-gray-700 invisible"
          }
        />
      </div>
      <div
        onClick={() => setOpen(1)}
        className={
          open === 0
            ? "z-90 right-2 absolute  h-6 w-6 text-white   cursor-pointer  visible"
            : "z-90 right-2 absolute h-6 w-6 text-white   cursor-pointer invisible"
        }
      >
        <Icons.EYE_SLASH
          className={
            open === 0
              ? "z-90 cursor-pointer right-0 -top-0 absolute pointer-events-none fill-gray-700 visible"
              : "z-90 cursor-pointer right-0 -top-0 absolute pointer-events-none fill-gray-700 invisible"
          }
        />
      </div>

      <Icons.LOCK className="left-3 absolute pointer-events-none fill-magnet-secondary" />

      <input
        id="password"
        name="password"
        type={open === 0 ? "password" : "text"}
        placeholder="Type Here..."
        className="px-10  shadow appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
      ></input>
    </div>
  );
}

class MitraLoginView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      navigator
    } = this.props;
    return (
      <div className="flex flex-col items-center font-poppins bg-magnet-secondary ">
        <div className="w-8/12 ">
          <div onClick={() => navigator(Page.MITRA)} className="flex flex-row gap-8 mt-10 text-white cursor-pointer text-center text-2xl relative font-semibold">
            <Icons.ARROW_LEFT className="fill-gray-200 w-8 h-8" />
            Mitra Login Page
          </div>
          <div
            className="absolute -z-20
         right-0 top-20"
          >
            <img className=" mt-8" alt="" src={MitraImage.vector4}></img>
          </div>
        </div>
        <div className=" border border-white border-spacing-20  gap-6 rounded-3xl mt-10 bg-magnet-secondary  w-8/12 z-30">
          <div className=" flex m-4 flex-row rounded-3xl  bg-white shadow-xl  z-30">
            <div className="flex flex-col justify-center w-full rounded-l-3xl p-16 bg-magnet-secondarylight">
              <img className="h-8" alt="" src={MitraImage.logored}></img>{" "}
              <img className="h-64" alt="" src={MitraImage.undraw}></img>
              <p className="text-magnet-secondarydark text-center">
                Tempat Pengembangan Karir Terbaik Bagi Para Alumni & Awardee
              </p>
            </div>{" "}
            <div className="flex flex-col rounded-3xl p-16 gap-6 w-full">
              <div className="text-xl font-bold text-magnet-secondarydark">
                Hi, Mitra Mata Garuda!
              </div>
              <div className="flex flex-row text-lg font-medium">
                <div>Email</div>
              </div>
              <div className="relative flex flex-row text-gray-700 leading-tight focus:shadow-outline shadow appearance-none border rounded-2xl">
                <Icons.USER className="left-3 absolute pointer-events-none fill-magnet-secondary" />

                <input
                  id="user"
                  name="user"
                  type="text"
                  placeholder="agung@email.com"
                  className="pl-10 shadow appearance-none border rounded-xl w-full py-4 -mt-4 px-4 text-gray-700 leading-tight focus:outline-magnet-secondary focus:shadow-outline"
                ></input>
              </div>

              <div className="flex flex-row text-lg font-medium">
                <div>Password</div>
              </div>
              <ShowHide />

              <div className="flex flex-row text-lg justify-between font-semibold">
                <div className="flex items-center  mb-4">
                  <input
                    id="remember"
                    name="remember"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 leading-tight text-magnet-secondary accent-magnet-secondary rounded-2xl bg-magnet-secondarydark border-magnet-secondarydark rounded focus:ring-magnet-secondary dark:focus:ring-magnet-secondary dark:ring-offset-magnet-secondary focus:ring-2 dark:bg-magnet-secondarydark dark:border-magnet-secondarydark"
                  ></input>
                  <label
                    for="remember"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Ingat saya
                  </label>
                </div>
                <div className="flex cursor-pointer text-end text-magnet-secondary hover:text-magnet-primary font-semibold text-sm">
                  Recovery Password
                </div>
              </div>
              <div className="rounded-2xl border border-magnet-secondary hover:bg-white hover:text-black hover:border hover:border-magnet-secondary text-center text-white font-semibold bg-magnet-secondary py-2 cursor-pointer">
                Login
              </div>
              <div className="rounded-2xl hover:text-white hover:bg-magnet-secondary justify-center flex flex-row text-center bg-white font-semibold border border-magnet-secondary py-2 cursor-pointer">
                <div>
                  <Icons.GOOGLE className="fill-magnet-secondary mr-2 hover:fill-white" />
                </div>
                <div>Sign in with Google</div>
              </div>
              <div className='flex flex-col justify-center items-center'>
                <p className='text-xs font-light text-gray-500 mt-2 mb-4 '>atau </p>
                <p className='text-sm'>
                  Belum punya akun? <span className='font-semibold text-magnet-secondary cursor-pointer' onClick={() => navigator(Page.REGISTERMITRA)}>Mendaftar</span>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MitraLoginView;
