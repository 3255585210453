import React from 'react'


class KeunggulanBanner extends React.Component {
  render() {
    const { image, title, content } = this.props
    return (
      <div className='flex md:flex-row flex-col md:px-16 px-4 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.07)] rounded-3xl py-12 md:h-[400px]'>
        <div className='md:w-4/12 flex items-center justify-center'>
          <img alt={title} src={image}></img>
        </div>
        <div className='md:w-8/12 flex flex-col gap-6 md:px-3'>
          <h1 className='text-magnet-rose-normal font-bold text-xl md:text-3xl'>{title}</h1>
          <p className='opacity-60 md:text-base text-xs'>{content}</p>
        </div>
      </div>
    )
  }
}

export default KeunggulanBanner