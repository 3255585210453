import React from "react";
import HTMLReactParser from "html-react-parser";
import * as APP_DATA from '../../../../core/ConstantData';

class DetailMgtalk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { detail_mgtalk } = this.props;
    return (
      <div className="flex flex-col w-full">
        <div className="flex flex-col bg-white shadow px-4 md:px-6 py-4 rounded-2xl mb-6">
          <h3 className="text-base md:text-lg font-montserrat font-bold">{detail_mgtalk.title}</h3>
          <div className="flex flex-row items-center gap-2 mb-8">
              <img alt='' src={APP_DATA.NEW_LOGO_MAGNET} className='cursor-pointer w-16'></img>
              <span>Mata Garuda Network</span>
          </div>
          <div className="flex flex-col mb-6">
            <div className="flex w-10/12 mb-6">
            <iframe width="720" height="420" src={detail_mgtalk.video_url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <h4 className="text-sm md:text-base font-bold">Deskripsi</h4>
            <hr className="mt-2 mb-3" />
            <p className="md:text-sm text-justify">{HTMLReactParser(`${detail_mgtalk.description}`)}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailMgtalk;
