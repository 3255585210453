import React from "react";
import { AboutusImage } from "../../../entity/AboutusImage";

class AboutusConnection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    return (
      <div className="w-full bg-zinc-100 md:px-32 py-12">
        <div className="flex flex-col justify-center w-full">
          <div className="w-full">
            <h2 className="w-fit mx-auto text-center text-3xl  md:text-5xl font-bold leading-snug bg-gradient-to-r from-magnet-rose-normal to-magnet-orange-normal bg-clip-text text-transparent">Connection</h2>
          </div>
          <div className="w-full self-center">
            <img className="w-full object-contain" src={AboutusImage.maps} alt="Map About us"/>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutusConnection;
