import React from "react";
import * as APP_DATA from "../../../core/ConstantData";
import { Icons } from "../../entity/Icons";
import { Page } from "../../entity/Pages";

class HeaderLoginMitraView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="flex justify-center py-12 h-32 font-poppins bg-magnet-secondary to-magnet-secondarygradient"></div>
      </React.Fragment>
    );
  }
}

export default HeaderLoginMitraView;
