import React from "react";
import { Icons } from "../../../entity/Icons";
import { kelasMG } from "./CourseList";

class MGTalkList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { content } = this.props;

    return (
      <div className="flex flex-col gap-2 mt-4">
        <div className="flex flex-row justify-between">
          <div className="flex items-center gap-2">
            <div className="">
              <div className="flex justify-center items-center w-8 h-8 bg-magnet-primary fill-magnet-primarylight rounded-full">
                <Icons.DIARY className="h-4" />
              </div>
            </div>
            <p className="text-2xl font-semibold text-magnet-dark">MG Talks</p>
          </div>
          <div className="flex flex-row items-center">
            <p className="font-semibold text-magnet-primary">
              Lihat Lebih Banyak
            </p>
            <Icons.ANGLE_RIGHT className="fill-magnet-primary h-4" />
          </div>
        </div>
        <div>
          <p>Baru saja di-post nih, yakin kamu gak tertarik?</p>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row -ml-4">
            {content.map((item, i) => {
              if (item.tipe === "MG Talks")
                return kelasMG(
                  item.thumbnail,
                  item.course,
                  item.harga,
                  item.hargacoret,
                  item.diskon,
                  item.lembaga,
                  item.rating,
                  item.peserta,
                  item.tipe
                );
              return <></>;
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default MGTalkList;
