import React from "react";
import CourseList from "./sections/CourseList";

import WebinarList from "./sections/WebinarList";

import MGTalksList from "./sections/MGTalksList";

import FilterCourse from "./sections/FilterCourse";

class CourseView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { postCourse } = this.props;

    return (
      <div className="flex flex-col items-center font-poppins">
        <div className="w-10/12">
          <div className="flex flex-col gap-8 mt-10">
            <div className="flex flex-col gap-4">
              <p className="text-4xl font-semibold">Belajar Bersama MAGNET</p>
              <p className="text-lg font-light">
                Online learning with over 1.001+ courses to improve your skill
                better
              </p>
            </div>
            <FilterCourse />
          </div>
          <CourseList content={postCourse} />
          <WebinarList content={postCourse} />
          <MGTalksList content={postCourse} />
        </div>
      </div>
    );
  }
}

export default CourseView;
