import proses_cepat from '../../image/proses-cepat.svg'
import resmi from '../../image/resmi.svg'
import terpercaya from '../../image/terpercaya.svg'
import transparan from '../../image/transparan.svg'

export const KeunggulanImage = {
  KOMUNIKASI_CEPAT: proses_cepat,
  RESMI: resmi,
  TERPERCAYA: terpercaya,
  JARINGAN_LUAS: transparan,
}
