import React from "react";
import { Icons } from "../../../entity/Icons";

//const dipasang = [
//"Dipasang",
//  "Hari ini",
// "Kemarin",
//  "Minggu ini",
// "Bulan ini",
// "Tahun ini",
//;
//const Jenis = ["Jenis"];

class FilterCourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { navigateToCareer } = this.props;

    return (
      <div className="flex flex-col">
        <div className="bg-gray-100 rounded-xl flex flex-row p-8 gap-6 items-center justify-between">
          <div className="w-11/12 bg-white rounded-xl flex flex-row items-center justify-between">
            <div className="px-4">
              <Icons.SEARCH className="h-10 fill-magnet-primary" />
            </div>
            <input
              className="p-4 w-full"
              type="text"
              placeholder="Cari Lowongan..."
            />
            <div className="px-4 cursor-pointer">
              <Icons.TIMES className="h-6 w-6 fill-magnet-gray hover:fill-magnet-primary bg-gray-100 rounded-full duration-500" />
            </div>
          </div>
          <button
            className="w-1/12 bg-magnet-primary rounded-xl p-4 text-magnet-primarylight border border-magnet-primary hover:bg-white hover:text-magnet-primary duration-500"
            onClick={navigateToCareer}
          >
            Cari
          </button>
        </div>
        <div className="flex flex-row mt-4">
          <div>
            <select className="fill-orange inline text-xl font-semibold select border fill-magnet-primary border-magnet-primary rounded-lg  px-4 w-40 h-10 max-w-xs mt-4 px-4 ">
              <option className="invisible" disabled selected>
                Dipasang{" "}
                {
                  <Icons.ANGLE_DOWN className="h-6 w-6 fill-magnet-primary hover:fill-magnet-gray  rounded-full" />
                }
              </option>
              <option>Kemarin</option>
              <option>1 Bulan Lalu</option>
              <option>Hari Ini</option>
            </select>
          </div>
          <div>
            <select className="mx-4 inline font-semibold text-xl select border border-magnet-primary rounded-lg  px-4 w-28 h-10 max-w-xs mt-4 px-4 ">
              <option className="invisible" disabled selected>
                Jenis
              </option>
              <option>Cheese</option>
              <option>Veggie</option>
              <option>Pepperoni</option>
              <option>Margherita</option>
              <option>Hawaiian</option>
            </select>
          </div>
          <div>
            <select className="text-xl select font-semibold border border-magnet-primary rounded-lg  px-4 w-50 h-10 max-w-xs mt-4 px-4 ">
              <option className="invisible" disabled selected>
                Spesialisasi
              </option>
              <option>Cheese</option>
              <option>Veggie</option>
              <option>Pepperoni</option>
              <option>Margherita</option>
              <option>Hawaiian</option>
            </select>
          </div>
          <div className="flex flex-grow"></div>
          <div className="flex flex-row mt-4 text-magnet-primary font-semibold">
            <button>Reset Filter</button>
          </div>
        </div>{" "}
        <hr className="h-px my-4  bg-gray-200 border-0 dark:bg-gray-700"></hr>
        <div className="flex flex-row mb-4 ">
          <div className="mr-2 flex flex-row shrink bg-gray rounded-md rounded-md bg-gray-200 text-gray-400 px-2 flex-row">
            <div>Kemarin</div>
            <div className="flex flex-grow"></div>
            <div className="mt-1 ml-2">
              <Icons.TIMES className="h-4 w-4 fill-magnet-gray hover:fill-magnet-primary  rounded-full duration-500" />
            </div>
          </div>{" "}
          <div className="mr-2 flex flex-row shrink bg-gray rounded-md rounded-md bg-gray-200 text-gray-400 px-2 flex-row">
            <div>Kemarin</div>
            <div className="flex flex-grow"></div>
            <div className="mt-1 ml-2">
              <Icons.TIMES className="h-4 w-4 fill-magnet-gray hover:fill-magnet-primary  rounded-full duration-500" />
            </div>
          </div>{" "}
          <div className="mr-2 flex flex-row shrink bg-gray rounded-md rounded-md bg-gray-200 text-gray-400 px-2 flex-row">
            <div>Kemarin</div>
            <div className="flex flex-grow"></div>
            <div className="mt-1 ml-2">
              <Icons.TIMES className="h-4 w-4 fill-magnet-gray hover:fill-magnet-primary  rounded-full duration-500" />
            </div>
          </div>{" "}
          <div className="mr-2 flex flex-row shrink bg-gray rounded-md rounded-md bg-gray-200 text-gray-400 px-2 flex-row">
            <div>Kemarin</div>
            <div className="flex flex-grow"></div>
            <div className="mt-1 ml-2">
              <Icons.TIMES className="h-4 w-4 fill-magnet-gray hover:fill-magnet-primary  rounded-full duration-500" />
            </div>
          </div>{" "}
          <div className="mr-2 flex flex-row shrink bg-gray rounded-md rounded-md bg-gray-200 text-gray-400 px-2 flex-row">
            <div>Kemarin</div>
            <div className="flex flex-grow"></div>
            <div className="mt-1 ml-2">
              <Icons.TIMES className="h-4 w-4 fill-magnet-gray hover:fill-magnet-primary  rounded-full duration-500" />
            </div>
          </div>{" "}
          <div className="mr-2 flex flex-row shrink bg-gray rounded-md rounded-md bg-gray-200 text-gray-400 px-2 flex-row">
            <div>Kemarin</div>
            <div className="flex flex-grow"></div>
            <div className="mt-1 ml-2">
              <Icons.TIMES className="h-4 w-4 fill-magnet-gray hover:fill-magnet-primary  rounded-full duration-500" />
            </div>
          </div>{" "}
          <div className="mr-2 flex flex-row shrink bg-gray rounded-md rounded-md bg-gray-200 text-gray-400 px-2 flex-row">
            <div>Kemarin</div>
            <div className="flex flex-grow"></div>
            <div className="mt-1 ml-2">
              <Icons.TIMES className="h-4 w-4 fill-magnet-gray hover:fill-magnet-primary  rounded-full duration-500" />
            </div>
          </div>{" "}
        </div>{" "}
        <div className="flex flex-row mb-4 ">
          <div className="mr-2 flex flex-row shrink bg-gray rounded-md rounded-md bg-gray-200 text-gray-400 px-2 flex-row">
            <div>Kemarin</div>
            <div className="flex flex-grow"></div>
            <div className="mt-1 ml-2">
              <Icons.TIMES className="h-4 w-4 fill-magnet-gray hover:fill-magnet-primary  rounded-full duration-500" />
            </div>
          </div>{" "}
          <div className="mr-2 flex flex-row shrink bg-gray rounded-md rounded-md bg-gray-200 text-gray-400 px-2 flex-row">
            <div>Kemarin</div>
            <div className="flex flex-grow"></div>
            <div className="mt-1 ml-2">
              <Icons.TIMES className="h-4 w-4 fill-magnet-gray hover:fill-magnet-primary  rounded-full duration-500" />
            </div>
          </div>{" "}
          <div className="mr-2 flex flex-row shrink bg-gray rounded-md rounded-md bg-gray-200 text-gray-400 px-2 flex-row">
            <div>Kemarin</div>
            <div className="flex flex-grow"></div>
            <div className="mt-1 ml-2">
              <Icons.TIMES className="h-4 w-4 fill-magnet-gray hover:fill-magnet-primary  rounded-full duration-500" />
            </div>
          </div>{" "}
          <div className="mr-2 flex flex-row shrink bg-gray rounded-md rounded-md bg-gray-200 text-gray-400 px-2 flex-row">
            <div>Kemarin</div>
            <div className="flex flex-grow"></div>
            <div className="mt-1 ml-2">
              <Icons.TIMES className="h-4 w-4 fill-magnet-gray hover:fill-magnet-primary  rounded-full duration-500" />
            </div>
          </div>{" "}
          <div className="mr-2 flex flex-row shrink bg-gray rounded-md rounded-md bg-gray-200 text-gray-400 px-2 flex-row">
            <div>Kemarin</div>
            <div className="flex flex-grow"></div>
            <div className="mt-1 ml-2">
              <Icons.TIMES className="h-4 w-4 fill-magnet-gray hover:fill-magnet-primary  rounded-full duration-500" />
            </div>
          </div>{" "}
          <div className="mr-2 flex flex-row shrink bg-gray rounded-md rounded-md bg-gray-200 text-gray-400 px-2 flex-row">
            <div>Kemarin</div>
            <div className="flex flex-grow"></div>
            <div className="mt-1 ml-2">
              <Icons.TIMES className="h-4 w-4 fill-magnet-gray hover:fill-magnet-primary  rounded-full duration-500" />
            </div>
          </div>{" "}
          <div className="mr-2 flex flex-row shrink bg-gray rounded-md rounded-md bg-gray-200 text-gray-400 px-2 flex-row">
            <div>Kemarin</div>
            <div className="flex flex-grow"></div>
            <div className="mt-1 ml-2">
              <Icons.TIMES className="h-4 w-4 fill-magnet-gray hover:fill-magnet-primary  rounded-full duration-500" />
            </div>
          </div>{" "}
        </div>
      </div>
    );
  }
}

export default FilterCourse;
